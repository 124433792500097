import React, { forwardRef, useCallback, useEffect } from 'react';
import SortableList, { SortableItem } from 'react-easy-sort';
import { addBlobIdentifier, onFilesAdded, uploadFile } from '../../../helpers/fileHelpers/imageUploadHelper';

import tenantTheme from '@theme';
import { Space } from 'antd';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { isSafari } from '../../../utility/utility';
import { Icon } from '../../common';
import ImageUploadItem from './image-upload-item';
import { UploadButton } from './styled';

const ImageUploadDropzone = forwardRef((props, ref) => {
  const {
    setPictures,
    pictures,
    getRetry,
    btnClassName,
    btnText,
    children,
    previewKey,
    selectOnClick,
    selectMainOnDoubleClick,
    fullPreviewKey,
    btnStyle,
    showClose,
    contentMappingForImages,
    attachmentType,
    viewType,
    name,
    filesAllowed,
    containerClass,
    onImageUpload = () => {},
  } = props;

  useEffect(() => {
    getRetry && getRetry(retryCallback);
  });

  const removeImage = async (imageItem) => {
    if (typeof imageItem?.id === 'string') {
      const index = pictures.findIndex((pic) => {
        return pic.id === imageItem.id;
      });
      pictures.splice(index, 1);
      setPictures(pictures);
    } else {
      const index = pictures.findIndex((pic) => {
        return pic.id === imageItem.id;
      });
      pictures[index] = { ...pictures[index], _destroy: true };
      setPictures(pictures);
    }
  };

  const isMobile = useSelector((state) => state.AppConfig.isMobile);

  const setFiles = (files) => {
    setPictures(files);
  };

  const retryCallback = (imageItem) => {
    pictures.map((pic) => {
      if (pic.inError && !pic.uploading && !pic.uploaded && pic?.id === imageItem?.id && !pic._destroy) {
        uploadFile(pic, attachmentType, 'nil', pictures, setFiles, true, false, undefined);
      }
    });
  };

  const getImageItem = (item) => {
    return (
      <ImageUploadItem
        key={item.id}
        onRetry={retryCallback}
        removeImage={removeImage}
        item={item}
        files={pictures}
        setFiles={setFiles}
        isSvg={false}
        makeBlob
        attachmentType={attachmentType}
        associationKey={null}
        previewKey={previewKey}
        selectOnClick={selectOnClick}
        selectMainOnDoubleClick={selectMainOnDoubleClick}
        fullPreviewKey={fullPreviewKey}
        showClose={showClose}
        viewType={viewType}
        onImageUpload={onImageUpload}
      />
    );
  };

  const handleFileAdd = async (files) => {
    const newFilesArray = await addBlobIdentifier(files);
    onFilesAdded(pictures, (pics) => setPictures(pics), newFilesArray, filesAllowed);
  };

  const renderUploadButton = (buttonProps) => {
    return (
      <UploadButton className={btnClassName} style={{ ...btnStyle }}>
        {isMobile && <Icon icon="FaImages" size={'18px'} color={tenantTheme['primary-color']} />}
        {btnText}
        <input
          style={{ display: 'none' }}
          type="file"
          accept={contentMappingForImages.join(',')}
          multiple={isSafari() ? false : true}
          onChange={async (e) => {
            const newFiles = e.target.files;
            await handleFileAdd(newFiles);
          }}
          onClick={(event) => {
            event.target.value = '';
          }}
          name={name}
          {...buttonProps}
        />
      </UploadButton>
    );
  };

  const onSortEnd = (oldIndex, newIndex) => {
    const oldIndexObject = pictures?.[oldIndex];

    pictures?.splice(oldIndex, 1);
    pictures?.splice(newIndex, 0, oldIndexObject);
    setPictures(pictures);
  };

  const renderImages = useCallback(() => {
    return viewType === 'list' ? (
      <SortableList className="list" onSortEnd={onSortEnd} draggedItemClassName="">
        {pictures
          .filter((e) => !e?._destroy)
          .map((pic, i) => (
            <SortableItem key={pic}>
              <div>{getImageItem(pic, i)}</div>
            </SortableItem>
          ))}
      </SortableList>
    ) : (
      <SortableList className="" onSortEnd={onSortEnd} draggedItemClassName="">
        <Space wrap={true}>
          {pictures
            .filter((e) => !e?._destroy)
            .map((pic, i) => (
              <SortableItem key={pic}>
                <div>{getImageItem(pic, i)}</div>
              </SortableItem>
            ))}
        </Space>
      </SortableList>
    );
  }, [pictures]);

  return (
    <div className={`dzu-dropzone ${viewType === 'list' ? 'dropzone-list' : ''} ${containerClass}`}>
      {children(renderImages, renderUploadButton)}
    </div>
  );
});

ImageUploadDropzone.defaultProps = {
  isSelectingMultiple: false,
  btnClassName: 'ant-btn ant-btn-primary',
  btnText: 'Upload',
  contentMappingForImages: ['image/jpeg', 'image/png'],
  attachmentType: 'generic',
  viewType: 'gallery',
};

ImageUploadDropzone.propTypes = {
  isSelectingMultiple: PropTypes.bool,
  children: PropTypes.func.isRequired,
  btnClassName: PropTypes.string,
  btnText: PropTypes.string,
  btnStyle: PropTypes.object,
};

const DropZone = (props) => {
  const { children, onDropCallback, className } = props;

  const dragOver = (e) => {
    e.preventDefault();
  };

  const dragEnter = (e) => {
    e.preventDefault();
  };

  const dragLeave = (e) => {
    e.preventDefault();
  };

  const fileDrop = (e) => {
    e.preventDefault();
    const { files } = e.dataTransfer;
    onDropCallback(files);
  };
  return (
    <div className={className} onDragOver={dragOver} onDragEnter={dragEnter} onDragLeave={dragLeave} onDrop={fileDrop}>
      {children}
    </div>
  );
};

export default ImageUploadDropzone;
