import tenantConstants from '@constants';
import tenantData from '@data';
import tenantUtils from '@utils';
import { Col, Row, Typography } from 'antd';
import { t } from 'i18next';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Flex, Group, Icon, Number, RadioButtons, Select, Skeleton, TextInput } from '../../../../components/common';
import { IconStyled } from '../../../../components/common/icon/IconStyled';
import CreditInfo from '../../../../components/credits-info/credits-info';
import MobileVerification from '../../../../components/mobile-number-verification/mobile-verification';
import { AddAmenities, ImageSelect } from '../../../../components/post-listing';
import { useScrollToPageSection } from '../../../../hooks';
import { imageUploadSuccessEvent } from '../../../../services/analyticsService';
import GenerateContentField from './GenerateContentField';
import { skipFieldsForField } from './listing-form-data';
import LocationSelect from './location-select/location-select';
import { BlockTitle, CardListing, PriceCheckValue } from './styled';
const allowedFilesType = ['jpg', 'png', 'jpeg'];

const { Text } = Typography;

const RenderPostListingFields = (props) => {
  const { loading, formik, isMobile, setLoading, users, alreadyPosted = [] } = props;
  const { user } = useSelector((state) => state.loginUser);
  const { locale } = useSelector((state) => state?.AppConfig);

  const { t } = useTranslation();

  const bedroomList = useMemo(() => {
    return skipFieldsForField?.['bedrooms'].property_type[
      formik.values['sub_listing_type']?.id || formik.values['property_type']
    ]?.studio
      ? tenantData.bedroomsList?.filter((item) => item.key !== '-1')
      : tenantData.bedroomsList;
  }, [
    skipFieldsForField,
    formik.values['property_type'],
    tenantData.bedroomsList,
    formik.values['sub_listing_type']?.id,
  ]);

  useScrollToPageSection();

  return (
    <>
      <CardBlock icon="IconLocationPurpose" title={t('Property Location')}>
        <Group id="location" gap="32px" style={{ maxWidth: 640 }}>
          <LocationSelect
            name="Location_select"
            formik={formik}
            setFieldValue={formik.setFieldValue}
            setFieldTouched={formik.setFieldTouched}
            value={formik?.values['location-info']}
            error={formik.errors?.['location-info']}
            touched={formik.touched?.['location-info']}
            onBlur={formik.handleBlur}
            item={{ key: 'location-info' }}
            onCityChange={(option) => {
              // formik.setFieldValue(['location-info']?.city, { ...option, id: option?.city?.city_id });
            }}
            disabled={{
              city: formik?.values?.location?.city_id,
              // location: formik?.values?.location?.id,
            }}
            renderCrossCityAlerts={() => {}}
            hideLocation={false}
            onLocationSelect={() => {}}
            user={user}
            showPlot={true}
            propertyType={formik.values['listing_type']}
          />
        </Group>
      </CardBlock>
      <CardBlock icon="IconImagesPost" title={t('Property Images')}>
        <Group id="images" gap="32px" style={{ maxWidth: 640 }}>
          <ImageSelect
            labelIcon="LuImage"
            label={t('Images')}
            values={formik.values}
            setFieldValue={formik.setFieldValue}
            errorMsg={
              formik.errors['property_images'] && formik.touched['property_images'] && formik.errors['property_images']
            }
            valueKey={'property_images'}
            filesAllowed={tenantData.allowedFilesType}
            imageBankIcon={false}
            showImageBank={false}
            onImageUpload={(response) => {
              imageUploadSuccessEvent(user, response, !alreadyPosted, formik.values);
            }}
          />
          {/* <VideoSelect
            labelIcon="AiOutlineVideoCamera"
            label=t("Videos")
            value={formik.values['videos']}
            setFieldValue={formik.setFieldValue}
            errorMsg={formik.errors['videos'] && formik.touched['videos'] && formik.errors['videos']}
            valueKey="videos"
            hostList={tenantData.videoHostsList}
            isMobile={isMobile}
          /> */}
        </Group>
      </CardBlock>
      <CardBlock icon="IconAdInformation" title={t('Price and Specs')}>
        <Group id="specs" gap="32px" style={{ maxWidth: 640 }}>
          {formik?.values?.listing_purpose?.slug == 'sale' ? (
            <TextInput
              key="price"
              name="price"
              label={
                <>
                  {t('Price')} <>({t('SAR')})</>
                </>
              }
              labelIcon="RiCalendar2Fill"
              placeholder={t('Enter Price')}
              handleChange={(event) => {
                formik.setFieldValue('price', event.target.value, true);
              }}
              suffix={<span className="color-gray-dark">{t(tenantConstants.currency)}</span>}
              handleBlur={(event) => {
                formik.setFieldTouched('price', true);
                formik.setFieldValue('price', event.target.value, true);
              }}
              value={formik?.values['price']}
              skeletonLoading={loading}
              errorMsg={formik?.errors['price'] && formik?.touched['price'] && formik?.errors['price']}
              extra={() => {
                return formik?.values['price'] && !formik?.errors['price'] ? (
                  <PriceCheckValue>
                    <Number
                      type="price"
                      className={'text-muted'}
                      value={formik?.values['price']}
                      tooltip={false}
                      compact={false}
                    />
                  </PriceCheckValue>
                ) : null;
              }}
              disabled={!!formik?.values['price']}
            />
          ) : (
            <>
              <RadioButtons
                name="rental_frequency"
                shape="round"
                key="rental_frequency"
                label={t('Rental Frequency')}
                inputLeftIcon="user"
                labelIcon="MdOutlineMap"
                value={formik?.values['rental_frequency']}
                handleChange={(e) => {
                  formik.setFieldValue('rental_frequency', e?.target?.value, true);
                }}
                buttonList={tenantData.rentalFrequencyList.map((item) => ({ ...item, label: t(item.label) }))}
                errorMsg={
                  formik.errors['rental_frequency'] &&
                  formik?.touched['rental_frequency'] &&
                  formik.errors['rental_frequency']
                }
              />
              <TextInput
                key="rental_price"
                name="rental_price"
                label={
                  <>
                    {t('Rent Price')} <>({t('SAR')})</>
                  </>
                }
                labelIcon="RiCalendar2Fill"
                placeholder={t('Enter Rent')}
                handleBlur={(event) => {
                  formik.setFieldTouched('rental_price', true);
                  formik.setFieldValue('rental_price', event.target.value, true);
                }}
                handleChange={(event) => {
                  formik.setFieldValue('rental_price', event.target.value, true);
                }}
                value={formik?.values['rental_price']}
                skeletonLoading={loading}
                errorMsg={
                  formik?.errors['rental_price'] && formik?.touched['rental_price'] && formik?.errors['rental_price']
                }
                suffix={<span className="color-gray-dark">{t(tenantConstants.currency)}</span>}
                extra={() => {
                  return formik?.values['rental_price'] && !formik?.errors['rental_price'] ? (
                    <PriceCheckValue>
                      <Number
                        type="price"
                        value={formik?.values['rental_price']}
                        tooltip={false}
                        compact={false}
                        className={'text-muted'}
                      />
                    </PriceCheckValue>
                  ) : null;
                }}
                disabled={!!formik?.values['rental_price']}
              />
            </>
          )}
          {!!formik?.values?.listingTypes?.length && (
            <Select
              name="sub_listing_type"
              key="sub_listing_type"
              label={t('Property Type')}
              labelIcon="FiUser"
              placeholder={t('Select Property Type')}
              onChange={(_value, option) => formik.setFieldValue('sub_listing_type', option, true)}
              onBlur={() => formik?.setFieldTouched('sub_listing_type', true)}
              value={tenantUtils.getLocalisedString(formik?.values?.['sub_listing_type'], 'title')}
              options={formik?.values?.listingTypes}
              getOptionLabel={(op) => tenantUtils.getLocalisedString(op, 'title')}
              getOptionValue={(op) => op?.id}
              disabled={alreadyPosted}
              errorMsg={
                formik?.errors['sub_listing_type'] &&
                formik?.touched['sub_listing_type'] &&
                formik?.errors['sub_listing_type']
              }
            />
          )}
          {!skipFieldsForField?.['bedrooms'].property_type[
            formik.values['sub_listing_type']?.id || formik.values['property_type']
          ]?.skipField && (
            <RadioButtons
              name="bedrooms"
              shape="round"
              key="bedrooms"
              label={t('Number of Bedrooms')}
              inputLeftIcon="user"
              labelIcon="LuBedDouble"
              value={formik?.values['bedrooms']}
              handleChange={(e) => {
                formik.setFieldValue('bedrooms', e?.target?.value, true);
              }}
              buttonList={bedroomList}
              errorMsg={formik.errors['bedrooms'] && formik?.touched['bedrooms'] && formik.errors['bedrooms']}
            />
          )}
          {!skipFieldsForField?.['bathrooms'].property_type[
            formik.values['sub_listing_type']?.id || formik.values['property_type']
          ]?.skipField && (
            <RadioButtons
              name="bathrooms"
              shape="round"
              key="bathrooms"
              label={t('Number of Bathrooms')}
              inputLeftIcon="user"
              labelIcon="BiBath"
              value={formik?.values['bathrooms']}
              handleChange={(e) => {
                formik.setFieldValue('bathrooms', e?.target?.value, true);
              }}
              buttonList={tenantData.bathroomsList}
              errorMsg={formik.errors['bathrooms'] && formik?.touched['bathrooms'] && formik.errors['bathrooms']}
            />
          )}
          {!skipFieldsForField?.['furnished'].property_type[
            formik.values['sub_listing_type']?.id || formik.values['property_type']
          ] && (
            <RadioButtons
              name="furnished"
              shape="round"
              key="furnished"
              label={t('Furnished')}
              inputLeftIcon="user"
              labelIcon="LuLamp"
              value={formik?.values['furnished']}
              handleChange={(e) => {
                formik.setFieldValue('furnished', e?.target?.value, true);
              }}
              buttonList={[
                { key: '1', label: t('Yes') },
                { key: '0', label: t('No') },
              ]}
              errorMsg={formik.errors['furnished'] && formik?.touched['furnished'] && formik.errors['furnished']}
            />
          )}
          {formik?.values?.residence_type_applicable && formik?.values?.residenceTypes?.length > 0 && (
            <Select
              name="residence_type"
              key="residence_type"
              label={t('Residence Type')}
              labelIcon="IconResidentialPlots"
              placeholder={t('Select Residence Type')}
              value={formik?.values['residence_type']}
              onChange={(e) => {
                formik?.setFieldValue('residence_type', e);
              }}
              onBlur={() => formik?.setFieldTouched('residence_type', true)}
              options={formik?.values?.residenceTypes}
              getOptionLabel={(op) => tenantUtils.getLocalisedString(op, 'name')}
              getOptionValue={(op) => op.id}
            />
          )}
        </Group>
      </CardBlock>
      <CardBlock icon="IconAdInformation" title={t('Features and Amenities')}>
        <Group id="amenities" gap="32px" style={{ maxWidth: 640 }}>
          <AddAmenities
            name="feature_and_amenities"
            handleChange={(values) => {
              formik.setFieldValue('feature_and_amenities', values);
            }}
            handleBlur={formik?.handleBlur}
            value={formik?.values['feature_and_amenities']}
            skeletonLoading={loading}
            key="feature_and_amenities"
            labelIcon="MdOutlineOtherHouses"
            bodyStyle={{ paddingBlockStart: 'initial' }}
            payloadKey={formik.values?.['property_type']}
          />
        </Group>
      </CardBlock>
      <CardBlock icon="IconFeaturesAmenities" title={t('Title and Description')}>
        <Group id="description" gap="32px" style={{ maxWidth: 640 }}>
          <GenerateContentField
            formik={formik}
            setSubmitDisable={setLoading}
            labelIcon="PiTextT"
            desc={'title'}
            skipField={locale == 'en' ? 'property_description_en' : 'property_description_ar'}
            name={locale == 'ar' ? 'property_title_ar' : 'property_title_en'}
            dir={locale == 'ar' ? 'rtl' : ''}
            label={locale == 'ar' ? 'عنوان' : 'Title'}
            placeholder={
              locale == 'ar'
                ? 'أدخل العنوان، على سبيل المثال، منزل جديد جميل...'
                : 'Enter title e.g Beautiful new house...'
            }
            payloadKey={locale == 'en' ? 'title' : 'title_ar'}
          />

          <GenerateContentField
            formik={formik}
            setSubmitDisable={setLoading}
            labelIcon="BiDetail"
            lineCount={isMobile ? 3 : 5}
            limit={2500}
            desc={'description'}
            skipField={locale == 'en' ? 'property_title_en' : 'property_title_ar'}
            payloadKey={locale == 'en' ? 'description' : 'description_ar'}
            name={locale == 'ar' ? 'property_description_ar' : 'property_description_en'}
            dir={locale == 'ar' ? 'rtl' : ''}
            label={locale == 'ar' ? 'وصف' : 'Description'}
            placeholder={locale == 'ar' ? 'صف عقارك بالتفصيل' : 'Describe your property in detail'}
          />
        </Group>
      </CardBlock>
      <CardBlock icon="IconContactInfo" title={t('Contact Information')}>
        <Group id="contactInfo" gap="32px" style={{ maxWidth: 640 }}>
          {user?.is_agency_admin && (
            <Select
              name="posting_as"
              key="posting_as"
              label={t('Posting As')}
              labelIcon="FiUser"
              placeholder={t('Select User')}
              value={tenantUtils.getLocalisedString(formik?.values['posting_as'], 'name')}
              // onChange={onUserChange}
              onBlur={() => formik?.setFieldTouched('posting_as', true)}
              options={users}
              getOptionLabel={(op) => tenantUtils.getLocalisedString(op, 'name')}
              getOptionValue={(op) => op.id}
              disabled={true}
            />
          )}

          <Group template="max-content auto" gap="16px">
            <MobileVerification
              name={`mobile`}
              placeholder={t('Enter Phone Number')}
              defaultCountry="SA"
              value={formik?.values['mobile']}
              onChange={(value) => {
                formik.setFieldValue(`mobile`, !!value ? value : '', true);
              }}
              errorMsg={formik.errors['mobile'] && formik.touched['mobile'] && formik.errors['mobile']}
              touched={formik.touched.mobile}
              onBlur={() => formik.setFieldTouched(`mobile`, true)}
              label={t('Phone Number')}
              labelIcon={'MdSmartphone'}
              isMobile={isMobile}
              userType={'User'}
              isUserVerified={!!user?.is_mobile_verified}
              disabled={user?.mobile && !!user?.is_mobile_verified}
              containerClassName={'pos-rel'}
              userId={user?.id}
              countrySelectProps={{ disabled: true }}
            />
          </Group>
        </Group>
      </CardBlock>
      {user?.credits?.ksa?.available > 0 && (
        <div id="creditsInfo">
          <CardBlock icon="IconPackageCredit" title={t('Package & Credits Info')}>
            {isMobile && (
              <>
                <Flex gap="16px" align="start">
                  {/* <Icon
                  icon={'IconListings'}
                  style={{ borderRadius: '50%' }}
                  iconProps={{ size: 16 }}
                  width={isMobile ? 32 : 52}
                  height={isMobile ? 32 : 52}
                /> */}

                  <IconStyled>
                    <Icon icon="IconCredit" />
                  </IconStyled>
                  <Flex vertical gap="8px" className="w-100">
                    <Text strong>{t('Package & Credits Info')}</Text>
                  </Flex>
                </Flex>
              </>
            )}
            <CreditInfo
              isMobile={isMobile}
              loading={loading}
              cardStyle={{ marginInlineStart: '48px', width: !isMobile && '594px', borderWidth: 1 }}
            />
          </CardBlock>
        </div>
      )}
    </>
  );
};

const CardBlock = ({ title, children, icon, loading, renderTitleOnMobile = false }) => {
  const isMob = useSelector((state) => state.AppConfig.isMobile);
  const renderTitle = (icon = 'MdCircle', title = t('Block title goes here')) => {
    return (
      <Col xs={24} lg={5} style={{ alignSelf: 'self-start' }}>
        <Row align="middle" gutter={[16, 0]}>
          <Col xs={4} lg={24}>
            {loading ? (
              <Skeleton type="avatar" size={isMob ? 40 : 52} className="mb-8" />
            ) : (
              <Icon icon={icon} width={isMob ? 40 : 52} height={isMob ? 40 : 52} />
            )}
          </Col>
          <Col xs={20} lg={24}>
            {loading ? <Skeleton type="title" width={100} /> : <BlockTitle>{title}</BlockTitle>}
          </Col>
        </Row>
      </Col>
    );
  };

  return (
    <CardListing>
      <Row align="middle" gutter={[32, 16]}>
        {(renderTitleOnMobile || !isMob) && renderTitle(icon, title)}
        <Col xs={24} lg={19}>
          {children}
        </Col>
      </Row>
    </CardListing>
  );
};

const PostListingSkeleton = () => {
  const renderFields = (list, key) => {
    return [1, 2, 3, 4, 5].map((e) => {
      return (
        <Col key={e}>
          <Row className="mb-8">
            <Skeleton type="avatar" />
            <Skeleton type="input" />
          </Row>
          <Skeleton type="input" />
        </Col>
      );
    });
  };
  return [1, 2, 3].map((item) => (
    <CardBlock loading key={item}>
      <Group style={{ maxWidth: 640 }}>{renderFields()}</Group>
    </CardBlock>
  ));
};

export default RenderPostListingFields;
