import tenantApis from '@api';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Icon, notification } from '../components/common';
import { fetchQuotaCreditsData, updateListForDashboard } from '../redux/dashboard/actionCreators';
import { updateList } from '../redux/listings/actionCreator';
import useGetLocation from './useGetLocation';
import { fetchUserDetail } from '../redux/user/actionCreator';
import { getTimeDateString } from '../utility/date';
import { TIME_DATE_FORMAT } from '../constants/formats';
import { submitUpgradePopupClickEvent } from '../services/analyticsService';

export const useApplyProductModalData = (user,deductionModalRef, serviceDataRef, listing) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useGetLocation();
  const navigate = useNavigate();
  const [confirmationLoading, setConfirmationLoading] = useState(false);
  const [actionLoading, setActionLoading] = useState(false);
  const [selectedPaymentOption, setSelectedPaymentOption] = useState('credits');
  const [action, setAction] = useState(null);
  const [listingData, setListingData] = useState(null);
  const { selectedUser: selectedUser } = useSelector((state) => state.Dashboard);
  const isMemberArea = useSelector((state) => state.AppConfig.isMemberArea);


  const leads = {
    views: listing?.[0]?.views,
    leads: listing?.[0]?.leads,
    clicks: listing?.[0]?.clicks,
    calls: listing?.[0]?.calls,
    emails: listing?.[0]?.emails,
    sms: listing?.[0]?.sms,
    whatsapp: listing?.[0]?.whatsapp,
    chat: listing?.[0]?.chat,
  }; //data[0] bayut platforms data for specific bayut

  const updateListingReduxData = (response) => {
    if (location.pathname === '/dashboard') {
      dispatch(
        fetchQuotaCreditsData(selectedUser ? selectedUser?.id : user?.agency?.id ? -1 : user?.id, action?.platform),
      );
      dispatch(updateListForDashboard(response));
    } else {
      isMemberArea && dispatch(fetchQuotaCreditsData(user?.id, action?.platform));
      dispatch(updateList(response));
    }
  };

  const getServicePayload = () => {
    const values = serviceDataRef?.current?.getValues();
    return values
      ? {
          [action?.applicableProduct?.slug]: {
            comments: values?.comments,
            requested_at: getTimeDateString(values?.requested_at, TIME_DATE_FORMAT, false, true),
          },
        }
      : null;
  };

  const onApplyProduct = async (productId, listingId) => {
    serviceDataRef?.current?.handleSubmit();
    const errors = await serviceDataRef?.current?.getErrors();
    if (!errors) {
      setConfirmationLoading(true);
      const add_on = getServicePayload();
      const response = await tenantApis.applyProducts(listingId, {
        product_ids: [productId],
        ...(add_on && { add_on }),
      });
      if (response) {
        submitUpgradePopupClickEvent(user, response.error, listing?.[0]);
        setConfirmationLoading(false);
        deductionModalRef?.current && deductionModalRef.current.hideQuotaCreditModal();
        setActionLoading(false);
        if (response?.error) {
          notification.error(response?.error);
        } else if (response?.data?.listing) {
          const data = {
            ...tenantApis.listingMapper(response.data.listing, user, true, leads),
            listing_id: listingId,
            valueExists: true,
          };
          updateListingReduxData(data);
          dispatch(fetchUserDetail(user?.id));
        }
      }
    }
  };

  const createCart = async (productId, listingId, expiryDays, creditsAmount, purchaseFullCredits) => {
    serviceDataRef?.current?.handleSubmit();
    const errors = await serviceDataRef?.current?.getErrors();
    const cart = {
      total_credits: creditsAmount,
      listing_expiry_days: expiryDays,
      source: 'profolio',
      listing_id: listingId,
      cart_details_attributes: [{ item_id: productId, item_type: 'Product', source: 'profolio', quantity: 1 }],
      purchased_full_credits: purchaseFullCredits,
    };
    const add_on = getServicePayload();
    if (!errors) {
      setConfirmationLoading(true);
      const response = await tenantApis.createUpsellCart({ cart, ...(add_on && { add_on }) });
      if (response) {
        submitUpgradePopupClickEvent(user, response.error, listing?.[0]);
        setConfirmationLoading(false);
        deductionModalRef?.current && deductionModalRef.current.hideQuotaCreditModal();
        setActionLoading(false);
        if (response.error) {
          notification.error(response.error);
        } else {
          navigate(`/checkout?cart_id=${response?.cart?.id}&upgrade_listing=true`, {
            state: {
              disposition: listingData?.disposition?.slug,
              status: listingData?.status?.slug,
              interactedFrom: 'manage-listings',
            },
          });
        }
      }
    }
  };
  const onSuccessQuotaCreditModal = () => {
    const isUsingCredits = selectedPaymentOption === 'credits';

  
    if (user?.isCurrencyUser) {
      if (action?.isSufficient && isUsingCredits) {
        onApplyProduct(action?.applicableProduct?.product_id, action?.listing_id)
      } else {
        createCart(
          action?.applicableProduct?.product_id,
          action?.listing_id,
          action?.expiryDays,
          isUsingCredits ? action?.creditsDiff : action?.requiredCredits,
          !isUsingCredits
        )
      }
    } else {
      onApplyProduct(action?.applicableProduct?.product_id, action?.listing_id)
    }
  };

  const getDeduction = async (deductionType, listing, product) => {
    setListingData(listing);
    setActionLoading((prev) => ({ ...prev, [`${listing?.slug}${product?.name}`]: true }));
    const response = await tenantApis.fetchApplicableProductsById(listing?.property_id, product?.slug);
    if (response) {
      if (response?.error) {
        setActionLoading(false);
        notification.error(response.error);
      } else if (!response?.applicableProduct) {
        setActionLoading(false);
        notification.error(t('Product can not be applied'));
      } else if (!user?.isCurrencyUser) {
        if (response?.available_credits >= response?.applicableProduct?.quantity_required) {
          setAction({ ...response, platform: listing?.slug, expiryDays: listing?.expiryDays });
          deductionModalRef?.current && deductionModalRef.current.showQuotaCreditModal();
        } else {
          setActionLoading(false);
          notification.error(t(`You don't have enough credits`));
        }
      } else {
        const creditsDiff =
          response?.available_credits - response?.applicableProduct?.credits_required?.[listing?.expiryDays];
        setAction({
          ...response,
          platform: listing?.slug,
          expiryDays: listing?.expiryDays,
          isSufficient: !!(creditsDiff >= 0),
          creditsDiff: Math.abs(creditsDiff),
          requiredCredits: response?.applicableProduct?.credits_required?.[listing?.expiryDays],
        });
        deductionModalRef?.current && deductionModalRef.current.showQuotaCreditModal();
      }
    }
  };

  const paymentOptions = useMemo(
    () => [
      {
        key: 'credits',
        title: 'Credits',
        description: 'Pay using your available credits to get the upgrade instantly',
        value: action?.requiredCredits,
        prefix: <Icon icon="IconTotalCredit"></Icon>,
      },
      {
        key: 'currency',
        title: 'Credit or Debit Card',
        description: 'Pay online in SAR using other payment methods at checkout',
        value: action?.requiredCredits * action?.credit_unit_price,
        prefix: t('SAR'),
      },
    ],
    [action],
  );

  const onChangePaymentOption = (e) => {
    setSelectedPaymentOption(e.key);
  };

  const onCancelModal = () => {
    setActionLoading(null);
    serviceDataRef?.current?.resetForm();
    deductionModalRef?.current?.hideQuotaCreditModal();
  };

  return {
    selectedPaymentOption,
    confirmationLoading,
    deductionModalRef,
    paymentOptions,
    action,
    actionLoading,
    onSuccessQuotaCreditModal,
    getDeduction,
    onChangePaymentOption,
    onCancelModal,
    onApplyProduct,
  };
};
