import { formatMobile } from '../../../../../utility/utility';
import { imageStateObject } from '../../../../../helpers/imageHelpers/imageStateObject';

export const getAgencyValues = (values) => {
  if (!values) return null;
  return {
    companyTitle: values?.name,
    city: { city: values?.city_id ? { location_id: values?.city_id } : null },
    email: values?.email,
    website: values?.website,
    description: values?.description,
    mobile: values?.mobile ? formatMobile(values?.mobile, 'singleNumber').split(',')?.[0] : '',
    landline: values?.phone ? formatMobile(values?.phone, 'singleNumber') : '',
    whatsapp: values?.whatsapp ? formatMobile(values?.whatsapp, 'singleNumber') : '',
    agencyWhatsappForAll: values?.use_agency_whatsapp,
    logo: values?.agency_logo && [{ gallerythumb: values?.agency_logo, ...imageStateObject() }],
    address: values?.company_address,
    updatePropertyListings: values?.update_agency_listing_details,
    enable_whatsapp_leads: values?.enable_whatsapp_leads,

    ownerId: values?.creator_id,
    name: values?.owner_name,
    designation: values?.designation,
    message: values?.owner?.message,
    profilePhoto: values?.owner?.profile_image && [
      { gallerythumb: values?.owner?.profile_image, ...imageStateObject() },
    ],
  };
};
