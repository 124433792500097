import { assignDefaultValueToObject } from '../../../utility/utility';
import HeaderComponent from './header-component/header-components';
import Health from './listing/health/health';
import PlatformActions from './listing/listing-platform-actions/platformActions';
import ListingsRowActions from './listing/listing-row-actions/listing-row-actions';
import ListingCard from './listing/listingCard';
import ListingsPage from './listing/listings';
import PostListingPage from './post-listing/post-listing';
import PropShop from './prop-shop/prop-shop';

import { AgencyUserRowActions } from './agency-staff/agency-user-row-actions/agency-user-actions';
import CreditInfoDrawer from './credit-info-drawer/creditInfoDrawer';
import InboxPage from './email-leads/emailLeads';
import HeaderLink from './headerLink/headerLink';
import { HelpAndSupport } from './helpAndSupport/helpAndSupport';
import { Layout } from './layout/layout';
import { Logo } from './logo/Logo';
import OwnerProfile from './owner-profile/owner-profile';
import PageAlerts from './page-alerts/page-alerts';
import LocationSelect from './post-listing/location-select/location-select';
import PostAdModal from './post-listing/PostAdModal';
import PostListingWithPropertyType from './post-listing/post-listing-with-options';
import UserPreferencePage from './preference/preference';
import ProfileKCForm from './profile/profileKcForm';
import { CreditTopUps } from './prop-shop/credit-top-ups/creditTopUps';
import ListingsSummary from './reports/ListingsSummary';
import SmartCreditsUtilizationModal from './smart-credits-utilisation/smart-credits-utilisation';
import UpgradeListingPage from './upgrade-listing/upgrade-listing';
import ProfileCompletionAlert from './profile-completion/profile-completion';
import ProfileStatusPopUp from './profile-completion/profile-settings-card';

let Components = {
  HeaderComponent,
  PostListingPage,
  PlatformActions,
  ListingsRowActions,
  LocationSelect,
  Health,
  CreditInfoDrawer,
  HeaderLink,
  PostAdModal,
  ListingsPage,
  PropShop,
  UpgradeListingPage,
  ListingCard,
  AgencyUserRowActions,
  ListingsSummary,
  Logo,
  PageAlerts,
  Layout,
  InboxPage,
  ProfileKCForm,
  OwnerProfile,
  CreditTopUps,
  SmartCreditsUtilizationModal,
  HelpAndSupport,
  UserPreferencePage,
  PostListingWithPropertyType,
  ProfileCompletionAlert,
  ProfileStatusPopUp,
};

export default assignDefaultValueToObject(Components, () => null);
