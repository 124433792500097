export const agencyUser = (values) => {
  return {
    user: {
      name: values?.name,
      name_l1: values?.nameArabic,
      email: values?.email,
      ...(values?.password && { pass: values?.password }),
      mobile: values?.mobile && values?.mobile,
      // phone: values?.landline && values?.landline,
      whatsapp: values?.whatsapp && values?.whatsapp,
      address: values?.address,
      national_short_address: values?.nationalShortAddress,
      country_id: values?.country || 155,
      city_id: values?.city?.city?.location_id || null,
      user_profile_id:
        !!values?.image && !!values?.image?.length ? values?.image?.[0]?.uuid || values?.image?.[0]?.id : '',
    },
  };
};

export const permissions = (userId, values) => {
  return {
    user_id: userId,
    privs: {
      listings: values.listings ? 1 : 0,
      stats: values.stats ? 1 : 0,
      leads: values.leads ? 1 : 0,
      inbox: values.inbox ? 1 : 0,
      staff: values.staff ? 1 : 0,
      profile: values.profile ? 1 : 0,
    },
  };
};

export const loggedInUser = (values) => {
  return {
    user: {
      name: values?.name,
      name_l1: values?.nameArabic,
      mobile: values?.mobile,
      national_short_address: values?.nationalShortAddress,
      address: values?.address,
      country_id: values?.country || 155,
      city_id: values?.location?.city?.location_id || null,
      user_profile_id:
        !!values?.profile_image && !!values?.profile_image.length
          ? values?.profile_image?.[0]?.uuid || values?.profile_image?.[0]?.id
          : '',
      update_user_listing_details: !!values?.updatePropertyListings,
      // ...(!!values?.phone && { phone: values?.phone }),
      //...(!!values?.whatsapp && { whatsapp: values?.whatsapp }),
      whatsapp: values?.whatsapp,
      description: values?.agentDescription,
      description_l1: values?.agentDescriptionArabic,
      service_areas: values?.serviceArea,
      languages: values?.languages,
      experience: values?.experience,
    },
  };
};

export const preferencesUpdate = (values) => {
  return {
    preferences: {
      email_notification: values?.email_notification,
      newsletter: values?.newsletter,
      automated_reports: values?.automated_reports,
      currency: values?.currency,
      area_unit: values?.area_unit,
    },
  };
};

export const agencySettings = (values, locale) => {
  return {
    agency: {
      ...(locale == 'en' ? { name: values?.buisnessName } : { name_l1: values?.buisnessName }),
      country_id: values?.country,
      city_id: values?.city?.city?.city_id,
      address: values?.address,
      national_short_address: values?.nationalShortAddress,
      mobile: values?.mobile && values?.mobile,
      use_agency_whatsapp: !!values?.agencyWhatsappForAll ? values?.agencyWhatsappForAll : 0,
      agency_logo_id: !!values?.logo?.length ? values?.logo[0]?.uuid || values?.logo[0]?.id : null,
      //update_agency_listing_details: !!values?.updatePropertyListings ? values?.updatePropertyListings : 0,
      update_agency_listing_details: !!values?.updateCompanyListings ? values?.updateCompanyListings : 0,
      ...(!!values?.email && { email: values?.email }),
      ...(!!values?.website && { website: values?.website }),
      ...(!!values?.description && locale == 'en'
        ? { description: values?.description }
        : { description_l1: values?.description }),
      // ...(values?.landline && { phone: values?.landline }),
      // ...(!!values?.whatsapp && { whatsapp: values?.whatsapp }),
    },
  };
};

export const userPassword = (values) => {
  return {
    user: {
      current_password: values?.oldPassword,
      new_password: values?.newPassword,
      new_password_confirmation: values?.confirmPassword,
    },
  };
};
export const autoGeneratedContentPayload = (values, content_type) => {
  const isDailyRentalListing = values?.listing_purpose?.slug === 'daily-rental';
  return {
    content_type: content_type,
    purpose_name: values?.listing_purpose?.title,
    type: isDailyRentalListing
      ? values?.property_type_label
      : values?.sub_listing_type?.label || values?.listing_type?.title,
    city: values?.location?.city_id,
    location: values?.location?.title,
    area: values?.area_unit?.value,
    furnished: isDailyRentalListing ? 'yes' : values?.furnished === '1' ? 'yes' : 'no',
    rental_frequency: isDailyRentalListing ? 1 : values?.listing_purpose?.title === 'Rent' && values?.rental_frequency,
    area_unit: values?.area_unit?.id,
    price:
      values?.listing_purpose?.title === 'Rent'
        ? values?.rental_price
          ? parseInt(values?.rental_price)
          : 0
        : values?.price
          ? parseInt(values?.price)
          : 0,
    beds: parseInt(values?.bedrooms) || null,
    baths: parseInt(values?.bathrooms) || null,
  };
};

export const convertToAgencyPayload = (values, userId) => {
  return {
    user: {
      agency_attributes: {
        name: values?.name,
        name_l1: values?.nameArabic,
        email: values?.email,
        mobile: values?.mobile,
        landline: values?.landline,
        entity_type: values?.typeOfAgency,
        address: values?.address,
        city_id: values?.city?.city?.location_id,
        agency_logo_id: values?.agency_logo?.[0]?.uuid,
        website: values?.website,
        description: values?.description,
        description_l1: values?.descriptionArabic,
        creator_id: userId,
      },
    },
  };
};
