import tenantApi from '@api';
import tenantConstants from '@constants';
import tenantTheme from '@theme';
import tenantUtils from '@utils';
import { Form, Input, Space, Typography } from 'antd';
import cx from 'clsx';
import debounce from 'lodash/debounce';
import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Card,
  Flex,
  Heading,
  Icon,
  Popover,
  Select,
  TextWithIcon,
  notification,
} from '../../../../../components/common';
import { regex } from '../../../../../constants/regex';
import { useRouteNavigate } from '../../../../../hooks';
import { setMonthRules } from '../../../../../redux/monthRules/actionCreator';
import { getTopUpClickEvent, suggestCreditsClickEvent } from '../../../../../services/analyticsService';
import { convertQueryObjToString } from '../../../../../utility/urlQuery';
import { formatNumberString } from '../../../../../utility/utility';
import { CreditTopUpCard } from '../styled';

const { Text } = Typography;

export const CreditTopUps = forwardRef(({ redirectUrl, header = true, bodyStyle, style }, ref) => {
  const isMobile = useSelector((state) => state.AppConfig.isMobile);
  const navigate = useRouteNavigate();
  const { t } = useTranslation();
  const { monthRules, loading, error } = useSelector((state) => state.monthRules);

  const [disableAll, setDisableAll] = useState(false);
  const [inputQuantity, setInputQuantity] = useState();
  const [customCredits, setCustomCredits] = useState({ count: 1, price: 0, nonDiscountedPrice: 0 });
  const inputRef = useRef(null);
  const [selectedDuration, setSelectedDuration] = useState(null);
  const { isMemberArea } = useSelector((state) => state.AppConfig);
  const { user } = useSelector((state) => state.loginUser);
  const dispatch = useDispatch();
  useEffect(() => {
    !!isNonPackageUser() && dispatch(setMonthRules());
  }, []);

  useEffect(() => {
    if (!disableAll && inputRef.current) {
      inputRef.current.focus();
    }
  }, [disableAll]);
  useImperativeHandle(ref, () => ({
    clearState() {
      setDisableAll(false);
      setInputQuantity('');
      setCustomCredits({ count: 0, price: 0, nonDiscountedPrice: 0 });
      setSelectedDuration(null);
    },
  }));
  const isNonPackageUser = () => {
    return isMemberArea || !!user?.is_custom_package_allowed;
  };
  const renderMonthsTooltip = () => {
    return (
      <Popover
        placement="top"
        content={
          <div style={{ maxWidth: '300px', width: '100%' }}>{t('Enter atleast 100 credits to increase duration')}</div>
        }
        action="hover"
      >
        <Icon icon="AiOutlineInfoCircle" iconProps={{ color: '#222' }} />
      </Popover>
    );
  };
  const handleCheckout = async () => {
    getTopUpClickEvent(user, customCredits?.count);
    let cart;
    cart = {
      total_credits: !!isNonPackageUser() ? customCredits?.count * selectedDuration : customCredits?.count,
      ...(!!isNonPackageUser() && { duration_in_months: selectedDuration }),
      source: 'profolio',
    };
    setDisableAll(true);
    const response = await tenantApi.createCart(cart);
    if (response.error) {
      notification.error(response.error);
    } else {
      navigate(`/checkout?${convertQueryObjToString({ cart_id: response?.id, ...(redirectUrl && { redirectUrl }) })}`);
    }
    setDisableAll(false);
  };
  const getMonthDurations = (value) => {
    let options = monthRules?.reduce((acc, data) => {
      if (parseInt(value) >= data?.min_range && parseInt(value) <= data?.max_range) {
        return acc.concat(data?.months);
      }
      return acc;
    }, []);
    return options;
  };

  const isProfolioUser = () => {
    if (selectedDuration >= 6 && parseInt(inputQuantity) >= 100) {
      return true;
    }
    return false;
  };
  const renderFieldSubText = () => {
    return !isProfolioUser() ? (
      <div>
        <Trans i18nKey="unlockProfolio" values={{ credits: 100 }} components={{ sup: <sup /> }} />
      </div>
    ) : (
      <div>
        <Trans i18nKey="accessProfolio" components={{ sup: <sup /> }} />
      </div>
    );
  };

  const renderFooterText = () => {
    return !!isNonPackageUser() ? (
      <div style={{ color: tenantTheme['base-color'] }}>
        {inputQuantity && selectedDuration > 1
          ? `${inputQuantity} ${t('credits will be allocated every month for next ')} ${selectedDuration} ${t(
              ' months',
            )}`
          : t('Each credit allocation is valid for 1 month')}
      </div>
    ) : (
      <div style={{ color: tenantTheme['base-color'] }}>{t('Each credit allocation is valid for 1 month')}</div>
    );
  };

  const getCreditsPrice = async (creditsCount, duration) => {
    setDisableAll(true);
    const response = await tenantApi.getCustomCreditsPrice(
      !!isNonPackageUser() && duration
        ? { total_credits: creditsCount * duration, duration_in_months: duration }
        : { total_credits: creditsCount },
    );
    if (response) {
      if (response.error) {
        notification['error'](response.error);
      } else {
        setCustomCredits((prevState) => {
          return {
            ...prevState,
            count: creditsCount,
            price: response?.price,
            nonDiscountedPrice: response?.nonDiscountedPrice,
          };
        });
      }
      setDisableAll(false);
    }
  };

  const debouncedCreditsPrice = useCallback(
    debounce((creditsCount, duration) => getCreditsPrice(creditsCount, duration), 700),
    [],
  );

  const onInputQuantityChange = (event, callDebounce) => {
    suggestCreditsClickEvent(user, customCredits?.count);
    const quantity = event?.target?.value;
    if (!quantity) {
      setInputQuantity('');
      setSelectedDuration(null);
      setCustomCredits({ count: 0, price: 0, nonDiscountedPrice: 0 });
    } else {
      if (regex.naturalNumbers.test(quantity)) {
        setInputQuantity(parseInt(quantity));
        const duration =
          getMonthDurations(quantity)?.length > 1 ? selectedDuration : getMonthDurations(quantity)?.[0]?.id;
        setSelectedDuration(duration);
        if (!!callDebounce) {
          debouncedCreditsPrice(quantity, duration);
        } else {
          getCreditsPrice(quantity);
        }
      }
    }
  };

  return (
    <CreditTopUpCard
      className="topUpCard"
      bodyStyle={{ padding: isMobile ? 16 : 24, ...bodyStyle }}
      style={{ borderRadius: isMobile && 0, ...style }}
    >
      <Flex vertical gap="24px" className={isMobile && 'mb-16'}>
        <div>
          <Heading as={isMobile ? 'h6' : 'h4'} style={{ fontWeight: '700' }} className="mb-4">
            {!!isNonPackageUser() ? t('Custom Package & Credits') : t('Credit Top-up')}
          </Heading>
          <Text type="secondary" className={isMobile && 'fz-12'}>
            {t(
              'Ran out of credits? Add more credits to your account to post more listings and avail more services seamlessly.',
            )}
          </Text>
        </div>
        <div className="mb-24">
          <Flex className="mb-8" template={isMobile && 'initial'} align="start" gap={isMobile ? '16px' : '24px'}>
            <Form layout="vertical" style={{ maxWidth: 340, width: '100%' }}>
              <Form.Item className="mb-0" label={t('Monthly Credits')}>
                <Input
                  ref={inputRef}
                  placeholder={t('Enter the number of credits')}
                  className="w-100 fw-600"
                  style={{ height: 43 }}
                  size="large"
                  value={inputQuantity}
                  disabled={disableAll}
                  onChange={(e) => {
                    onInputQuantityChange(e, true);
                  }}
                  maxLength={6}
                />{' '}
              </Form.Item>
            </Form>
            {!!isNonPackageUser() && (
              <>
                {' '}
                <div style={{ alignSelf: 'center', marginBlockStart: '35px' }}>
                  <Icon icon="MdClose" size={16} color="#DEDEDE" />
                </div>
                <Select
                  label={t('Months')}
                  inputLoading={disableAll}
                  disabled={!inputQuantity || getMonthDurations(inputQuantity)?.length == 1}
                  placeholder={t('Select Months')}
                  size="default"
                  renderPopover={
                    (!inputQuantity || getMonthDurations(inputQuantity)?.length == 1) && renderMonthsTooltip
                  }
                  value={selectedDuration}
                  options={getMonthDurations(inputQuantity)}
                  getOptionLabel={(e) => tenantUtils.getLocalisedString(e, 'value')}
                  getOptionValue={(e) => e?.id}
                  onChange={(e) => {
                    setSelectedDuration(e);
                    inputQuantity && debouncedCreditsPrice(parseInt(inputQuantity), e);
                  }}
                  labelProps={{
                    color: '#222',
                    className: 'align-center-v',
                    style: { gap: '6px', '--label-font-weight': '400' },
                  }}
                  style={{ maxWidth: 340, width: '100%' }}
                ></Select>
              </>
            )}
          </Flex>

          {!!isNonPackageUser() && (
            <TextWithIcon
              textSize={isMobile && '11px'}
              style={!isMobile ? { textWrap: 'noWrap' } : {}}
              icon={!isProfolioUser() ? 'UnlockIcon' : 'GiCheckMark'}
              iconProps={{ color: tenantTheme['primary-color'], size: 16 }}
              title={renderFieldSubText()}
            />
          )}
        </div>
      </Flex>
      <Card
        className="creditCard"
        bodyStyle={{ padding: '12px 16px' }}
        style={{ backgroundColor: tenantTheme['primary-light-4'], borderWidth: 0 }}
      >
        <Flex vertical={isMobile && true} justify="space-between" gap="20px">
          <div>
            <Flex gap="8px" className={'mb-4'}>
              <Text className="fw-700">{t('Total Credits')}</Text>
              <TextWithIcon
                align="center"
                justify="center"
                value={
                  inputQuantity
                    ? getMonthDurations(inputQuantity)?.length > 1 && !!isNonPackageUser()
                      ? parseInt(inputQuantity) * selectedDuration
                      : inputQuantity
                    : 0
                }
                icon="IconTotalCredit"
                fontWeight="700"
                gap="6px"
              />
            </Flex>

            <TextWithIcon
              textSize={isMobile && '12px'}
              iconProps={{ color: tenantTheme['primary-color'], size: '1em' }}
              icon="IoTimeOutline"
              value={renderFooterText()}
            />
          </div>

          <Flex justify={isMobile && 'space-between'} align="center" gap="40px" className={isMobile && 'w-100'}>
            <Flex gap={isMobile ? '12px' : '16px'}>
              {customCredits?.price > 0 && inputQuantity && (
                <div>
                  <Text type="secondary" className="d-block fz-14 fw-700 text-right text-left">
                    {' '}
                    {t('Total')}
                  </Text>
                  <Text className={cx(isMobile ? 'fz-14' : 'fz-16', 'fw-700')}>
                    <span className="fw-400">{t('SAR')} </span>
                    {formatNumberString(customCredits?.price, false)}
                  </Text>
                </div>
              )}
              {customCredits?.nonDiscountedPrice > customCredits?.price && (
                <Space
                  size={4}
                  className="color-gray-dark"
                  justify="end"
                  style={{ alignSelf: 'end', marginBottom: '1px', fontSize: isMobile && '12px' }}
                >
                  {t(tenantConstants.currency)}
                  <span
                    style={{
                      textDecoration: 'line-through' + tenantTheme['black'],
                    }}
                  >
                    {formatNumberString(customCredits?.nonDiscountedPrice, { fractionDigits: 1 })}
                  </span>
                </Space>
              )}
            </Flex>
            <Button
              onClick={handleCheckout}
              isLoading={disableAll}
              disabled={disableAll || !inputQuantity}
              style={{ minWidth: isMobile ? '120px' : '187px' }}
              size={isMobile ? 'small' : 'large'}
              type="primary"
            >
              {!!isNonPackageUser() ? t('Proceed to Payment') : t('Get Top-up')}
            </Button>
          </Flex>
        </Flex>
      </Card>
    </CreditTopUpCard>
  );
});
