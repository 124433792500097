import tenantApi from '@api';
import tenantRoutes from '@routes';
import tenantTheme from '@theme';
import { Typography } from 'antd';
import { useFormik } from 'formik';
import React, { forwardRef, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
import {
  Button,
  Group,
  Icon,
  LinkWithIcon,
  Modal,
  Popover,
  Segmented,
  Select,
  TextInput,
  notification,
} from '../../../../components/common';
import { SvgLicense } from '../../../../components/svg';
import { useRouteNavigate } from '../../../../hooks';
import setApplicableLicenseList from '../../../../redux/applicable-license/actionCreator';
import { regaAdValidationEvent } from '../../../../services/analyticsService';
import { getBaseURL, getClassifiedBaseURL } from '../../../../utility/env';
import { DrawerModal } from '../../../../components/common';

const PostAdModal = forwardRef(
  (
    { onSuccess = () => {}, onCancel = () => {}, children = () => null, defaultState = false, isFetch = false },
    modalRef,
  ) => {
    const { t } = useTranslation();
    const [licenseNumber, setLicenseNumber] = useState(null);
    const [showModal, setShowModal] = useState(defaultState);
    const navigate = useRouteNavigate();
    const [loading, setLoading] = useState(false);
    const { user: loggedInUser } = useSelector((state) => state.loginUser);
    const { isMemberArea, locale } = useSelector((state) => state.AppConfig);
    const dispatch = useDispatch();

    const { applicableLicenses: licenses } = useSelector((state) => state.applicableLicenseData);
    const [selectedTab, setSelectedTab] = useState(isMemberArea ? 'crNumber' : 'falLicense');

    const formikRef = useRef();

    const popoverData = useMemo(() => {
      return {
        fal_license: (
          <>
            {t(
              'The FAL License is issued by REGA which permits individuals and real estate agents to legally buy, sell, rent, and advertise real estate in Saudi Arabia.',
            )}
            <LinkWithIcon
              as="a"
              linkTitle={t('Learn more about FAL License')}
              href={
                locale == 'en'
                  ? `https://www.bayut.sa/blog/en/market-trends/rega-regulations/`
                  : 'https://www.bayut.sa/blog/%D8%A7%D8%AA%D8%AC%D8%A7%D9%87%D8%A7%D8%AA-%D8%A7%D9%84%D8%B3%D9%88%D9%82/%D8%B1%D8%AE%D8%B5%D8%A9-%D8%A7%D9%84%D8%A7%D8%B9%D9%84%D8%A7%D9%86-%D8%A7%D9%84%D8%B9%D9%82%D8%A7%D8%B1%D9%8A/'
              }
              target="_blank"
              style={{
                color: tenantTheme['primary-color'],
                marginBlockStart: '8px',
                paddingInlineStart: '2px',
                width: 'max-content',
              }}
            />
          </>
        ),
        rega_license: (
          <>
            {t(
              'License issued by REGA to the brokers for each property which serves as a proof of the legal rights of the broker to advertise it.',
            )}
            <LinkWithIcon
              as="a"
              linkTitle={t('How to get a REGA Ad License?')}
              href={
                locale == 'en'
                  ? `https://www.bayut.sa/blog/en/market-trends/rega-regulations/`
                  : 'https://www.bayut.sa/blog/%D8%A7%D8%AA%D8%AC%D8%A7%D9%87%D8%A7%D8%AA-%D8%A7%D9%84%D8%B3%D9%88%D9%82/%D8%B1%D8%AE%D8%B5%D8%A9-%D8%A7%D9%84%D8%A7%D8%B9%D9%84%D8%A7%D9%86-%D8%A7%D9%84%D8%B9%D9%82%D8%A7%D8%B1%D9%8A/'
              }
              target="_blank"
              style={{
                color: tenantTheme['primary-color'],
                marginBlockStart: '8px',
                paddingInlineStart: '2px',
                width: 'max-content',
              }}
            />
          </>
        ),
      };
    }, []);

    const fetchLicense = async () => {
      dispatch(setApplicableLicenseList(loggedInUser?.id));
    };
    useImperativeHandle(modalRef, () => ({
      show() {
        setShowModal(true);
      },
    }));

    useEffect(() => {
      !!isFetch && fetchLicense();
    }, []);

    useEffect(() => {
      if (licenses?.length == 0) {
        setSelectedTab('crNumber');
      }
      if (licenses?.length == 1) {
        setLicenseNumber(licenses?.[0]?.number);
      }
    }, [licenses]);

    const getInitialValues = () => {
      return selectedTab == 'falLicense'
        ? { falLicense: licenses?.length ? licenses?.[0]?.number : '', licenseId: '' }
        : { licenseType: isMemberArea && selectedTab == 'nationalId' ? 'user' : 'agency', advertiserId: '', adID: '' };
    };

    const resetValues = () => {
      setSelectedTab(isMemberArea ? 'crNumber' : licenses?.length ? 'falLicense' : 'crNumber');
      formik.setValues(getInitialValues());
    };
    const getValidationSchema = () => {
      return selectedTab == 'falLicense'
        ? yup.object().shape({
            falLicense: yup.string().required(t('Please select FAL License')).typeError(t('Please select FAL License')),
            licenseId: yup.number().required(t('Please enter License ID')).typeError(t('Invalid License ID')),
          })
        : yup.object().shape({
            // licenseType: yup.string().required(t('Please select License Type')),
            advertiserId: yup.number().when('licenseType', {
              is: 'agency',
              then: () => yup.number().required(t('Please enter CR Number')).typeError(t('Invalid CR Number')),
              otherwise: () => yup.number().required(t('Please enter National ID')).typeError(t('Invalid National ID')),
            }),
            adID: yup.number().required(t('Please enter Ad License Number')).typeError(t('Invalid Ad License ID')),
          });
    };

    useEffect(() => {
      formik && formik.setValues(getInitialValues());
      selectedTab == 'crNumber' && formik?.setFieldValue('licenseType', 'agency');
      selectedTab == 'nationalId' && formik?.setFieldValue('licenseType', 'user');
    }, [licenses, selectedTab]);

    const handleSubmit = async () => {
      setLoading(true);
      verifyFALLicenseClickEvent(loggedInUser);
    
      const response = await tenantApi.postNewListing(
        selectedTab === 'falLicense'
          ? { ad_license_number: formik?.values?.licenseId, license_number: formik?.values?.falLicense }
          : {
              ad_license_number: formik?.values?.adID,
              advertiser_id: formik?.values?.advertiserId,
              license_type: formik?.values?.licenseType,
            },
      );
      if (response) {
        setLoading(false);
        regaAdValidationEvent(loggedInUser, response, formik.values);
        if (response?.error) {
          notification.error(response?.error);
        } else {
          setShowModal(false);
          const listingId = response?.data?.listing?.id;
          navigate(`${tenantRoutes.app().post_listing.path}/${listingId}`);
          notification.success(t('REGA Ad details received successfully'));
          resetValues();
          onSuccess();
        }
      }
    };

    const formik = useFormik({
      initialValues: getInitialValues(),
      validationSchema: getValidationSchema(),
      validateOnChange: false,
      onSubmit: handleSubmit,
      innerRef: formikRef,
    });

    const optionTypes = useMemo(
      () => [
        { key: 'falLicense', value: 'falLicense', label: t('FAL License') },
        { key: 'crNumber', value: 'crNumber', label: t('CR Number') },
        { key: 'nationalId', value: 'nationalId', label: t('National ID') },
      ],
      [licenses],
    );

    const handleTabChange = (e) => {
      setSelectedTab(e);
      formik?.resetForm();
    };

    const renderPopover = (type) => {
      return (
        <Popover
          placement="bottom"
          content={<div style={{ maxWidth: '300px', width: '100%' }}>{popoverData?.[type]}</div>}
          action="hover"
        >
          <Icon icon="AiOutlineInfoCircle" iconProps={{ color: '#222' }} />
        </Popover>
      );
    };

    const renderAddLicenseButton = () => {
      return (
        <Button
          type="link"
          style={{ marginInlineStart: 'auto', '--btn-padding-y': 0 }}
          href={`${getClassifiedBaseURL()}/${locale}/verification/rega/?redirectPath=${getBaseURL()}/${locale}/post-listing`}
          icon="HiPlus"
        >
          {t('Add new FAL License')}
        </Button>
      );
    };

    const renderFalLicenseFields = () => {
      return (
        <>
          <div>
            <Select
              key="falLicense"
              name="falLicense"
              placeholder={t('FAL License')}
              getOptionLabel={(e) => e?.number}
              getOptionValue={(e) => e?.number}
              renderPopover={() => renderPopover('fal_license')}
              label={t('FAL License')}
              value={formik.values.falLicense || null}
              onChange={(e) => formik.setFieldValue('falLicense', e)}
              options={licenses}
              disabled={!licenses?.length}
              renderSideButton={renderAddLicenseButton}
              labelProps={{ className: 'align-center-v', style: { gap: '6px' } }}
              errorMsg={formik?.errors['falLicense'] && formik?.touched['falLicense'] && formik?.errors['falLicense']}
            />
          </div>
          <div className="mb-8">
            <TextInput
              key="licenseId"
              name="licenseId"
              type="input"
              placeholder={t('Enter REGA Ad License Number')}
              value={formik.values.licenseId}
              label={t('REGA Ad License Number')}
              renderPopover={() => renderPopover('rega_license')}
              onChange={(e) => formik.setFieldValue('licenseId', e?.target?.value)}
              onBlur={() => formik.setFieldTouched('licenseId', true)}
              errorMsg={formik?.errors['licenseId'] && formik?.touched['licenseId'] && formik?.errors['licenseId']}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  formik?.handleSubmit();
                }
              }}
              labelProps={{ className: 'align-center-v', style: { gap: '6px' } }}
            />
          </div>
        </>
      );
    };

    const renderLicenseIdFields = () => {
      return (
        <>
          {/* <RadioButtons
          key="licenseType"
          name="licenseType"
          shape="round"
          label={t('License Type')}
          value={formik.values.licenseType}
          handleChange={e => {
            formik.setFieldValue('licenseType', e?.target?.value);
            formik.setFieldValue('advertiserId', '');
            formik.setFieldValue('adID', '');
            formik.setFieldTouched('advertiserId', false);
            formik.setFieldTouched('adID', false);
          }}
          buttonList={[
            { key: 'agency', label: 'Agency' },
            { key: 'user', label: 'Individual' },
          ]}
        /> */}
          <div>
            <TextInput
              key="advertiserId"
              name="advertiserId"
              type="input"
              placeholder={formik?.values?.licenseType == 'agency' ? t('Enter CR Number') : t('Enter National ID')}
              value={formik.values.advertiserId}
              label={formik?.values?.licenseType == 'agency' ? t('CR Number') : t('National ID')}
              onBlur={() => {
                formik.setFieldTouched('advertiserId', true);
              }}
              errorMsg={
                formik?.errors['advertiserId'] && formik?.touched['advertiserId'] && formik?.errors['advertiserId']
              }
              onChange={(e) => {
                formik.setFieldValue('advertiserId', e?.target?.value);
              }}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  formik?.handleSubmit();
                }
              }}
            />
          </div>

          <div className="mb-8">
            <TextInput
              key="adID"
              name="adID"
              type="input"
              placeholder={t('Enter REGA Ad License Number')}
              value={formik.values.adID}
              label={t('REGA Ad License Number')}
              renderPopover={() => renderPopover('rega_license')}
              onChange={(e) => {
                formik.setFieldValue('adID', e?.target?.value);
              }}
              onBlur={() => {
                formik.setFieldTouched('adID', true);
              }}
              errorMsg={formik?.errors['adID'] && formik?.touched['adID'] && formik?.errors['adID']}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  formik?.handleSubmit();
                }
              }}
              labelProps={{ className: 'align-center-v', style: { gap: '6px' } }}
            />
          </div>
        </>
      );
    };

    const handleClose = () => {
      setShowModal(false);
      resetValues();
      onCancel();
    };

    return (
      <>
        {children({ open: showModal, setOpen: setShowModal })}
        <DrawerModal
          ref={null}
          width={500}
          height="100vh"
          visible={showModal}
          title={t('Post a Listing')}
          onCancel={handleClose}
          destroyOnClose
          footer={null}
        >
          <Group gap="16px">
            <div className="text-center">
              <SvgLicense size={116} />
            </div>

            <Typography.Paragraph align="center" className="mb-0 fs16 color-gray-dark">
              {t('Please enter REGA advertising license details to continue')}
            </Typography.Paragraph>

            <div className="mb-8" style={{ display: 'flex', justifyContent: 'center' }}>
              <Segmented value={selectedTab} options={optionTypes} onChange={handleTabChange} />
            </div>

            <form className="grid">
              {selectedTab === 'falLicense' ? renderFalLicenseFields() : renderLicenseIdFields()}
            </form>

            <Button type="primary" size="large" onClick={() => formik?.handleSubmit()} block loading={loading}>
              {t('Continue')}
            </Button>
          </Group>
        </DrawerModal>
      </>
    );
  },
);
export default PostAdModal;
