import { NetworkService } from '../../../../services/networkService';
import { getClassifiedBaseURL } from '../../../../utility/env';

export const fetchFavoriteProperties = async () => {
  const response = await NetworkService.get(`${getClassifiedBaseURL()}/api/user/favorites`);
  return response;
};

export const fetchSavedSearches = async (locale) => {
  const response = await NetworkService.get(`${getClassifiedBaseURL()}/api/user/searches/saved/`, null, {
    'Accept-Language': locale || '',
  });

  return response;
};

export const deleteSavedSearch = async (locale, id, userID) => {
  const response = await NetworkService.delete(
    `${getClassifiedBaseURL()}/api/user/${userID}/searches/saved/${id}`,
    null,
    {
      'Accept-Language': locale || '',
    },
  );

  return response;
};

export const saveUserSettings = async (payload) => {
  const response = await NetworkService.post(`${getClassifiedBaseURL()}/api/saveSettings`, payload);
  return response;
};

export const logout = async (payload) => {
  const response = await NetworkService.post(`${getClassifiedBaseURL()}/api/logout`, payload);
  return response;
};
