import tenantData from '@data';
import tenantUtils from '@utils';
import { NetworkService, getAppSource } from '../../../../services/networkService';
import { convertQueryObjToString } from '../../../../utility/urlQuery';
import { getErrorAllResponse } from '../../../../utility/utility';
import { postedToBayut } from './listingsApis';

const fieldType = { number: 'input', text: 'input', checkbox: 'checkbox', select: 'select' };

const sortIndex = { number: 3, text: 2, checkbox: 4, select: 1 };

const getAmenitiesData = (payload) => {
  const amenitiesData = {};
  payload &&
    payload.forEach((amenityItem, index) => {
      if (amenityItem.groups.length) {
        Object.defineProperty(amenitiesData, amenityItem.type_id, { value: { fields: {}, fieldsSections: [] } });
      }
      amenityItem.groups.forEach((e, i) => {
        amenitiesData?.[amenityItem.type_id].fieldsSections.push({
          title: tenantUtils.getLocalisedString(e, 'title'),
          fields: [],
        });
        // to sort
        const features = e.features.sort((first, second) => {
          return sortIndex[first.format] < sortIndex[second.format]
            ? -1
            : sortIndex[first.format] == sortIndex[second.format]
              ? 0
              : 1;
        });
        features.forEach((item) => {
          amenitiesData[amenityItem.type_id].fields[item.id] = {
            type: fieldType[item.format],
            initialValue: '',
            value: '',
            props: {
              options:
                fieldType[item.format] === 'select'
                  ? tenantUtils
                      .getLocalisedString(item, 'options')
                      .split(',')
                      .map((e) => ({ id: e, name: e }))
                  : undefined,
              label: tenantUtils.getLocalisedString(item, 'title'),
              horizontal: true,
              id: item.id,
            },
          };
          amenitiesData[amenityItem.type_id].fieldsSections[i].fields.push(item.id);
        });
      });
    });
  return amenitiesData;
};

export const fetchListingDetailToUpdate = async (userId, listingId) => {
  const response = await NetworkService.get(`/api/listings/${listingId}/edit`, {});
  const listing = response?.data?.listing;
  const residenceTypes = response?.data?.residence_types;

  if (response) {
    if (response.error) {
      return response;
    } else {
      return {
        data: {
          listing: listing
            ? {
                ...listing,
                location: { ...listing?.location, breadcrumb: listing?.location?.breadcrumb?.reverse() },
                platform: {
                  ksa: postedToBayut(listing?.status?.name),
                },
                residenceTypes,
                listingTypes: response?.data?.listing_types,
              }
            : listing,
        },
      };
    }
  }
};

export const fetchCrossCityDetail = async (userId, cityId) => {
  const response = await NetworkService.get(`/api/listings/find_cross_city?subject_id=${userId}&city_id=${cityId}`, {});
  if (response) {
    if (response?.error) {
      return response;
    } else {
      const obj = response?.data?.CrossCity;
      return Object.keys(response?.data?.CrossCity)?.length > 0
        ? {
            data: {
              assigned_cities: obj?.assigned_cities,
              olx: {
                mapped: obj?.olx?.mapped,
                restricted: !obj?.olx?.mapped
                  ? false
                  : !obj?.olx?.bypass && obj?.olx?.cross_city_allowed - obj?.olx?.cross_city_consumed < 1,
              },
              zameen: {
                mapped: obj?.zameen?.mapped,
                restricted: !obj?.zameen?.mapped
                  ? false
                  : !obj?.zameen?.bypass && obj?.zameen?.cross_city_allowed - obj?.zameen?.cross_city_consumed < 1,
              },
            },
          }
        : undefined;
    }
  }
};

export const getS3PresignedUrl = async (attachmentType = 'listing_image', associationKey, fileName) => {
  const response = await NetworkService.get(
    `${
      attachmentType == 'listing_image' ? '/api/attachments/pre_signed_url' : '/api/assets/pre_signed_url'
    }?category=${attachmentType}&no_of_urls=${1}`,
    {},
  );
  return response;
};

export const getImageBank = async (userId, perPage = 30, page = 1) => {
  const response = await NetworkService.get(`/api/users/${userId}/image_bank?page=${page}&per_page=${perPage}`, {});
  if (response) {
    if (response.error) {
      return response;
    } else {
      return {
        data: {
          images: response.data.images,
          pagination: tenantUtils.getPaginationObject(response.data?.pagination),
        },
      };
    }
  }
  return response;
};

export const postNewListing = async (queryObj) => {
  const source = getAppSource();
  const response = await NetworkService.get(`/api/listings/new?${convertQueryObjToString(queryObj)}`, '', { source });
  return response;
};

const findFeatureById = (arr, featureId) => {
  for (const group of arr) {
    const feature = group.features.find((feature) => feature.feature_id == featureId);
    if (feature) {
      return feature?.id;
    }
  }
  return null;
};

const getListingFeatures = (featuresAmenities, listingFeatures) =>
  featuresAmenities && Object.keys(featuresAmenities)?.length
    ? Object.keys(featuresAmenities)
        .map((e) =>
          !!findFeatureById(listingFeatures, e)
            ? {
                id: findFeatureById(listingFeatures, e),
                feature_id: e,
                ...(featuresAmenities?.[e] == undefined || featuresAmenities?.[e] == false
                  ? { _destroy: true }
                  : { feature_value: featuresAmenities?.[e] === true ? 'Yes' : featuresAmenities?.[e] }),
              }
            : featuresAmenities?.[e]
              ? { feature_id: e, feature_value: featuresAmenities?.[e] === true ? 'Yes' : featuresAmenities?.[e] }
              : null,
        )
        .filter((e) => !!e)
    : [];

const createListingPayload = (values, listing, source, autoRenew, productId) => {
  const isSellOrRentProperty = values?.listing_purpose?.slug == 'sale' || values?.listing_purpose?.slug == 'rent';
  const isDailyRentalListing = values?.listing_purpose?.slug == 'daily-rental';
  const images = values.property_images?.length
    ? values.property_images
        .filter((e) => e.uuid)
        .map((image) => {
          const { id, uuid, isMainImage, _destroy } = image;
          return {
            name: uuid,
            is_main_image: !!isMainImage,
            // TODO: need to fix this
            ...(id && typeof id === 'number' && { id }),
            _destroy,
          };
        })
    : [];

  const mainImageIndex = images.findIndex((e) => !!e.is_main_image);
  if (mainImageIndex != -1) {
    const mainImage = images.splice(mainImageIndex, 1);
    images.unshift(mainImage?.[0]);
  } else {
    const index = images.findIndex((e) => !e?._destroy);
    images[index] = { ...images[index], is_main_image: true };
  }
  return {
    listing: {
      source,
      purpose_id: values?.purpose,
      ...(isSellOrRentProperty
        ? { type_id: values?.sub_listing_type?.id || values?.listing_type?.id }
        : { type_id: values?.property_type }),
      latitude: values?.['location-info']?.map?.latitude || values?.latitude || values?.location?.latitude,
      longitude: values?.['location-info']?.map?.longitude || values?.longitude || values?.location?.longitude,
      ...(isSellOrRentProperty && {
        is_furnished: values?.furnished == '1',
      }),
      ...(isSellOrRentProperty && {
        area_unit_id: values?.area_unit?.id,
      }),
      area_unit_value: isSellOrRentProperty ? values?.area_unit?.value : values?.area,
      ...(isDailyRentalListing && { national_address: values?.national_address }),
      ...(values?.residence_type_applicable && { residence_type: values?.residence_type }),
      listing_features_attributes: getListingFeatures(values?.feature_and_amenities, listing?.listing_features),
      beds: values?.bedrooms,
      bath: values?.bathrooms,
      price: values?.listing_purpose?.slug == 'sale' || isDailyRentalListing ? values?.price : values?.rental_price,
      ...(values?.listing_purpose?.slug == 'rent' && { rental_price: values?.rental_price }),
      listing_images_attributes: images?.length ? images.map((image, index) => ({ ...image, order: index })) : [],
      title: values?.property_title_en,
      title_l1: values?.property_title_ar,
      description: values?.property_description_en,
      description_l1: values?.property_description_ar,
      platform_id: 1,
      ...(isSellOrRentProperty && {
        rent_frequency_id: values?.rental_frequency,
      }),
      is_autorenewal_on: autoRenew,
      location_id: values?.['location-info']?.location?.location_id
        ? values?.['location-info']?.location?.location_id
        : values?.['location-info']?.city?.id,
    },
    product_id: productId,
  };
};

export const updateListing = async (values, listing, autoRenew, productId) => {
  const source = getAppSource();
  const listingId = values?.id;
  const payload = createListingPayload(values, listing, source, autoRenew, productId);
  let response = await NetworkService.put(`/api/listings/${listingId}`, payload, { source });
  if (response) {
    return response;
  }
};

export const priceCheckApi = async (values) => {
  const areaUnitSlug = tenantData.areaUnitList.find((e) => e.id === values.area.unit)?.slug;
  const payload = {
    location_id: values.location_select?.location.location_id,
    type_id: values.property_type,
    purpose_id: values.purpose,
    area: (values.area.value * tenantData.areaMapings[areaUnitSlug]['square_feet']).toFixed(0),
    price: values?.monthly_rent.value ? values?.monthly_rent?.value : values.price.value,
  };
  const response = await NetworkService.get(`/api/listings/price_check`, payload);
  return response;
};

export const getQuotaDeductionApi = async (data) => {
  const response = await NetworkService.get(`/api/quotas/consumption`, data);
  if (response) {
    if (response?.error || Object.keys(response?.data?.quota)?.length === 0) {
      return { error: response?.error || 'Network Error' };
    } else {
      const obj = response?.data?.quota;
      return {
        ...(obj?.zameen && Object?.keys(obj?.zameen)?.length > 0
          ? {
              zameen: {
                available: Number(obj.zameen.available_quota?.toFixed(1)),
                required: Number(obj.zameen.factor?.toFixed(1)),
                isZoneArea: !!(obj.zameen?.factor > 1),
                zoneFactor: Number((obj.zameen.factor - 1).toFixed(1)),
                totalQuota: Number(obj.zameen.total_quota?.toFixed(1)),
                message: obj?.zameen?.message,
              },
            }
          : {
              zameen: {
                available: 0,
                required: 1,
                isZoneArea: false,
                zoneFactor: 0,
                totalQuota: 0,
                message: obj?.zameen?.message,
              },
            }),
        ...(obj?.olx &&
          Object?.keys(obj?.olx)?.length > 0 && {
            olx: {
              available: Number(obj.olx.available_quota?.toFixed(1)),
              required: Number(obj.olx.factor?.toFixed(1)),
              isZoneArea: !!(obj.olx?.factor > 1),
              zoneFactor: Number((obj.olx.factor - 1).toFixed(1)),
              totalQuota: Number(obj.olx.total_quota?.toFixed(1)),
              message: obj?.olx?.message,
            },
          }),
      };
    }
  }
};

export const getPhotographyVideoGraphyCredits = async (action, user) => {
  const promiseArray = [
    getCreditDeductionApi(action?.listing?.slug, {
      listing_id: action?.listing?.property_id,
      product_id: action?.product?.id,
      subject_id: action?.listing?.listingOwner ? action?.listing?.listingOwner?.id : user?.id,
    }),
    getCreditDeductionApi(action?.listing?.slug, {
      listing_id: action?.listing?.property_id,
      product_id: 13,
      subject_id: action?.listing?.listingOwner ? action?.listing?.listingOwner?.id : user?.id,
    }),
  ];

  const response = await Promise.all(promiseArray);
  const error = getErrorAllResponse(response);
  if (error) {
    return { error: response?.[0]?.error || response?.[1]?.error };
  }
  return {
    ['property_videography']: response?.[0]?.zameen,
    ['property_photography']: response?.[1]?.zameen,
  };
};

export const getCreditDeductionApi = async (listingId, productId, platform) => {
  const response = await NetworkService.get(`/api/products/applicable_products?listing_id=${listingId}`);
  if (response) {
    const products = response?.data?.products;
    if (products?.length) {
      const applicable_produduct = products?.find((e) => e?.product_id == productId);
      return {
        [platform]: {
          available: 100,
          required: applicable_produduct?.credits_required?.['30'],
          isZoneArea: false,
          zoneFactor: 1,
          totalQuota: 100,
        },
      };
    } else {
      return response;
    }
  }
};

export const listingHasUpgrades = (listing, user) => {
  return (
    (!!user?.platforms?.[0] && !listing?.platforms?.zameen) ||
    (!!user?.platforms?.[1] && !listing?.platforms?.olx) ||
    !!(listing?.platforms?.zameen && listing?.platforms?.zameen?.haveUpgrades) ||
    !!(listing?.platforms?.olx && listing?.platforms?.haveUpgrades)
  );
};

const getProductDetails = (allProducts) => {
  return allProducts?.length
    ? allProducts?.map((e) => {
        const productDetails = tenantData.getListingActions(e?.slug);
        if (productDetails) {
          return { ...productDetails, ...e };
        } else {
          return e;
        }
      })
    : [];
};

const getProductDetailToBeApplied = (allProducts, productSlug) => {
  const details = allProducts?.find((it) => it?.slug == productSlug);

  if (details) {
    return { ...tenantData.getListingActions(productSlug), ...details };
  } else {
    return details;
  }
};

export const fetchApplicableProductsById = async (listingId, productSlug) => {
  const response = await NetworkService.get(`/api/products/applicable_products?listing_id=${listingId}`);
  if (response) {
    if (response?.error) {
      return response;
    } else {
      return {
        ...response?.data,
        applicableProduct: getProductDetailToBeApplied(response?.data?.products, productSlug),
        products: getProductDetails(response?.data?.products),
      };
    }
  }
};

export const fetchAutoGeneratedContent = async (listingId, payload) => {
  const response = await NetworkService.get(`/api/listings/${listingId}/generate_content`, payload);
  if (response) {
    if (response?.error) {
      return response;
    } else {
      return response?.data;
    }
  }
};

export const postPackageOnListing = async (listingId, productIds) => {
  let response;

  response = await NetworkService.patch(`/api/listings/${listingId}/apply_products`, { product_ids: productIds });

  if (response) {
  }
};

export const deleteImageFromBank = async (images) => {
  const response = await NetworkService.delete(`/api/images`, {
    media_category_id: '7',
    images,
  });
  return response;
};

export const deleteImageFromS3 = async (imageItem) => {
  const response = await NetworkService.delete(`/api/images`, {
    media_category_id: '7',
    images: [imageItem?.id],
  });
  if (response) {
    return response;
  }
};

export const getAmenities = async (purposeId) => {
  const response = await NetworkService.get(`/api/amenities${purposeId ? `?purpose_id=${purposeId}` : ''}`);
  if (response) {
    if (response.error) {
      return response;
    } else {
      return {
        data: getAmenitiesData(response.data?.amenities),
      };
    }
  }
  return response;
};

export const getProjects = async (agencyId) => {
  const response = await NetworkService.get(`/api/projects?q[agency_id_eq]=${agencyId}`, {});
  if (response) {
    if (response.error) {
      return response;
    } else {
      return {
        data: response.data.projects,
      };
    }
  }
  return response;
};
