import tenantConstants from '@constants';
import tenantRoutes from '@routes';
import tenantUtils from '@utils';
import { Menu } from 'antd';
import cx from 'clsx';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Button, Dropdown, Flex, Group, Heading, Icon, Select } from '../../../../components/common';
import { useAppAuthentication } from '../../../../hooks';
import DownloadBayutModal from '../downloadAppModal/downloadAppModal';
import NavBarNavigationContainer from './navBarNavigationContainer';
import { DropdownItemUpsell, HeaderStyled, ModalPopupStyle } from './styled';
import { areaRangeUnitList, currencyArabicConversionUnitList, getCookieUnits, getLink } from './utils';
import { useDispatch } from 'react-redux';
import tenantApi from '@api';
import LiteHeaderDropdown from '../../../../components/common/lite-header-dropdown/lite-header-dropdown';
import tenantTheme from '@theme';

export const DeskTopNavBar = ({
  userData,
  logoutUser,
  locale,
  savedSearches,
  setSavedSearches,
  favouriteProperties,
  showChangeCurrencyModal,
  setShowChangeCurrencyModal,
  showChangeAreaUnitModal,
  setShowChangeAreaUnitModal,
  saveSettings,
  pathLocale,
  toggleLanguage,
  languageObj,
  isFavpropLoading,
  setNoFavPropModal,
  onDeleteSavedSearch,
  setNoSaveSearchModal,
}) => {
  const { t } = useTranslation();
  const units = getCookieUnits(t);
  const dispatch = useDispatch();
  const [toSetCurrency, setToSetCurrency] = useState(units.currency);
  const [toSetArea, setToSetArea] = useState(units.area);
  const { auth } = useAppAuthentication();
  const [modalVisible, setModalVisible] = useState(false);
  const { user } = useSelector((state) => state.loginUser);

  const options = useMemo(
    () => [
      {
        value: getLink(t('Become a Business User'), tenantRoutes.app('', false, user).prop_shop.path),
        key: t('Become a Business User'),
      },
      {
        value: getLink(t('Account Settings'), `${tenantRoutes.app('', false, user).settings.route}/user-profile`),
        href: `${tenantRoutes.app('', false, user).settings.route}/user-profile`,
        key: t('Account Settings'),
      },
      {
        value: t('Logout'),
        key: t('Logout'),
        onClick: () => logoutUser(),
      },
    ],
    [],
  );

  const settingsOptions = useMemo(
    () => [
      { value: t('Change Currency'), onClick: () => setShowChangeCurrencyModal(true) },
      { value: t('Change Area Unit'), onClick: () => setShowChangeAreaUnitModal(true) },
    ],
    [],
  );

  const onCancelSettingPopup = () => {
    if (showChangeCurrencyModal) {
      setShowChangeCurrencyModal(false);
    }
    if (showChangeAreaUnitModal) {
      setShowChangeAreaUnitModal(false);
    }
  };

  const onSaveUnit = () => {
    setShowChangeCurrencyModal(false);
    setShowChangeAreaUnitModal(false);
    const settings = showChangeCurrencyModal ? { currency: toSetCurrency } : { area: toSetArea };
    saveSettings(settings);
  };

  const renderAreaUnitSelector = () => {
    return (
      <div onClick={(event) => event.stopPropagation()}>
        <Select
          suffixIcon="IoMdArrowDropdown"
          openIcon="IoMdArrowDropdown"
          className="unitSelectDropdown"
          value={toSetArea}
          unit={toSetArea}
          onChange={(value) => setToSetArea(value)}
          style={{ alignItems: 'start' }}
          containerClass="unitSelect"
          options={areaRangeUnitList}
          getOptionValue={(e) => e.slug}
          getOptionLabel={(e) => t(e.alt_label)}
        />
      </div>
    );
  };

  const renderChangeCurrencySelector = () => {
    return (
      <div onClick={(event) => event.stopPropagation()}>
        <Select
          suffixIcon="IoMdArrowDropdown"
          openIcon="IoMdArrowDropdown"
          className="unitSelectDropdown"
          unit={toSetCurrency}
          onChange={(value) => setToSetCurrency(value)}
          type="price"
          style={{ alignItems: 'start' }}
          options={currencyArabicConversionUnitList}
          value={toSetCurrency}
          getOptionValue={(e) => e.slug}
          getOptionLabel={(e) => t(e.alt_label)}
        />
      </div>
    );
  };

  const renderSettingsPopup = () => {
    return (
      <ModalPopupStyle
        closeIcon={<Icon icon="CgClose" color="#222" size={20} />}
        className="modalHeaderContainer"
        width={400}
        visible={showChangeCurrencyModal || showChangeAreaUnitModal}
        style={{ overlay: { zIndex: 1000 } }}
        onCancel={onCancelSettingPopup}
        footer={null}
        title={
          <Heading as="h2" className="text-center mb-0">
            {showChangeCurrencyModal ? t('Change Currency') : showChangeAreaUnitModal && t('Change Area')}
          </Heading>
        }
      >
        {showChangeCurrencyModal ? (
          renderChangeCurrencySelector()
        ) : showChangeAreaUnitModal ? (
          renderAreaUnitSelector()
        ) : (
          <></>
        )}
        <Button className="saveBtn" type="success" block onClick={onSaveUnit}>
          {t('SAVE')}
        </Button>
      </ModalPopupStyle>
    );
  };

  const renderFavPropertyDropdownUI = () => {
    return (
      <Button
        type="link"
        {...(favouriteProperties.length > 0
          ? { href: `${pathLocale}/favorite-properties.html` }
          : { onClick: () => setNoFavPropModal(true) })}
        className={cx('px-0 favouritePropertiesTag')}
        icon="IconFavourite"
        iconSize="1.28571em"
      >
        <div>
          {t('Favourite properties')}
          {!isFavpropLoading && (
            <span className="text-primary fw-600" style={{ marginInlineStart: '4px' }}>
              ({favouriteProperties.length || 0})
            </span>
          )}
        </div>
      </Button>
    );
  };

  const getSavedSearchesDropDownUI = () => {
    return (
      <LiteHeaderDropdown
        title={
          <div>
            {t('Saved searches')}
            {!savedSearches?.loading && (
              <span
                className="text-primary fw-600"
                style={{ marginInlineStart: '4px', color: tenantTheme['primary-color'] }}
              >
                ({savedSearches?.length || 0})
              </span>
            )}
          </div>
        }
        placement={locale === 'ar' ? 'bottomLeft' : 'bottomRight'}
        prefixIcon="TiStar"
        options={savedSearches}
        optionsIcon={'IconTrashCan'}
        color="#4c4a4a"
        width={13}
        className="pointer"
        onOptionsIconClick={onDeleteSavedSearch}
      />
    );
  };

  const renderLoginDropDownUI = () => {
    return auth?.authenticated ? (
      <Dropdown
        options={options}
        placement={locale === 'ar' ? 'bottomLeft' : 'bottomRight'}
        prefixIcon="IconLogin"
        prefixIconProps={{ size: '1.28571em', color: 'currentColor' }}
        iconSize="1.28571em"
        className={cx('dropDownSelect', 'languageDropdown', 'userDropdown')}
        getOptionLabel={(e) => e?.value}
        getOptionValue={(e) => e?.value}
        suffixIcon="IoMdArrowDropdown"
        suffixIconProps={{ color: 'currentColor', size: '1.4em', style: { marginInline: -5, marginBlockStart: 1 } }}
        style={{ gap: 8 }}
        content={
          <>
            <DropdownItemUpsell>
              <Link to={tenantRoutes.app('', false, user).prop_shop.path}>
                <div className="fw-700">{t('Are you a Broker/Agency?')}</div>
                <Flex className="text-primary fz-12">{t('Get a Business Package')}</Flex>
              </Link>
            </DropdownItemUpsell>
            {options
              .filter((e) => e.key !== t('Become a Business User'))
              .map((e) =>
                e.onClick ? (
                  <Menu.Item style={{ ...e.style }} onClick={() => e?.onClick()}>
                    <Group template="max-content auto" gap="8px" style={{ alignItems: 'center' }}>
                      {e?.icon && <Icon icon={e.icon} size="1.2em" />}
                      {e.key}
                    </Group>
                  </Menu.Item>
                ) : (
                  <Menu.Item className="p-0">
                    <Link to={e?.href}>
                      <Group template="max-content auto" gap="0px" style={{ alignItems: 'center' }}>
                        {e?.icon && <Icon icon={e.icon} size="1.2em" />}
                        {e?.key}
                      </Group>
                    </Link>
                  </Menu.Item>
                ),
              )}
          </>
        }
      >
        {tenantUtils.getLocalisedString(userData, 'name')}
      </Dropdown>
    ) : (
      <Button
        style={{ fontWeight: '700', fontSize: '14px' }}
        className="u-p0"
        size="sm"
        variant="link"
        href={tenantConstants.GET_LOGIN_PATH(window.location.pathname)}
        icon="IconLogin"
      >
        {t('Log In')}
      </Button>
    );
  };

  const getSiteSettingsDropDownUI = () => {
    return (
      <Dropdown
        options={settingsOptions}
        placement={locale === 'ar' ? 'bottomRight' : 'bottomLeft'}
        prefixIcon="IconSettings"
        prefixIconProps={{ size: '1.28571em', color: 'currentColor' }}
        rootClassName="p-0"
        className={cx('dropDownSelect', 'languageDropdown', 'fz-12')}
        getOptionValue={(item) => item?.value}
        getOptionLabel={(item) => item?.value}
        suffixIcon={null}
        style={{ gap: 7 }}
      >
        {t('Site settings')}
      </Dropdown>
    );
  };

  const renderDesktopNavUI = () => {
    return (
      <>
        <div className="navbar-ceiling">
          <Flex className="container" justify="space-between">
            <Flex gap="23px">
              {languageObj && (
                <a href={toggleLanguage(languageObj.key)}>
                  <Button
                    style={{ fontFamily: locale === 'ar' && 'Helvetica' }}
                    className="px-0 languageSwitcher"
                    type="link"
                    icon="IconLanguageSwitcher"
                    iconSize="1.428571em"
                  >
                    {languageObj.labelShort}
                  </Button>
                </a>
              )}
              {getSiteSettingsDropDownUI()}
              <Button
                className="px-0"
                type="link"
                icon="MdPhoneIphone"
                iconSize="1.3em"
                onClick={() => {
                  setModalVisible(true);
                }}
                style={{ gap: '3px' }}
              >
                {t('Download App')}
              </Button>
              <DownloadBayutModal visible={modalVisible} onClose={() => setModalVisible(false)} />
            </Flex>
            <Flex gap="22px">
              {renderFavPropertyDropdownUI()}
              {/* to be implemented yet */}
              {savedSearches?.length > 0 ? (
                getSavedSearchesDropDownUI()
              ) : (
                <>
                  <Button
                    className="px-0"
                    onClick={setNoSaveSearchModal}
                    icon="TiStar"
                    iconSize="1.428571em"
                    type={'link'}
                  >
                    <div>
                      {t('Saved searches')}
                      {!savedSearches?.loading && (
                        <span className="text-primary fw-600" style={{ marginInlineStart: '4px' }}>
                          ({savedSearches?.length || 0})
                        </span>
                      )}
                    </div>
                  </Button>
                </>
              )}
              <Link to="/listings">
                <Button className="px-0" type="link" icon="IconListings" iconSize="1.428571em">
                  {t('My Listings')}
                </Button>
              </Link>
              {renderLoginDropDownUI()}
            </Flex>
          </Flex>
        </div>
        <NavBarNavigationContainer locale={locale} />
      </>
    );
  };

  return (
    <HeaderStyled>
      {renderSettingsPopup()}
      {renderDesktopNavUI()}
    </HeaderStyled>
  );
};
