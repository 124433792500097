import { NetworkService } from '../../../../services/networkService';
import tenantApi from '@api';

const updateAgencySettings = async (agencyId, values) => {
  let response = await NetworkService.put(
    `/api/agencies/${agencyId}`,
    tenantApi.agencySettings(values),
  );
  if (response.error) {
    return {
      error: response.error,
    };
  } else if (response.data)
    return {
      user: {
        ...response.data?.agency,
        agentid: response.data?.id,
        agent_type: response.data?.agency_type,
        agent_status: response.data?.agency_status,
        company_name: response.data?.name,
        agent_country: response.data?.country_id,
        company_phone: response.data?.phone,
        company_cell: response.data?.mobile,
        company_email: response.data?.email,
        company_website: response.data?.website,
        agent_logo: response.data?.agency_logo,
        time_added: response.data?.created_at,
        owner_title: response.data?.designation,
        company_whatsapp: response.data?.whatsapp,
      },
    };
};
export default updateAgencySettings;
