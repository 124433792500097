import tenantApi from '@api';
import tenantData from '@data';
import tenantRoutes from '@routes';
import tenantTheme from '@theme';
import { Col, Divider, Popover, Row, Space, Typography } from 'antd';
import { useFormik } from 'formik';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { postListingClickEvent } from '../../../../services/analyticsService';
import {
  Button,
  Card,
  ConfirmationModal,
  Flex,
  Group,
  Heading,
  Icon,
  Skeleton,
  Switch,
  notification,
} from '../../../../components/common';
import OtpVerificationModal from '../../../../components/otp-verification-modal/otp-verification-modal';
import { PERMISSIONS_TYPE } from '../../../../constants/permissions';
import { usePageTitle, useRouteNavigate, useScrollToFieldForm } from '../../../../hooks';
import { fetchUserDetail } from '../../../../redux/user/actionCreator';
import { setUsersList } from '../../../../redux/users/actionCreator';
import { QuotaCreditModal } from '../listing/listing-platform-actions/quotaCreditModal';
import RegaCard from './RegaCard';
import RenderPostListingFields from './RenderPostListingFIelds';
import { getInitialValues, getRentalInitialValues } from './getInitialValues';
import { getValidationSchema } from './getValidationSchema';
import { PostListingAction } from './styled';
import SuccessfulPaymentModal from '../../../../components/checkout/successfulPaymentModal';
import { PostListingForm } from '../../../../components/post-listing';
import { skipFieldsForField } from './listing-form-data';

const PostListing = (props) => {
  const { Text } = Typography;

  const { t } = useTranslation();
  usePageTitle(t('Post Listing - Profolio'));

  const { formData = {}, loading, isMobile } = props;

  const [autoRenew, setAutoRenew] = useState(true);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);
  const [fetchContentLoading, setFetchContentLoading] = useState(false);
  const [applicableProduct, setApplicableProduct] = useState(null);
  const [creditsInfo, setCreditsInfo] = useState(null);
  const [successModalData, setSuccessModalData] = useState(null);
  const [locationLoading, setLocationLoading] = useState(false);

  const { user } = useSelector((state) => state.loginUser);
  const users = useSelector((state) => state.userGroup.list);
  const { locale } = useSelector((state) => state?.AppConfig);
  const isDailyRentalListing = formData?.listing?.listing_purpose?.slug == 'daily-rental';
  const deductionRef = useRef();
  const formikRef = useRef();
  const autoRenewModalRef = useRef();
  const otpVerifyRef = useRef();
  const dailyRentalBathroomsList = [{ key: '0', label: '0' }]?.concat(tenantData.bathroomsList);

  const navigate = useRouteNavigate();
  const dispatch = useDispatch();

  const onSubmit = async () => {
    if (formik?.values?.property_images?.some((e) => !!e?.uploading && !e?.inError)) {
      notification.error(t('Image uploading is still in progress'));
    } else {
      if (!formData?.listing?.is_posted && !user?.is_mobile_verified) {
        setSubmitLoading(true);
        otpVerifyRef.current.onVerify(formik?.values['mobile']);
      } else {
        handlePostOrUpdateListing();
      }
    }
  };

  const formik = useFormik({
    initialValues: isDailyRentalListing
      ? getRentalInitialValues(formData?.listing, user)
      : getInitialValues(formData?.listing, user),
    validationSchema: getValidationSchema(
      locale,
      formData?.listing?.listing_purpose?.slug,
      formData?.listing?.listing_type?.id,
      user?.is_mobile_verified,
    ),
    validateOnChange: false,
    onSubmit: onSubmit,
    innerRef: formikRef,
  });

  const resetValuesOnPropertyTypeChange = () => {
    formik.setFieldValue('price', '', false);
    formik.setFieldValue('area', '', false);
    formik.setFieldValue('feature_and_amenities', null, false);
    formik.setFieldValue(locale == 'en' ? 'property_title_en' : 'property_title_ar', '', false);
    formik.setFieldValue(locale == 'en' ? 'property_description_en' : 'property_description_ar', '', false);
  };

  const bedroomList = useMemo(() => {
    return skipFieldsForField?.['bedrooms'].property_type[
      formik.values['sub_listing_type']?.id || formik.values['property_type']
    ]?.studio
      ? tenantData.bedroomsList?.filter((item) => item.key !== '-1')
      : tenantData.bedroomsList;
  }, [
    skipFieldsForField,
    formik.values['property_type'],
    tenantData.bedroomsList,
    formik.values['sub_listing_type']?.id,
  ]);

  useEffect(() => {
    if (formData?.listing?.id && !formData?.listing?.isPosted && formik?.values['location-info']?.city?.id) {
      getApplicableProducts();
    }
  }, [formik?.values['location-info']?.city?.id]);

  const getLocations = async (nationalAddress) => {
    setLocationLoading(true);
    const locationsResponse = await tenantApi.getLocationsBasedOnNational(nationalAddress);
    if (locationsResponse) {
      setLocationLoading(false);
      if (locationsResponse?.error) {
        notification.error(locationsResponse?.error);
      } else if (locationsResponse?.data?.response?.geometry) {
        formik?.setFieldValue(
          'location-info',
          {
            ...formik.values['location-info'],
            map: {
              longitude: locationsResponse?.data?.response?.geometry?.location?.lng,
              latitude: locationsResponse?.data?.response?.geometry?.location?.lat,
              type: 'map',
            },
          },
          true,
        );
      }
    }
  };

  const getApplicableProducts = async () => {
    const response = await tenantApi.getApplicableProducts(
      formData?.listing?.id,
      user?.platforms,
      false,
      false,
      isDailyRentalListing && formik?.values['location-info']?.city?.id,
    );
    if (response) {
      if (response.error) {
        notification.error(response.error);
      } else {
        const basicListing = response.products?.find((p) => p.slug === 'basic-listing');
        setCreditsInfo({
          ...creditsInfo,
          productId: basicListing?.product_id,
          requiredCredits: basicListing?.credits_required?.[30],
          isSufficient: !!(user?.credits?.ksa?.available >= basicListing?.credits_required?.[30]),
        });
      }
    }
  };

  const fetchRequiredQuota = async () => {
    const response = await tenantApi.fetchApplicableProductsById(
      formData?.listing?.id,
      tenantData.getListingActions('basic-listing')?.slug,
    ); //product for Quota
    if (response) {
      if (response?.error) {
        notification.error(response?.error);
      } else {
        setApplicableProduct(response);
        deductionRef?.current && deductionRef?.current?.showQuotaCreditModal();
      }
    }
  };

  const onUpdateListing = async () => {
    setModalLoading(true);
    const res = await tenantApi.updateListing(
      formik?.values,
      formData?.listing,
      autoRenew,
      creditsInfo?.isSufficient ? creditsInfo?.productId : null,
    );
    if (res) {
      setSubmitLoading(false);
      setModalLoading(false);
      deductionRef?.current && deductionRef?.current?.hideQuotaCreditModal();
      if (res?.error) {
        notification.error(res?.error);
      } else {
        postListingClickEvent(user, 200, formik?.values,autoRenew);
        if (!formData?.listing?.is_posted && !!user?.isCurrencyUser) {
          dispatch(fetchUserDetail());
          if (res?.data?.listing?.disposition?.slug == 'pending-nafaz-verification') {
            setSuccessModalData({ listing_id: formData?.listing?.id });
          } else {
            navigate(`${tenantRoutes.app().post_listing.path}/${formData?.listing?.id}/upgrade`, {
              state: { listing: formData?.listing },
            });
          }
        } else {
          notification?.success(
            `${t('Listing')} ${formData?.listing?.is_posted ? t('Updated') : t('Posted')} ${t('Successfully!')}`,
          );
          navigate(`${tenantRoutes.app().listings.path}`);
        }
      }
    }
  };

  const handlePostOrUpdateListing = () => {
    setSubmitLoading(true);
    if (!formData?.listing?.is_posted) {
      if (!user?.isCurrencyUser) {
        fetchRequiredQuota();
      } else {
        onUpdateListing();
      }
    } else {
      onUpdateListing();
    }
  };

  useEffect(() => {
    //ToDo as discussed
    user?.permissions?.[PERMISSIONS_TYPE.LISTINGS] && !users?.length && setUsersList();
  }, [user, formik?.values?.posting_as]);

  useEffect(() => {
    formik &&
      formData.listing &&
      formik.setValues(
        isDailyRentalListing
          ? getRentalInitialValues(formData.listing, user)
          : getInitialValues(formData.listing, user),
      );
  }, [formData.listing]);

  useScrollToFieldForm(formik);

  const renderAutoRenew = () => {
    return (
      <Card
        className="w-100"
        style={{ backgroundColor: '#F7F7F7', maxWidth: !isMobile && 'max-content' }}
        bodyStyle={{ padding: 8 }}
      >
        <Flex gap="10px" align="center" justify={isMobile && 'space-between'}>
          <Flex align="center" gap="4px">
            <Text type="secondary" className={isMobile && 'fz-12'}>
              {t('Auto-Renew')}
            </Text>
            <Popover content={<>{t('The listing will be automatically renewed after 30 days')}</>} action="hover">
              <>
                <Icon
                  style={{ marginTop: isMobile && '2px' }}
                  icon="AiOutlineInfoCircle"
                  size={12}
                  color={tenantTheme.gray700}
                />
              </>
            </Popover>
          </Flex>

          <Switch
            value={autoRenew}
            size="small"
            onChange={() => {
              autoRenew ? autoRenewModalRef?.current?.showModal() : setAutoRenew(true);
            }}
          />
        </Flex>

        <ConfirmationModal
          title={t('Disable auto renew')}
          ref={autoRenewModalRef}
          onCancel={() => autoRenewModalRef?.current?.hideModal()}
          onSuccess={() => {
            setAutoRenew(!autoRenew);
            autoRenewModalRef?.current?.hideModal();
          }}
        >
          {t('Are you sure you want to disable auto renew for this listing')}
        </ConfirmationModal>
      </Card>
    );
  };

  const setTitleDescription = () => {
    formik.setFieldValue('property_title_en', formik?.values?.title, false);
    formik.setFieldValue('property_title_ar', formik?.values?.title_l1, false);
    formik.setFieldValue('property_description_en', formik?.values?.description, false);
    formik.setFieldValue('property_description_ar', formik?.values?.description_l1, false);
  };

  return !!loading ? (
    <PostListingSkeleton />
  ) : (
    <>
      <QuotaCreditModal
        ref={deductionRef}
        action={applicableProduct}
        onSuccess={onUpdateListing}
        loading={modalLoading}
        onCancel={() => {
          deductionRef?.current && deductionRef?.current?.hideQuotaCreditModal();
          setSubmitLoading(false);
        }}
      />
      <Group gap={isMobile ? '0px' : '8px'}>
        {!isMobile && !formData?.listing?.is_posted && (
          <div>
            <Heading as="h2" className="fs20 mb-4 ">
              {isDailyRentalListing ? t('Post a Daily Rental Listing') : t('Post a Listing')}
            </Heading>
            <Typography.Paragraph className="color-gray-dark mb-0">
              {isDailyRentalListing
                ? t('Reach thousands of guests in a few simple step')
                : t('Reach thousand of buyers and tenants in a few steps')}
            </Typography.Paragraph>
          </div>
        )}
        {!isDailyRentalListing && <RegaCard listing={formData?.listing} />}
        <Card
          bodyStyle={{
            padding: isMobile ? '0 0 20px' : '40px 0',
          }}
        >
          <form className="form-post-listing">
            <Group gap={isMobile ? '12px' : '32px'}>
              {isDailyRentalListing ? (
                <PostListingForm
                  formik={formik}
                  isMobile={isMobile}
                  loading={loading}
                  users={users}
                  setLoading={setFetchContentLoading}
                  listingPurposeId={formData?.listing?.listing_purpose?.id}
                  isDailyRental={isDailyRentalListing}
                  areaMaxLength={4}
                  tooltipText={t(
                    'The National Address, developed by Saudi Post, is a uniform address format designed to standardize address information across Saudi Arabia.',
                  )}
                  listingType={t('Per Night')}
                  bedroomList={bedroomList}
                  bathRoomsList={dailyRentalBathroomsList}
                  isCreditsAvailable={user?.credits?.ksa?.available > 0}
                  clearUserSelections={resetValuesOnPropertyTypeChange}
                  getLocationsLatLong={getLocations}
                  nationalAddressLoading={locationLoading}
                  alreadyPosted={formData?.listing?.is_posted}
                />
              ) : (
                <RenderPostListingFields
                  formik={formik}
                  isMobile={isMobile}
                  loading={loading}
                  users={users}
                  setLoading={setFetchContentLoading}
                  alreadyPosted={formData?.listing?.is_posted}
                />
              )}
              {creditsInfo?.isSufficient && (
                <div style={{ paddingInline: '16px' }}>{isMobile && renderAutoRenew()}</div>
              )}
            </Group>
          </form>

          <Divider />

          <PostListingAction
            disabled={isDailyRentalListing && !creditsInfo}
            style={{ paddingInline: isMobile ? '16px' : '64px', alignItems: 'center' }}
          >
            {creditsInfo?.isSufficient && <>{!isMobile && renderAutoRenew()}</>}
            <Flex
              gap="60px"
              align="center"
              justify={isMobile ? 'space-between' : 'end'}
              className={'w-100'}
              style={{ marginInlineStart: 'auto' }}
            >
              {creditsInfo?.isSufficient && (
                <div>
                  <Text type="secondary" className="d-block">
                    {t('Required')}
                  </Text>
                  <Text className="fw-700">
                    {' '}
                    {creditsInfo?.requiredCredits} <span className="fw-400">{t('Credits')}</span>
                  </Text>
                </div>
              )}

              <Button
                style={{ width: '140px', marginInlineStart: 'auto' }}
                type="primary"
                size="large"
                onClick={formik.handleSubmit}
                loading={submitLoading}
                disabled={fetchContentLoading}
              >
                {formData?.listing?.is_posted
                  ? t('Update')
                  : user?.is_mobile_verified
                    ? creditsInfo?.isSufficient
                      ? t('Post Listing')
                      : t('Continue')
                    : t('Verify & Submit')}
              </Button>
            </Flex>
          </PostListingAction>
        </Card>
      </Group>
      <SuccessfulPaymentModal data={successModalData} setData={setSuccessModalData} />

      <OtpVerificationModal
        ref={otpVerifyRef}
        value={user?.mobile}
        onSuccess={handlePostOrUpdateListing}
        onCancelModal={() => setSubmitLoading(false)}
        onFail={() => setSubmitLoading(false)}
      />
    </>
  );
};

const PostListingSkeleton = () => {
  const isMobile = useSelector((state) => state.AppConfig.isMobile);

  const renderFields = () => {
    return [1, 2, 3, 4, 5].map((e) => {
      return (
        <Col xs={{ span: 24 }} key={e}>
          <Group className="mb-16" template="auto 1fr" gap="16px">
            <Skeleton type="avatar" />
            <Space direction="vertical">
              <Skeleton block style={{ height: 26, maxWidth: '20%' }} />
              <Skeleton type="input" block />
            </Space>
          </Group>
        </Col>
      );
    });
  };

  const renderRega = () => {
    return (
      <>
        {isMobile ? (
          <Group template="initial" gap="0px">
            <Skeleton type="paragraph" paragraph={{ rows: 3 }} />
          </Group>
        ) : (
          <Group template="repeat(3,minmax(100px, 250px))" gap="40px" className="justify-content-between">
            <Skeleton type="paragraph" paragraph={{ rows: 2 }} />
            <Skeleton type="paragraph" paragraph={{ rows: 2 }} />
            <Skeleton type="paragraph" paragraph={{ rows: 2 }} />
          </Group>
        )}
      </>
    );
  };

  return (
    <Group gap="16px">
      <Card bodyStyle={{ padding: 30 }}>{renderRega()}</Card>

      <Card bodyStyle={{ padding: 30 }}>
        {/* <Row align="center" className="mb-40" style={{ gap: 16 }}>
          <Skeleton type="avatar" />
          <Skeleton type="input" style={{ height: 2, marginTop: 12 }} />
          <Skeleton type="avatar" />
        </Row> */}
        <Row align="center" style={{ gap: 16 }}>
          <Col xs={{ span: 24, offset: 0 }} lg={{ span: 18, offset: 6 }}>
            <Group style={{ maxWidth: 640 }}>{renderFields()}</Group>
          </Col>
        </Row>
      </Card>

      {/* {[1].map(item => (
        <CardBlock loading key={item}>
          <Group style={{ maxWidth: 640 }}>{renderFields()}</Group>
        </CardBlock>
      ))} */}
    </Group>
  );
};

export default PostListing;
