import tenantTheme from '@theme';
import { Card } from 'antd';
import cx from 'clsx';
import React from 'react';
import { useSelector } from 'react-redux';
import { Flex, Icon, LinkWithIcon } from '..';
import { CardMetaStyled } from '../../../container/pages/user-settings/style';
import { IconSwitch } from '../../svg';
import { Link } from 'react-router-dom';

const CustomCard = ({
  avatarIcon,
  title,
  description,
  link,
  disabled,
  cardBackground,
  iconProps,
  titleClass,
  isVerified,
  borderWidth = 1,
  padding,
  style,
  iconSize = 32,
  onClick = () => {},
  internalLink,
}) => {
  const isMobile = useSelector((state) => state.AppConfig.isMobile);

  const renderCard = () => {
    return (
      <Card
        className="mb-8 "
        style={{ borderWidth: borderWidth, backgroundColor: cardBackground, ...style }}
        bodyStyle={{ padding: padding || 16 }}
        disabled={disabled}
        onClick={() => onClick()}
      >
        <Flex justify="space-between" gap={isMobile ? '20px' : '4px'}>
          <CardMetaStyled
            className="p-0"
            style={{ alignItems: 'center' }}
            avatar={
              avatarIcon ? <Icon size={iconSize} icon={avatarIcon} iconProps={iconProps ? iconProps : {}} /> : null
            }
            title={
              title ? (
                <Flex
                  gap="8px"
                  align="center"
                  className={cx(titleClass ? titleClass : 'fw-600', 'fz-14')}
                  style={{ color: isVerified ? tenantTheme['primary-color'] + 'aa' : '' }}
                >
                  {title}
                </Flex>
              ) : null
            }
            description={description ? <div className="color-gray-dark fz-12">{description}</div> : null}
          />
          {link && !internalLink && (
            <LinkWithIcon
              as="a"
              icon={<IconSwitch color={tenantTheme['primary-color']} />}
              href={link}
              className="btnLink"
              target="_blank"
            />
          )}
        </Flex>
      </Card>
    );
  };

  return link ? (
    internalLink ? (
      <Link to={link}>{renderCard()} </Link>
    ) : (
      <a style={{ width: '100%' }} href={link}>
        {renderCard()}
      </a>
    )
  ) : (
    renderCard()
  );
};

export default CustomCard;
