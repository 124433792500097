import chroma from 'chroma-js';

const gray800 = '#4c4a4a';
const gray700 = '#adb4d2';
const gray600 = '#9D9D9D';
const gray500 = '#C1BFBF';
const gray400 = '#DEDEDE';
const gray300 = '#E6E6E6';
const gray200 = '#f5f5f5';
const gray100 = '#f0f0f0';

const primaryColor = '#009f2b';
const primaryHover = '#009f2b';
const primaryLight = chroma.mix('#fff', primaryColor, 0.18).set('hsl.h', 160);
const primaryLight1 = chroma.mix('#fff', primaryColor, 0.8);
const primaryLight2 = '#CCDFE1';
const primaryLight3 = primaryLight;
const primaryLight4 = '#F2FAFA';

const secondaryColor = primaryColor;
const secondaryHover = chroma.mix(primaryColor, '000', 0.24);
const linkColor = '#1890ff';
const linkHover = '#0D79DF';
const headingColor = 'rgba(0, 0, 0, 0.85)';
const successColor = '#7ed8aa';
const successHover = '#0CAB7C';
const warningColor = '#FA8B0C';
const warningHover = '#D47407';
const errorColor = '#e83a3a';
const errorHover = '#E30D0F';
const infoColor = '#90EE90';
const infoHover = '#0D79DF';
const baseColor = '#1F1F1F';
const darkColor = '#272B41';
const darkHover = '#131623';
const grayColor = '#5A5F7D';
const grayLightestColor = '#bdbdbd';
const grayDarkColor = '#707070';
const grayHover = '#363A51';
const lightColor = '#9299B8';
const lightHover = '#e2e6ea';
const whiteColor = '#fff';
const dashColor = '#E3E6EF';
const whiteHover = '#5A5F7D';
const extraLightColor = gray700;
const dangerColor = '#FF4D4F';
const borderColorLight = '#F1F2F6';
const borderColorNormal = '#E3E6EF';
const borderColorDeep = '#C6D0DC';
const bgGrayColorDeep = '#EFF0F3';
const bgGrayColorLight = '#F8F9FB';
const bgGrayColorNormal = '#F4F5F7';
const lightGrayColor = '#868EAE';
const sliderRailColor = 'rgba(95,99,242,0.2)';
const graySolid = lightColor;
const healthColorLow = '#ffb5b5';
const healthColorAvg = '#ffcb64';
const healthColorGood = '#7ed8aa';
const placeholderColor = '#bdbdbd';
const errorRed = '#E83A3A';

const btnlg = '48px';
const btnsm = '36px';
const btnxs = '29px';

const theme = {
  gray800,
  gray700,
  gray600,
  gray500,
  gray400,
  gray300,
  gray200,
  gray100,

  'primary-color': primaryColor,
  'primary-hover': primaryHover,
  'primary-light': primaryLight,
  'primary-light-color': primaryLight3,
  'primary-light-1': primaryLight1,
  'primary-light-2': primaryLight2,
  'primary-light-3': primaryLight3,
  'primary-light-4': primaryLight4,
  'secondary-color': secondaryColor,
  'secondary-hover': secondaryHover,
  'link-color': linkColor,
  'link-hover': linkHover,
  'success-color': successColor,
  'success-hover': successHover,
  'warning-color': warningColor,
  'warning-hover': warningHover,
  'error-color': errorColor,
  'error-hover': errorHover,
  'info-color': infoColor,
  'info-hover': infoHover,
  'base-color': baseColor,
  'dark-color': darkColor,
  'dark-hover': darkHover,
  'error-red': errorRed,
  'gray-color': grayColor,
  'gray-lightest-color': grayLightestColor,
  'gray-dark-color': grayDarkColor,
  'gray-hover': grayHover,
  'light-color': lightColor,
  'light-hover': lightHover,
  'white-color': whiteColor,
  'white-hover': whiteHover,
  white: whiteColor,
  black: '#000',
  'placeholder-color': placeholderColor,
  'label-muted': lightColor,
  'dash-color': dashColor,
  'extra-light-color': extraLightColor,
  'danger-color': dangerColor,
  'font-family': "'Lato', sans-serif",
  'font-size-base': '14px',
  'heading-color': headingColor,
  'text-color': darkColor,
  'text-color-secondary': grayColor,
  'disabled-color': 'rgba(0, 0, 0, 0.25)',
  'border-radius-base': '6px',
  'border-color-base': '#d9d9d9',
  'box-shadow-base': '0 2px 8px rgba(0, 0, 0, 0.15)',
  'border-color-light': borderColorLight,
  'border-color-normal': borderColorNormal,
  'border-color-deep': borderColorDeep,
  'bg-color-light': bgGrayColorLight,
  'bg-color-normal': bgGrayColorNormal,
  'bg-color-deep': bgGrayColorDeep,
  'light-gray-color': lightGrayColor,
  'gray-solid': graySolid,
  'btn-height-large': btnlg,
  'btn-height-small': btnsm,
  'btn-height-extra-small': btnxs,
  'btn-default-color': darkColor,
  'main-bg-color': 'rgb(244, 245, 247)',

  // cards
  'card-head-background': '#ffffff',
  'card-head-color': darkColor,
  'card-background': '#ffffff',
  'card-head-padding': '16px',
  'card-padding-base': '12px',
  'card-radius': '10px',
  'card-shadow': '0 5px 20px rgba(146,153,184,0.03)',
  'card-border-width-lg': '1px',
  'card-border-width-sm': '1px',

  // Layout
  'layout-body-background': '#F4F5F7',
  'layout-header-background': '#ffffff',
  'layout-footer-background': '#fafafa',
  'layout-header-height': '74px',
  'layout-header-padding': '0 30px 0 15px',
  'layout-footer-padding': '24px 15px',
  'layout-sider-background': '#ffffff',
  'layout-trigger-height': '48px',
  'layout-trigger-background': '#002140',
  'layout-trigger-color': '#fff',
  'layout-zero-trigger-width': '36px',
  'layout-zero-trigger-height': '42px',

  // Layout light theme
  'layout-sider-background-light': '#fff',
  'layout-trigger-background-light': '#fff',
  'layout-trigger-color-light': 'rgba(0, 0, 0, 0.65)',

  // PageHeader
  'page-header-padding': '24px',
  'page-header-padding-vertical': '16px',
  'page-header-padding-breadcrumb': '12px',
  'page-header-back-color': '#000',
  'page-header-ghost-bg': 'inherit',

  // Popover body background color
  'popover-color': darkColor,

  // Modal
  'modal-border-radius': '12px',

  // alert
  'alert-success-border-color': successColor,
  'alert-success-bg-color': successColor + 15,
  'alert-error-bg-color': errorColor + 15,
  'alert-warning-bg-color': warningColor + 15,
  'alert-info-bg-color': infoColor + 15,

  // radio btn
  'radio-button-checked-bg': primaryColor,
  // radio pills
  'radio-pill-bg': 'transparent',
  'radio-pill-checked-bg': primaryLight3,
  'radio-pill-border-color': gray400,
  'radio-pill-checked-border-color': primaryLight1,
  'radio-pill-text-color': baseColor,
  'radio-pill-checked-text-color': primaryColor,

  // gutter width
  'grid-gutter-width': 25,

  // skeleton
  'skeleton-color': borderColorLight,

  // slider
  'slider-rail-background-color': sliderRailColor,
  'slider-rail-background-color-hover': sliderRailColor,
  'slider-track-background-color': primaryColor,
  'slider-track-background-color-hover': primaryColor,
  'slider-handle-color': primaryColor,
  'slider-handle-size': '16px',

  // input
  'input-height-base': '48px',
  'input-border-color': borderColorNormal,
  'input-height-sm': '30px',
  'input-height-lg': '50px',

  // rate
  'rate-star-color': warningColor,
  'rate-star-size': '13px',

  // Switch
  'switch-min-width': '35px',
  'switch-sm-min-width': '30px',
  'switch-height': '18px',
  'switch-sm-height': '15px',

  // result
  'result-title-font-size': '20px',
  'result-subtitle-font-size': '12px',
  'result-icon-font-size': '50px',

  // tabs
  'tabs-horizontal-padding': '12px 15px',
  'tabs-horizontal-margin': '0',

  // list
  'list-item-padding': '10px 24px',

  // Tags
  'tag-default-bg': '#EFF0F3',
  'tag-default-color': darkColor,
  'tag-font-size': '11px',

  // Health
  'health-color-low': healthColorLow,
  'health-color-avg': healthColorAvg,
  'health-color-good': healthColorGood,
};

const darkTheme = { ...theme, backgroundColor: '#000' };

const themeTokens = {
  token: {
    borderColor: gray100,
    borderRadius: '6px',
    borderRadiusLG: '8px',
    boxShadow: '0 2px 8px rgba(0, 0, 0, 0.15)',
    btnHeightLG: btnlg,
    btnHeightSM: btnsm,
    colorBgSpotlight: '#fff',
    colorError: errorColor,
    colorInfo: infoColor,
    colorLink: linkColor,
    colorPrimary: primaryColor,
    colorSuccess: successColor,
    colorTextDisabled: 'rgba(0, 0, 0, 0.25)',
    colorWarning: warningColor,
    fontFamily: 'Lato, Droid Arabic Kufi, sans-serif',
    fontSize: 14,
    fontSizeHeading1: 38,
    fontSizeHeading2: 30,
    fontSizeHeading3: 24,
    fontSizeHeading4: 20,
    fontSizeHeading5: 16,
    headingColor: headingColor,
    lineHeight: 1.571,
    textColor: darkColor,
    textColorSecondary: gray700,
  },
  components: {
    Button: {
      contentLineHeight: 2,
      paddingBlock: 17,
      paddingBlockLG: 7,
      paddingBlockSM: 16,
      defaultBorderColor: gray100,
      //  paddingBlockSM: 12,
      controlHeight: 36,
      controlHeightLG: 40,
      controlHeightSM: 32,
    },

    Card: {
      colorTextHeading: darkColor,
      headerBg: '#fff',
      colorBgContainer: '#fff',
      cardPaddingBase: 12,
      borderRadiusLG: 10,
      paddingLG: 12,

      // The following tokens need to be set directly in the component or custom styles as they are either deprecated or not directly mapped:
      // 'card-head-padding': '16px',  // Deprecated
      // 'card-shadow': '0 5px 20px rgba(146,153,184,0.03)', // No direct mapping
      // 'card-border-width-lg': '1px',  // No direct mapping
      // 'card-border-width-sm': '0px',  // No direct mapping
    },

    // Layout
    Layout: {
      bodyBg: '#F6F7FB',
      headerBg: '#fff',
      footerBg: '#fafafa',
      headerHeight: 74,
      headerPadding: '0 30px 0 15px',
      footerPadding: '24px 15px',
      siderBg: '#fff',
      triggerHeight: 48,
      triggerBg: '#002140',
      triggerColor: '#fff',
      zeroTriggerWidth: 36,
      zeroTriggerHeight: 42,
      lightSiderBg: '#fff',
      lightTriggerBg: '#fff',
      lightTriggerColor: 'rgba(0, 0, 0, 0.65)',
    },

    // Popover
    Popover: {
      colorText: darkColor,
      innerPadding: '16px',
    },

    //List
    List: {
      itemPadding: '10px 24px',
    },

    Menu: {
      iconMarginInlineEnd: 10,
    },

    // Alert
    Alert: {
      colorSuccessBorder: successColor,
      colorSuccessBg: chroma.mix('fff', successColor, 0.16).saturate(0.08),
      colorSuccess: successColor,
      colorInfoBorder: infoColor,
      colorInfoBg: chroma.mix('fff', infoColor, 0.16).saturate(0.08),
      colorInfo: infoColor,
      colorWarningBorder: warningColor,
      colorWarningBg: chroma.mix('fff', warningColor, 0.16).saturate(0.08),
      colorWarning: warningColor,
      colorErrorBorder: errorColor,
      colorErrorBg: chroma.mix('fff', errorColor, 0.16).saturate(0.08),
      colorError: errorColor,
      colorText: baseColor,
    },

    // Radio buttons
    Radio: {
      buttonBg: '#fff',
      buttonCheckedBg: primaryColor,
      buttonColor: gray700,
      radioPillBg: gray200,
      radioPillCheckedBg: primaryLight3,
      radioPillBorderColor: 'transparent',
      radioPillCheckedBorderColor: primaryLight1,
      radioPillTextColor: baseColor,
      radioPillCheckedTextColor: primaryColor,
    },

    // skeleton
    Skeleton: {
      gradientFromColor: borderColorLight,
    },

    // slider
    Slider: {
      railBg: sliderRailColor,
      railHoverBg: sliderRailColor,
      trackBg: primaryColor,
      trackHoverBg: primaryColor,
      handleColor: primaryColor,
      handleSize: '16px',
    },

    // Input
    Input: {
      colorBorder: borderColorNormal,
      colorTextPlaceholder: placeholderColor,
      paddingBlock: 10,
      paddingBlockSM: 8,
      paddingBlockLG: 12,
      activeShadow: `0 0 0 2px ${chroma(primaryColor).alpha(0.1)}`,
    },

    // Rate
    Rate: {
      starColor: warningColor,
      starSize: 13,
    },

    // Switch
    Switch: {
      trackMinWidth: 35,
      trackMinWidthSM: 30,
      trackHeight: 18,
      trackHeightSM: 15,
      trackPadding: 2,
      handleSize: 14,
      colorTextTertiary: 'rgba(0, 0, 0, .25)',
    },

    // result
    Result: {
      titleFontSize: 20,
      subtitleFontSize: 12,
      iconFontSize: 50,
    },

    // tabs
    Tabs: {
      horizontalItemPadding: '12px 15px',
      horizontalItemMargin: '0',
      horizontalItemGutter: 0,
    },

    // Steps
    Steps: {
      colorSplit: gray400,
      colorBgContainer: '#F5F5F5',
    },

    // Tags
    Tags: {
      defaultBg: '#EFF0F3',
      defaultColor: darkColor,
      fontSizeSM: 11,
    },

    // Select
    Select: {
      colorBorder: borderColorNormal,
      controlHeight: 44,
      controlHeightSM: 34,
      controlHeightLG: 50,
    },

    Modal: {
      titleFontSize: 16,
    },
  },
};

export { theme, darkTheme, themeTokens };
