import tenantApi from '@api';
import tenantConstants from '@constants';
import tenantTheme from '@theme';
import { Collapse, Divider, Radio, Space, Typography } from 'antd';
import cx from 'clsx';
import React, { lazy, Suspense, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import SuccessfulPaymentModal from '../../../../components/checkout/successfulPaymentModal';
import { upgradeButtonUpsellClickEvent, postListingClickEvent } from '../../../../services/analyticsService';
import {
  Alert,
  Button,
  Card,
  Checkbox,
  Dropdown,
  EmptyState,
  Group,
  Heading,
  Icon,
  notification,
  Skeleton,
  Spinner,
} from '../../../../components/common';
import CreditInfo from '../../../../components/credits-info/credits-info';
import PitchPackageModal from '../../../../components/pitch-package-modal/pitchPackageModal';
import { TIME_DATE_FORMAT } from '../../../../constants/formats';
import { ActionButton } from '../../../../container/pages/user-settings/style';
import { Main } from '../../../../container/styled';
import { useRouteNavigate } from '../../../../hooks';
import { pageViewUpsellEvent } from '../../../../services/analyticsService';
import UpsellDesktop from '../../../../static/lottie/bayutUpsellDesktop.json';
import UpsellMobile from '../../../../static/lottie/bayutUpsellMobile.json';
import { getTimeDateString } from '../../../../utility/date';
import { formatPrice } from '../../../../utility/utility';
import Product from './product';
import ServiceOptions from './ServiceOptions';
import { CollapseCard, CollapseRadio, CollapseStyled, UpSellImage } from './style';
const Lottie = lazy(() => import('../../../../components/common/lottie/lottie'));

const RadioGroup = Radio.Group;
const { Panel } = Collapse;

const UpgradeListingPage = (props) => {
  const listingId = props?.id;
  const { t, i18n } = useTranslation();
  const [total, setTotal] = useState(0);
  const [upsellData, setUpsellData] = useState(null);
  const [successModalData, setSuccessModalData] = useState(null);
  const [serviceSelected, setServiceSelected] = useState({});
  const [activeProduct, setActiveProduct] = useState(null);
  const [requiredAmount, setRequiredAmount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [pitchPackageVisible, setPitchPackageVisible] = useState(false);
  const [error, setError] = useState(null);
  const user = useSelector((state) => state.loginUser.user);
  const isMobile = useSelector((state) => state.AppConfig.isMobile);
  const [listingsData, setListingsData] = useState(null);
  const navigate = useRouteNavigate();
  const serviceRefs = useRef({});

  useEffect(() => {
    listingsData && pageViewUpsellEvent(user, listingsData);
  }, [listingsData]);

  useEffect(() => {
    handleInitialSelection();
  }, [upsellData]);

  useEffect(() => {
    getProductsData();
  }, []);

  useEffect(() => {
    handleTotal();
  }, [serviceSelected, activeProduct]);

  const handleTotal = () => {
    const availableCredits = upsellData?.ksa?.availableCredits;
    const creditUnitPrice = upsellData?.ksa?.creditUnitPrice;
    const pricingKey = availableCredits ? 'requiredCredits' : 'price';
    let total = 0;
    total += activeProduct && activeProduct?.activePrice;
    selectedProducts.forEach((key) => {
      const service = upsellData?.ksa?.subServices?.find((e) => e.id == key);
      if (service) {
        total += service?.[pricingKey]?.[30];
      }
    });
    setTotal(total);

    setRequiredAmount({ credits: total - availableCredits, amount: (total - availableCredits) * creditUnitPrice });
  };

  const handleInitialSelection = () => {
    let signatureProduct = null;
    if (!upsellData?.ksa?.isPosted) {
      signatureProduct = upsellData?.ksa?.applicableProducts?.find((e) => e.slug === 'signature-listing');
      setActiveProduct({
        ...signatureProduct,
        activePrice: upsellData?.ksa?.availableCredits
          ? signatureProduct?.requiredCredits[signatureProduct?.activeDuration]
          : signatureProduct?.price[signatureProduct?.activeDuration],
      });
    } else {
      setActiveProduct(null);
    }
  };

  const selectedProducts = useMemo(() => {
    const products = [];
    activeProduct?.id && products.push(activeProduct?.id);
    Object.keys(serviceSelected).forEach((e) => {
      serviceSelected[e] && products.push(parseInt(e));
    });
    return products;
  }, [activeProduct, serviceSelected]);
  const applyProducts = async (isPosted) => {
    setButtonLoading(true);
    const form_values = Object.keys(serviceRefs.current).reduce((acc, key) => {
      if (serviceRefs.current[key]) {
        acc[key] = serviceRefs.current[key].getValues();
      }
      return acc;
    }, {});
    const payload = {
      add_on: {
        'photography-service': form_values['photography-service']
          ? {
              requested_at: getTimeDateString(
                form_values['photography-service'].requested_at,
                TIME_DATE_FORMAT,
                false,
                true,
              ),
              description: form_values['photography-service'].comments,
            }
          : {},
        'videography-service': form_values['videography-service']
          ? {
              requested_at: getTimeDateString(
                form_values['videography-service'].requested_at,
                TIME_DATE_FORMAT,
                false,
                true,
              ),
              description: form_values['videography-service'].comments,
            }
          : {},
        'drone-footage-service': form_values['drone-footage-service']
          ? {
              requested_at: getTimeDateString(
                form_values['drone-footage-service'].requested_at,
                TIME_DATE_FORMAT,
                false,
                true,
              ),
              description: form_values['drone-footage-service'].comments,
            }
          : {},
      },
      product_ids: selectedProducts,
    };
    const response = await tenantApi.applyProducts(listingId, payload);
    if (response) {
      setButtonLoading(false);
      if (response.error) {
        notification.error(response.error);
      } else {
        if (!isPosted) {
          notification.success(t('Listing Posted Successfully'));
        } else {
          upgradeButtonUpsellClickEvent(user, response, listingsData, form_values, selectedProducts);
          notification.success(t('Listing Updated Successfully'));
        }
        !isPosted ? setSuccessModalData({ listing_id: listingId }) : navigate('/listings');
      }
    }
  };

  const createCart = async (currentCredits, currency, creditUnitPrice) => {
    setButtonLoading(true);
    const form_values = Object.keys(serviceRefs.current).reduce((serviceRef, key) => {
      serviceRef[key] = serviceRefs.current[key]?.getValues();
      return serviceRef;
    }, {});

    const cart = {
      total_credits: currency === 'Credits' ? total - currentCredits : total / creditUnitPrice,
      listing_expiry_days: activeProduct?.activeDuration || upsellData?.ksa?.listing?.expiry_days,
      source: 'profolio',
      listing_id: listingId,
      cart_details_attributes: [],
    };

    cart.cart_details_attributes = selectedProducts.map((item) => {
      const service = upsellData?.ksa?.subServices?.find((e) => e.id == item);
      if (service) {
        return {
          item_id: item,
          item_type: 'Product',
          source: 'profolio',
          quantity: 1,
          add_on: {
            [service.slug]: {
              requested_at: getTimeDateString(form_values[service.slug].requested_at, TIME_DATE_FORMAT, false, true),
              description: form_values[service.slug].comments,
            },
          },
        };
      }
      return { item_id: item, item_type: 'Product', source: 'profolio', quantity: 1 };
    });

    const response = await tenantApi.createUpsellCart(cart);
    if (response) {
      setButtonLoading(false);
      if (response.error) {
        notification.error(response.error);
      } else {
        navigate(`/checkout?cart_id=${response?.cart?.id}&upgrade_listing=true`, {
          state: {
            disposition: listingsData?.disposition?.slug,
            status: listingsData?.status?.slug,
            interactedFrom: 'upsell',
          },
        });
      }
    }
  };

  const handleCheckout = async () => {
    const currency = upsellData?.ksa?.currency;
    const currentCredits = upsellData?.ksa?.availableCredits;
    const creditUnitPrice = upsellData?.ksa?.creditUnitPrice;
    const isPosted = upsellData?.ksa?.isPosted;
    let isValid = true;

    const serviceRefsKeys = Object.keys(serviceRefs.current);
    const errorPromises = serviceRefsKeys.map(async (key) => {
      const serviceRef = serviceRefs.current[key];
      if (serviceRef) {
        serviceRef.handleSubmit();
        const errors = await serviceRef.getErrors();
        if (errors) {
          isValid = false;
        }
        return errors;
      } else {
        return true;
      }
    });
    await Promise.all(errorPromises);
    if (isValid) {
      if (total > currentCredits) {
        createCart(currentCredits, currency, creditUnitPrice);
      } else {
        applyProducts(isPosted);
      }
    }
  };

  const handleDropdown = (prod, platform) => (value) => {
    const availableCredits = upsellData?.[platform?.slug]?.availableCredits;
    let selectedService = [];
    Object.keys(serviceSelected).forEach((e) => {
      if (serviceSelected[e]) {
        selectedService.push(upsellData[platform?.slug].subServices.find((item) => item.id == e));
      }
    });
    setActiveProduct((prevState) => ({
      ...prevState,
      activeDuration: value?.key,
      activePrice: availableCredits ? prod.requiredCredits[value?.key] : prod.price[value?.key],
    }));
  };

  const getProductsData = async () => {
    setLoading(true);
    const response = await tenantApi.getApplicableProducts(listingId, user?.platforms, true, true);
    if (response) {
      setListingsData(response?.ksa?.listing);
      setLoading(false);
      if (response.error) {
        notification.error(response.error);
        setError(response.error);
      } else {
        setUpsellData(response);
      }
    }
  };

  const onChangeCheckbox = (service, platform) => () => {
    const updatedSelectedServices = {
      ...serviceSelected,
      [service.id]: !serviceSelected[service.id],
    };
    setServiceSelected(updatedSelectedServices);
  };

  const onClickProduct = (prod, platform) => {
    const availableCredits = upsellData?.[platform?.slug]?.availableCredits;
    if (activeProduct?.id != prod.id) {
      setActiveProduct({
        ...prod,
        activePrice: availableCredits ? prod.requiredCredits[prod.activeDuration] : prod.price[prod.activeDuration],
      });
    } else if (upsellData?.[platform?.slug]?.isPosted) {
      setActiveProduct(null);
    }
  };

  const durationOptions = useMemo(
    () => [
      { key: 30, name: t('30 Days') },
      { key: 60, name: t('60 Days') },
      { key: 90, name: t('90 Days') },
    ],
    [i18n.language],
  );

  const renderAlert = (platform) => {
    return (
      <>
        {!!upsellData?.[platform?.slug]?.availableCredits && total > upsellData?.[platform?.slug]?.availableCredits && (
          <Alert
            style={{ padding: 0 }}
            className={isMobile ? 'mb-16' : 'mb-24'}
            showIcon
            type=""
            icon={
              <Icon className="color-gray-dark" icon="HiInformationCircle" size={16} style={{ marginTop: '2px' }} />
            }
            message={
              <div
                className={cx('color-gray-light', isMobile ? 'fz-12' : 'fz-14')}
                style={{ paddingBlockStart: !isMobile && '3px', lineHeight: isMobile && 1.5 }}
              >
                {t('Insufficient Credits.')} {t('Pay')}{' '}
                <strong className="color-primary">
                  {t(tenantConstants.currency)} {formatPrice(Math.round(requiredAmount?.amount))}
                </strong>{' '}
                {t(`for the additional {{credits}} credits at checkout`, { credits: requiredAmount?.credits })}
              </div>
            }
          />
        )}
      </>
    );
  };

  const currencyHandler = (prod, currency) => {
    if (currency === t(tenantConstants.currency)) {
      return (
        <div>
          {currency}{' '}
          {activeProduct && prod.id === activeProduct?.id
            ? prod.price[activeProduct?.activeDuration]
            : prod.price[prod?.activeDuration]}
        </div>
      );
    } else {
      return (
        <div>
          <strong className="fs16">
            {activeProduct && prod.id === activeProduct.id
              ? prod.requiredCredits[activeProduct?.activeDuration]
              : prod.requiredCredits[prod?.activeDuration]}{' '}
          </strong>
          {t('Credits')}
          {/* <div className="fs12 color-gray-lightest">{t('Credits Required')}</div> */}
        </div>
      );
    }
  };

  return user?.is_credit_user ? (
    user?.platforms.map((platform) => (
      <Main>
        <div style={{ maxWidth: 800, marginInline: 'auto' }}>
          {loading ? (
            <Card bodyStyle={{ padding: isMobile ? 16 : '24px 40px 32px' }}>
              <Space size={16} direction="vertical" style={{ width: '100%', textAlign: 'center' }}>
                <Skeleton type="image" active />
                <Skeleton type="title" active />
                {[1, 2, 3].map(() => (
                  <Skeleton type="button" style={{ height: 90 }} size={'large'} block active />
                ))}
              </Space>
            </Card>
          ) : error ? (
            <EmptyState title={''} message={error} onClick={getProductsData} />
          ) : upsellData ? (
            <>
              <Card bodyStyle={{ padding: isMobile ? 16 : '24px 40px 32px' }}>
                {/* {upsellData?.[platform?.slug]?.isPosted && (
              <Button type="link" size="large" style={{ height: 40 }} href="/listings">
                {t('Skip')}
              </Button>
            )} */}
                <div className={cx('color-gray-lightest text-center ', isMobile ? 'mb-16' : 'mb-24')}>
                  <UpSellImage>
                    <Suspense fallback={<Spinner />}>
                      <Lottie animationData={isMobile ? UpsellMobile : UpsellDesktop} />
                    </Suspense>
                  </UpSellImage>

                  <Heading style={{ color: tenantTheme['base-color'] }} as={isMobile ? 'h4' : 'h2'} className="mb-4">
                    {t(upsellData?.[platform?.slug]?.headingMsg)}
                  </Heading>
                  {t(upsellData?.[platform?.slug]?.descriptionMsg)}
                </div>

                {!!upsellData?.[platform?.slug]?.applicableProducts?.length && (
                  <div className={isMobile ? 'mb-4' : 'mb-8'}>
                    <Heading className="mb-12" as="h6" style={{ fontWeight: '700' }}>
                      {t('Upgrade your listing to get more leads')}
                    </Heading>

                    <RadioGroup style={{ width: '100%' }} value={activeProduct?.id}>
                      {upsellData?.[platform?.slug]?.applicableProducts?.map((prod, index) => (
                        <CollapseStyled
                          activeKey={null}
                          style={{ backgroundColor: 'white' }}
                          className={cx('mb-12', activeProduct?.id == prod.id && 'listing-card')}
                          onChange={() => onClickProduct(prod, platform)}
                        >
                          <Panel
                            key={prod.id}
                            showArrow={false}
                            // style={{ marginBottom: isMobile ? 10 : 12 }}
                            header={
                              <>
                                <Product
                                  title={t(prod.title)}
                                  icon={prod.icon}
                                  iconColor={prod.iconColor}
                                  iconSize={14}
                                  dropDown={
                                    upsellData?.[platform?.slug]?.availableCredits <
                                      prod?.requiredCredits[prod?.activeDuration] &&
                                    !upsellData?.[platform?.slug]?.isPosted && (
                                      <Dropdown
                                        style={{
                                          borderWidth: '0 0 1px',
                                          borderRadius: 0,
                                          padding: 3,
                                          background: 'none',
                                          color: tenantTheme['primary-color'],
                                        }}
                                        placement="bottomLeft"
                                        options={durationOptions}
                                        onChange={handleDropdown(prod, platform)}
                                        // onClick={e => e.stopPropagation()}
                                      >
                                        {activeProduct && activeProduct.id == prod.id
                                          ? activeProduct?.activeDuration
                                          : prod.activeDuration}{' '}
                                        {t('Days')}
                                      </Dropdown>
                                    )
                                  }
                                  description={t(prod.description)}
                                  isRecommended={prod.isRecommended}
                                  price={
                                    <CollapseRadio key={index} value={prod.id}>
                                      {currencyHandler(prod, upsellData?.[platform?.slug]?.currency)}
                                    </CollapseRadio>
                                  }
                                />
                              </>
                            }
                          ></Panel>
                        </CollapseStyled>
                      ))}
                    </RadioGroup>
                  </div>
                )}
                {!!upsellData?.[platform?.slug]?.subServices?.length && (
                  <div className={isMobile ? 'mb-16' : 'mb-24'}>
                    <Heading className="mb-12" as="h6" style={{ fontWeight: '700' }}>
                      {t('Add additional services to stand out')}
                    </Heading>

                    <Card style={{ borderWidth: 1 }}>
                      <Group gap="12px">
                        {upsellData?.[platform?.slug]?.subServices &&
                          upsellData?.[platform?.slug]?.subServices?.map((service) => (
                            // <div onClick={onChangeCheckbox(service, platform)} style={{ cursor: 'pointer' }}>
                            <Product
                              title={t(service.title)}
                              description={t(service.description)}
                              icon={service.icon}
                              iconColor={service.iconColor}
                              iconSize={14}
                              price={
                                <div className="align-center-v color-primary" style={{ gap: 6 }}>
                                  {upsellData?.[platform?.slug]?.availableCredits == 0 &&
                                    upsellData?.[platform?.slug]?.currency}
                                  <strong className="fs16">
                                    {upsellData?.[platform?.slug]?.availableCredits
                                      ? service.requiredCredits[service?.activeDuration]
                                      : service.price[service?.activeDuration]}
                                  </strong>
                                  {upsellData?.[platform?.slug]?.availableCredits > 0 && t('Credits')}
                                  <Checkbox
                                    onChange={onChangeCheckbox(service, platform)}
                                    value={serviceSelected[service?.id]}
                                    id={service.id}
                                    style={{ marginInlineStart: 8 }}
                                  />
                                </div>
                              }
                              discountedPrice={upsellData?.[platform?.slug]?.availableCredits ? '' : service.oldPrice}
                              className="align-items-center"
                              extra={
                                serviceSelected?.[service.id] && (
                                  <CollapseCard
                                    style={{ borderWidth: 0 }}
                                    bodyStyle={{ padding: 16 }}
                                    className="collapse-card"
                                  >
                                    <ServiceOptions
                                      ref={(el) => (serviceRefs.current[service.slug] = el)}
                                      template={isMobile ? 'initial' : 'repeat(2,1fr)'}
                                      style={{ alignItems: 'baseline' }}
                                    />
                                  </CollapseCard>
                                )
                              }
                            />
                            // </div>
                          ))}
                      </Group>
                    </Card>
                  </div>
                )}
                {(!!upsellData?.[platform?.slug]?.applicableProducts.length ||
                  !!upsellData?.[platform?.slug]?.subServices.length) &&
                  upsellData?.[platform?.slug]?.availableCredits > 0 && (
                    <CreditInfo
                      className={'mb-16'}
                      cardStyle={{ width: '100%' }}
                      isMobile={isMobile}
                      loading={loading}
                      user={user}
                    />
                  )}
                {renderAlert(platform)}
                {isMobile && (
                  <Button
                    style={{ marginInlineEnd: 'auto' }}
                    onClick={() => {
                      upsellData?.[platform?.slug]?.isPosted
                        ? navigate(`/listings`)
                        : navigate(`/post-listing/${listingId}`);
                    }}
                    type="link"
                    size={!isMobile && 'large'}
                    // loading={buttonLoading}
                    disabled={buttonLoading}
                    className="p-0"
                  >
                    {upsellData?.[platform?.slug]?.isPosted ? t('View All Listings') : t('Edit Listing')}
                  </Button>
                )}

                {(!!upsellData?.[platform?.slug]?.applicableProducts.length ||
                  !!upsellData?.[platform?.slug]?.subServices.length) && (
                  <>
                    {!isMobile && <Divider style={{ marginBlock: '12px 24px' }} />}
                    <ActionButton>
                      {!isMobile && (
                        <Button
                          style={{ marginInlineEnd: 'auto' }}
                          onClick={() => {
                            upsellData?.[platform?.slug]?.isPosted
                              ? navigate(`/listings`)
                              : navigate(`/post-listing/${listingId}`);
                          }}
                          type="link"
                          size={isMobile ? 'small' : 'large'}
                          // loading={buttonLoading}
                          disabled={buttonLoading}
                          className="p-0"
                        >
                          {upsellData?.[platform?.slug]?.isPosted ? t('View All Listings') : t('Edit Listing')}
                        </Button>
                      )}
                      <div className="color-gray-lightest">
                        <div>{t('Total')}</div>
                        <Typography.Text className="fs16">
                          <strong>{total}</strong> {t(upsellData?.[platform?.slug]?.currency)}
                        </Typography.Text>
                      </div>

                      <Button
                        style={{ maxWidth: isMobile ? '178px' : '190px', width: '100%' }}
                        onClick={() => {
                          !user.is_package_user ? setPitchPackageVisible(true) : handleCheckout();
                        }}
                        type="primary"
                        // size={isMobile ? 'middle' : 'large'}
                        disabled={
                          upsellData?.[platform?.slug]?.isPosted ? !selectedProducts.length : !activeProduct?.id
                        }
                        loading={buttonLoading}
                      >
                        {upsellData?.[platform?.slug]?.isPosted ? t('Upgrade') : t('Post Listing')}
                      </Button>
                    </ActionButton>
                  </>
                )}
              </Card>
              {isMobile && <div style={{ height: 20 }}></div>}
            </>
          ) : (
            <Card bodyStyle={{ padding: isMobile ? 16 : '24px 40px 32px' }}>
              <EmptyState type={'table'} hideRetryButton />
            </Card>
          )}
        </div>
        <PitchPackageModal
          visible={pitchPackageVisible}
          setIsVisible={setPitchPackageVisible}
          onContinue={handleCheckout}
        />
        <SuccessfulPaymentModal data={successModalData} setData={setSuccessModalData} />
      </Main>
    ))
  ) : (
    <EmptyState title={''} message={t('You are not authorised to access this page!')} hideRetryButton />
  );
};

export default UpgradeListingPage;
