import tenantData from '@data';
import tenantUtils from '@utils';
import store from '../../../../redux/store';
import { setUsersList } from '../../../../redux/users/actionCreator';
import { NetworkService } from '../../../../services/networkService';
import { capitalizeFirstLetter, mapQueryParams } from '../../../../utility/utility';

export const postedToZameen = (e) => {
  switch (e) {
    case 'not_listed':
      return false;
    case undefined:
      return false;
    case 'limit':
      return false;
    default:
      return true;
  }
};

export const postedToOLX = (e) => {
  switch (e) {
    case 'not_listed':
      return false;
    case undefined:
      return false;
    default:
      return true;
  }
};

const getTitle = (key) => {
  switch (key) {
    case 'active':
      return {
        title: 'Active',
        mapping: 'Active',
      };
    case 'deleted':
      return {
        title: 'Deleted',
        mapping: 'Deleted',
      };
    case 'downgraded':
      return {
        title: 'Downgraded',
        mapping: 'Downgraded',
      };

    case 'expired':
      return {
        title: 'Expired',
        mapping: 'Expired',
      };
    case 'inactive':
      return {
        title: 'Inactive',
        mapping: 'Inactive',
      };
    case 'pending':
      return {
        title: 'Pending',
        mapping: 'Pending',
      };
    case 'rejected':
      return {
        title: 'Rejected',
        mapping: 'Rejected',
      };
  }
};

const getSummaryData = (data) => {
  delete data.limit;
  const arr = Object.keys(data)?.length > 0 ? Object.keys(data) : [];
  const stats = {};
  arr.forEach((e) => {
    stats[capitalizeFirstLetter(e)] = {
      ...getTitle(e),
      total: data[e],
    };
  });
  return stats;
};

export const zameenMapper = (e, statsExists = false) => {
  return {
    id: e.id,
    property_id: e?.id,
    slug: 'zameen',
    posted_on: e?.platform?.zameen?.posted_at,
    consumedQuota: e?.consumed_quota,
    expiry_date: e?.expiry_date,
    updated_at: e?.updated_at,
    status: tenantUtils.listingStatusMapper(e?.platform?.zameen?.status, e, 'zameen'),
    statusKey: 'status',
    ...(e.listing_purpose && { purpose: e.listing_purpose.title }),
    price: { value: e?.price ? e?.price : e?.rental_price, currency: e?.currency },
    location: e.location,
    hidden: e?.platform?.zameen?.hidden,
    ...(!statsExists && {
      views: e?.listing_stats?.imp_total,
      leads:
        e?.listing_stats?.phone_total +
        e?.listing_stats?.email_total +
        e?.listing_stats?.whatsapp_total +
        e?.listing_stats?.sms_total,
      clicks: e?.listing_stats?.views_count,
    }),
    posted: e?.platform?.zameen?.status ? postedToZameen(e?.platform?.zameen?.status) : false,
    stories: e?.platform?.zameen?.stories,
    category: e?.platform?.zameen?.mark?.title,
    icon: 'IconZameen',
    public_url: e?.platform?.zameen?.public_url,
    listingPlatformActions: tenantUtils.getListingPlatformActions(e, 'zameen'),
    listingRowActions: (refsObject, loading) => {
      const actions = tenantUtils.getListingRowActions(e, 'zameen', refsObject, loading);
      return actions;
    },
    ...(!!e?.user && { listingOwner: e?.user }),
  };
};

export const olxMapper = (e, statsExists = false) => {
  return {
    id: e?.platform?.olx?.platform_listing_id,
    property_id: e?.id,
    slug: 'olx',
    posted: !!e?.platform?.olx?.status ? postedToOLX(e?.platform?.olx?.status) : false,
    consumedQuota: e?.platform?.olx?.consumed_quota,
    status: tenantUtils.listingStatusMapper(e?.platform?.olx?.status, e, 'olx'),
    statusKey: 'status',
    ...(e.listing_purpose && { purpose: e.listing_purpose.title }),
    price: { value: e.price ? e?.price : e?.rental_price, currency: e?.currency },
    location: e.location,
    posted_on: !!e?.platform?.olx && e?.platform?.olx?.posted_at,
    updated_at: !!e?.platform?.olx && e?.platform?.olx?.date_updated,
    hidden: e?.platform?.olx?.hidden,
    ...(!statsExists && { views: 'loading' }),
    ...(!statsExists && { leads: 'loading' }),
    category: e?.platform?.olx?.mark?.title,
    public_url: e?.platform?.olx?.public_url,
    icon: 'IconOLX',
    listingPlatformActions: tenantUtils.getListingPlatformActions(e, 'olx'),
    listingRowActions: (refsObject, loading) => {
      const actions = tenantUtils.getListingRowActions(e, 'olx', refsObject, loading);
      return actions;
    },
    ...(!!e?.user && { listingOwner: e?.user }),
  };
};

export const listingMapper = (listing, user, statsExists) => {
  const platforms = {
    property_id: listing?.id,
    ...(listing?.listing_purpose && { purpose: listing?.listing_purpose }),
    type: listing?.listing_type,
    price: { value: listing?.price ? listing?.price : listing?.rental_price, currency: listing?.currency },
    location: listing?.location,
    stories: listing?.platform?.zameen?.stories,
    stats: listing?.listing_stats,
    data: [
      ...(!!user?.products?.platforms['zameen'] ? [zameenMapper(listing, statsExists && statsExists)] : []),
      ...(!!user?.products?.platforms['olx'] ? [olxMapper(listing, statsExists && statsExists)] : []),
    ],
  };
  return {
    ...listing,
    id: listing?.id,
    type: listing?.listing_type?.title,
    property: {
      id: listing?.id,
      ...(listing?.listing_purpose && { purpose: listing?.listing_purpose }),
      type: listing?.listing_type,
      area: { value: listing?.area_unit_value, unit: listing?.area_unit },
      location: {
        ...listing?.location,
        breadcrumb: listing?.location?.breadcrumb
          ?.filter((e) => e?.level > 1)
          ?.map((e) => tenantUtils.getLocalisedString(e, 'title'))
          ?.join(', '),
      },
      ...(listing?.listing_health && {
        health: listing?.listing_health,
      }),
      canApplyRefresh: !listing?.platform?.zameen?.refresh_upto
        ? true
        : !!(new Date() > new Date(listing?.platform?.zameen?.refresh_upto)),
      details: {
        listing_type: listing?.listing_type,
        listingSpecs: tenantData?.getListingSpecs(listing),
      },
      platforms,
      image: listing?.images?.find((e) => e?.is_main_image === 1),
      imageCount: listing?.images_count,
      price: { value: listing.price ? listing?.price : listing?.rental_price, currency: listing?.currency },
    },
    price: { value: listing.price ? listing?.price : listing?.rental_price, currency: listing?.currency },
    area: { value: listing?.area_unit_value, unit: listing?.area_unit },
    posted_by: { name: listing?.user?.name },
    active_images_count: { value: listing?.images_count },
    ...(listing?.listing_health && {
      health: listing?.listing_health,
    }),
    platforms,
  };
};

export const fetchListingPageData = async (userId, params, filtersList) => {
  const savedAreaUnit = store.getState().listings?.units?.area_unit;
  const defaultAreaUnit = savedAreaUnit
    ? savedAreaUnit
    : store.getState().loginUser.user.settings?.length > 0
      ? tenantData.areaUnitList.find(
          (e) => e.slug == store.getState().loginUser.user.settings.find((e) => e.name === 'area_unit')?.value,
        )?.slug
      : 'square_feet';

  const mappedParams = mapQueryParams(params, filtersList);

  const listingSummary = await NetworkService.get(`/api/listings/summary?${mappedParams}`, {});
  if (listingSummary) {
    if (!!listingSummary?.error) {
      return { ...listingSummary, error: 'Network Error' };
    } else {
      const response = await NetworkService.get(`/api/listings/my_listings?${mappedParams}`, {});
      if (response) {
        if (!!response?.error) {
          return response;
        } else {
          const listings = response?.data?.listings;
          const pagination = response?.data?.pagination;
          const listingTypesData = tenantData.listingTypes();
          const summary = getSummaryData(listingSummary?.data?.summary);

          const user = store.getState().loginUser.user;
          const agencyUsersList = store.getState().userGroup?.list;
          !!user?.agency && !agencyUsersList && store.dispatch(setUsersList(user?.agency?.id));

          return {
            data: {
              list: listings && listings?.length ? listings?.map((item) => listingMapper(item, user)) : [],
              pagination: tenantUtils.getPaginationObject(pagination),
              tabFilterKey: 'q[firmstate]',
              statuses: summary
                ? [
                    ...Object.keys(summary).map((e) => ({
                      ...summary[e],
                      key: e === 'All' ? '' : summary[e].mapping,
                      label: `${capitalizeFirstLetter(e)} (${summary[e].total})`,
                      tab: `${capitalizeFirstLetter(e)} (${summary[e].total})`,
                    })),
                  ]
                : [],
              table: [
                {
                  title: 'Property',
                  dataIndex: 'property',
                  key: 'property',
                  component: 'ListingPurpose',
                  className: 'col-property col-property-xs',
                  fixed: 'left',
                },
                {
                  title: 'Platform',
                  dataIndex: 'platforms',
                  key: 'platform',
                  component: 'Platform',
                },
                {
                  title: 'Stats',
                  dataIndex: 'platforms',
                  key: 'views,clicks',
                  component: 'Stats',
                  showLabel: true,
                },
                // { title: 'Leads', dataIndex: 'platforms', key: 'leads', component: 'Stats' },
                {
                  title: 'Posted On',
                  dataIndex: 'platforms',
                  key: 'posted_on',
                  component: 'Date',
                },
                {
                  title: 'Status',
                  dataIndex: 'platforms',
                  key: 'status',
                  component: 'PlatformStatus',
                },
                {
                  title: 'Quota',
                  dataIndex: 'platforms',
                  key: 'consumedQuota',
                  component: 'Stats',
                },
                {
                  title: 'Upgrades',
                  dataIndex: 'platforms',
                  key: 'platform_actions',
                  component: 'PlatformListingActions',
                  className: 'col-platform-actions',
                },
                {
                  title: 'Listing Actions',
                  dataIndex: 'platforms',
                  key: 'listing_actions',
                  component: 'TableListingActions',
                  fixed: 'right',
                  width: 250,
                  className: 'col-listing-actions',
                },
              ],
            },
          };
        }
      }
      return response;
    }
  }
};

export const hideUnhideListings = async (userId, listingId, platform, status) => {
  let response = '';
  if (platform === 'olx') {
    response = await NetworkService.put(`/api/listings/${listingId}/olx_status`, {
      status: status == 'hidden' ? 'enable' : 'disable',
      user_id: userId,
    });
  } else {
    response = await NetworkService.patch(`/api/listings/${listingId}/toggle_visibility`, {
      user_id: userId,
    });
  }
  if (response) {
    if (response.error) {
      return response;
    } else {
      if (!response?.data?.listing) {
        return {
          ...response,
          dontUpdate: true,
        };
      } else {
        return {
          data:
            platform === 'olx'
              ? {
                  data: {
                    ...olxMapper(response.data.listing, true),
                    listing_id: listingId,
                    replaceKey: 'platforms.data[1]',
                    valueExists: true,
                  },
                }
              : {
                  data: {
                    ...zameenMapper(response.data.listing, true),
                    listing_id: listingId,
                    replaceKey: 'platforms.data[0]',
                    valueExists: true,
                  },
                },
        };
      }
    }
  }
};

export const deleteListings = async (userId, listingId, platform, action) => {
  let response = '';
  if (platform === 'olx') {
    switch (action?.status) {
      case 'Deleted':
      case 'deleted':
      case 'pDeleted':
      case 'hDeleted':
        response = await NetworkService.post(`/api/listings/${listingId}/post_to_olx`, {
          user_id: userId,
        });
      default:
        response = await NetworkService.put(`/api/listings/${listingId}/olx_status`, {
          status: 'delete',
          user_id: userId,
        });
    }
  } else if (platform === 'zameen') {
    switch (action?.status) {
      case 'Deleted':
      case 'deleted':
      case 'pDeleted':
      case 'hDeleted':
        response = await NetworkService.post(`/api/listings/${listingId}/post_to_zameen`, {
          user_id: userId,
        });

      default:
        response = await NetworkService.delete(`/api/listings/${listingId}`, {
          user_id: userId,
        });
    }
  }
  if (response) {
    if (response.error) {
      return response;
    } else {
      if (!response?.data?.listing) {
        return {
          dontUpdate: true,
        };
      } else {
        return {
          data:
            platform === 'olx'
              ? {
                  data: {
                    ...olxMapper(response.data.listing, true),
                    listing_id: listingId,
                    replaceKey: 'platforms.data[1]',
                    valueExists: true,
                    quota_consumption: response?.data?.listing?.platform?.['olx']?.consumed_quota,
                  },
                }
              : {
                  data: {
                    ...zameenMapper(response.data.listing, true),
                    listing_id: listingId,
                    replaceKey: 'platforms.data[0]',
                    valueExists: true,
                    quota_consumption: response?.data?.listing?.platform?.['zameen']?.consumed_quota,
                  },
                },
        };
      }
    }
  }

  return response;
};

export const getListingDetail = async (listingId) => {
  const response = await NetworkService.get(`/api/listings/${listingId}/edit`);
  if (response) {
    if (response.error) {
      return response;
    } else {
      return response?.data?.listing;
    }
  }
};
