import tenantConstants from '@constants';
import tenantData from '@data';
import { t } from 'i18next';
import React from 'react';
import store from '../redux/store';
import { TENANT_KEY } from './env';
import { numberToWords } from './numberToWords';
import { convertQueryObjToString } from './urlQuery';

/**
 * Return ellipsis of a given string
 * @param {string} text
 * @param {number} size
 */
const ellipsis = (text, size) => {
  return `${text.split(' ').slice(0, size).join(' ')}...`;
};

export const stripSlashesFormPath = (path) => {
  if (path.includes('//')) {
    stripSlashesFormPath(path.replace('//', '/'));
  } else {
    return path || '';
  }
};

const stSlash = (path) => {
  if (path) {
    if (path.endsWith('/')) {
      return stSlash(path.slice(0, path.length - 1));
    }
    if (path.endsWith('*')) {
      return stSlash(path.slice(0, path.length - 2));
    }
    return path;
  }
  return '';
};

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

function getMaxForChart(data) {
  let max = 0;
  for (const key in data) {
    const maxValueFromArray =
      data[key].length > 0
        ? data[key].reduce((a, b) => {
            return Math.max(a, b);
          })
        : 0;
    if (maxValueFromArray > max) {
      max = maxValueFromArray;
    }
  }

  if (max >= 0 && max <= 5) {
    return !max ? 1 : max;
  }
  if (max > 5 && max <= 10) {
    return Math.ceil(max / 2) * 2;
  }
  if (max > 10 && max <= 100) {
    return Math.ceil(max / 10) * 10;
  }
  if (max > 100 && max <= 1000) {
    return Math.ceil(max / 100) * 100;
  }
  if (max > 1000 && max <= 10000) {
    return Math.ceil(max / 1000) * 1000;
  }
  return max;
}

function getStepForChart(data) {
  let max = getMaxForChart(data);
  if (max >= 0 && max <= 5) {
    return 1;
  }
  if (max > 5 && max <= 10) {
    return 2;
  }
  if (max > 10 && max <= 50) {
    return 10;
  }
  if (max > 50 && max <= 100) {
    return 20;
  }
  if (max > 100 && max <= 500) {
    return 100;
  }
  if (max > 500 && max <= 1000) {
    return 200;
  }
  if (max > 1000 && max <= 2000) {
    return 500;
  }
  if (max > 2000 && max <= 5000) {
    return 1000;
  }
  if (max > 5000 && max <= 10000) {
    return 2000;
  }
  return parseInt(max / 7) - (parseInt(max / 7) % 10);
}

const getResultGroup = (noOfDays, isLegion = false) => {
  if (isLegion) {
    if (noOfDays > 364) {
      return { result_group: 'year' };
    } else if (noOfDays > 89) {
      return { result_group: 'month' };
    } else return { result_group: 'day' };
  } else {
    if (noOfDays > 364) {
      return { result_group: 'yy' };
    } else if (noOfDays > 90) {
      return { result_group: 'mm' };
    } else if (noOfDays > 14) {
      return { result_group: 'ww' };
    } else return { result_group: 'dd' };
  }
};

function contains(str, value) {
  value = value?.toString();
  const splitStr = str.split(',');
  if (splitStr.indexOf(value) !== -1) {
    return true;
  }
  return false;
}

const formatPrice = (value) => {
  if (value) {
    const [integerPart] = value?.toString()?.split('.');
    return integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }
  return value;
};

export const numberFormat = (value, format, options) => {
  return new Intl.NumberFormat(format, options).format(value);
};

export const formatCompactedNumber = (num, dashForNone, intl) => {
  if (intl) {
    const { locale } = store.getState().AppConfig;
    return locale == 'en' ? capitalizeFirstLetter(numberToWords(num, false, locale, true)) : null;
    // return new Intl.NumberFormat().format(num);
  }

  if (tenantConstants.NUMBER_SYSTEM == 'western') {
    switch (true) {
      case null:
      case undefined:
      case '' && dashForNone:
        return '-';
      case num >= 0 && num < 1000:
        return num.toFixed(num % 1 ? 2 : 0);
      case num >= 1000 && num < 1000000:
        return (num / 1000).toFixed((num / 1000) % 1 ? 2 : 0) + t('Thousand');
      case num >= 1000000 && num < 1000000000:
        return (num / 1000000).toFixed((num / 1000000) % 1 ? 2 : 0) + t('Million');
      case num >= 1000000000 && num < 1000000000000:
        return (num / 1000000000).toFixed((num / 1000000000) % 1 ? 2 : 0) + t('Billion');
      case num >= 1000000000000 && num < 1000000000000000:
        return (num / 1000000000000).toFixed((num / 1000000000000) % 1 ? 2 : 0) + t('Trillion');
      case num >= 1000000000000000:
        return (num / 1000000000000000).toFixed((num / 1000000000000000) % 1 ? 2 : 0) + t('Quadrillion');
      default:
        return num;
    }
  } else {
    switch (true) {
      case null:
      case undefined:
      case '' && dashForNone:
        return '-';
      case num >= 0 && num < 1000:
        return num.toFixed(num % 1 ? 2 : 0);
      case num >= 1000 && num < 100000:
        return (num / 1000).toFixed((num / 1000) % 1 ? 2 : 0) + t('Thousand');
      case num >= 100000 && num < 10000000:
        return (num / 100000).toFixed((num / 100000) % 1 ? 2 : 0) + t('Lakh');
      case num >= 10000000 && num < 1000000000:
        return (num / 10000000).toFixed((num / 10000000) % 1 ? 2 : 0) + t('Crore');
      case num >= 1000000000:
        return (num / 1000000000).toFixed((num / 1000000000) % 1 ? 2 : 0) + t('Arab');
    }
  }
};

const getCurrency = (val) => {
  switch (val) {
    case 'SAR':
      return 'SAR';
    default:
      return 'Rs ';
  }
};

const formatNumberString = (num, options) => {
  const { dashForNone, fractionDigits, ...rest } = options || {};
  if (!num) {
    if (dashForNone) {
      return '-';
    }
    return 0;
  }

  if (options?.notation == 'compact') return formatCompactedNumber(Number(num), dashForNone);
  const fracDigits = !!fractionDigits ? (Number(num) % 1 ? fractionDigits : 0) : Number(num) % 1 ? 2 : 0;
  return new Intl.NumberFormat(tenantConstants.locale, {
    ...(fracDigits <= 2 && { minimumFractionDigits: 0 }),
    maximumFractionDigits: fracDigits,
    ...rest,
  }).format(num);
};

const LocaliseCurrencyFromPrice = (inputString) => {
  const { locale } = store.getState().AppConfig;
  const regex = /^([A-Za-z]{3})\s*([\d,]+)$/;
  //TO DO Make single regex
  const regexwithDash = /^-([A-Za-z]{3})\s*([\d,]+)$/;

  const match = inputString.match(regex) ? inputString.match(regex) : inputString.match(regexwithDash);

  if (match) {
    const currency = match[1].toUpperCase();
    const value = match[2];
    if (match[0].includes('-')) {
      return locale == 'en' ? '-' + t(currency) + ' ' + value : t(currency) + ' ' + value + '-';
    } else {
      return t(currency) + ' ' + value;
    }
  } else {
    return inputString;
  }
};

const formatPriceValue = (
  priceValue,
  currencyOptions,
  showCurrency = true,
  fractionDigits,
  localiseCurrency = true,
) => {
  if (!isNaN(Number(priceValue))) {
    if (currencyOptions?.notation == 'compact')
      return showCurrency
        ? getCurrency(tenantConstants.currency) +
            ' ' +
            formatCompactedNumber(Number(priceValue), currencyOptions?.dashForNone, currencyOptions?.intl)
        : formatCompactedNumber(Number(priceValue), currencyOptions?.dashForNone, currencyOptions?.intl);
    const fracDigits = !!fractionDigits
      ? Number(priceValue) % 1
        ? fractionDigits
        : 0
      : Number(priceValue) % 1
        ? 2
        : 0;
    let formattedPrice = new Intl.NumberFormat(tenantConstants.locale, {
      currency: tenantConstants.currency,
      style: 'currency',
      currencyDisplay: 'symbol',
      ...(fracDigits <= 2 && { minimumFractionDigits: 0 }),
      maximumFractionDigits: fracDigits,
      ...currencyOptions,
    }).format(priceValue);

    return localiseCurrency ? LocaliseCurrencyFromPrice(formattedPrice) : formattedPrice;
  }
  return '-';
};

export const generateUUID = () => {
  let dt = new Date().getTime();
  const uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    const r = (dt + Math.random() * 16) % 16 | 0;
    dt = Math.floor(dt / 16);
    return (c == 'x' ? r : (r & 0x3) | 0x8).toString(16);
  });
  return uuid;
};

// Array Objects

const assignDefaultValueToObject = (obj, value) => {
  let newObj = new Proxy(obj, { get: (target, name) => (target.hasOwnProperty(name) ? target[name] : value) });
  return newObj;
};

const getUniqueArrayByObjKey = (array, key) => {
  return [
    ...new Map(
      array.map((item) => [Array.isArray(key) ? key.map((e) => item[e]).join('-') : item[key], item]),
    ).values(),
  ];
};

const getOBJValueByKey = (object, path, defval = null) => {
  if (!path) return defval;
  if (typeof path === 'string') path = path.split('.');
  return path.reduce((xs, x) => (xs && xs[x] ? xs[x] : defval), object);
};

const getKeyValueFromObject = (obj, path, assignValue, valueExists) => {
  const a = { ...obj };
  let value = a;
  const keys = path
    .replace(/\[(\w+)\]/g, '.$1')
    .replace(/^\./, '')
    .split('.');

  keys.forEach((item, index) => {
    if (item in value) {
      if (index == keys.length - 1 && assignValue) {
        if (valueExists) {
          value[item] = { ...value[item], ...assignValue };
        } else {
          value[item] = assignValue;
        }
      } else {
        value = value[item];
      }
    } else {
      return;
    }
  });
  return assignValue ? a : value;
};

// Errors

const getErrorString = (error) => {
  const errors =
    error?.response?.data?.errors || error?.response?.data?.error_description || error?.response?.data?.error;
  if (errors) {
    if (typeof errors === 'string') return errors;
    if (typeof errors === 'object')
      return Object.keys(errors)
        .map((key) => errors[key])
        .join(', ');
  }
  if (error && error.response?.data?.message) return error.response?.data?.message;
  if (error && error.message) return error.message;
  if (error && typeof error === 'string') return error;
};

const getErrorAllResponse = (response) => {
  let errorArray = [];
  response.forEach((e) => e?.error && errorArray.push(e.error));
  return errorArray.join(', ');
};

const getErrorObjFromResponse = (response, isMulti) => {
  if (isMulti) {
    let errorMessage = [],
      code = [];
    response.forEach((e) => {
      if (e?.response?.data?.message) {
        errorMessage.push(e.response.data.message);
        code.push(e.response.status);
        return;
      }
      if (e.error) {
        errorMessage.push(e.error);
        return;
      }
    });
    return {
      message: errorMessage.length ? errorMessage.join(', ') : '',
      code: code,
    };
  } else {
    let errorMessage = '';
    response.forEach((e) => {
      if (e.error) errorMessage = e.error;
    });
    return {
      message: errorMessage,
      code: 400,
    };
  }
};

const areaUnitConvert =
  (convertTo) =>
  (convertFrom, values = []) => {
    let convertedValues = [];
    values.map((item) => {
      convertedValues.push(Math.floor(item * tenantData.areaMapings[convertFrom][convertTo]));
    });
    return convertedValues;
  };

const formatMobile = (data, type) => {
  const replaceNumber = (number) => {
    let mobileNumberList;
    if (TENANT_KEY === 'zameen') {
      if (number.startsWith('03')) {
        mobileNumberList = number.replace('03', '+923');
      } else if (number.startsWith('+923')) {
        mobileNumberList = number;
      } else if (number?.startsWith('042')) {
        mobileNumberList = number?.replace('042', '+9242');
      } else if (number?.startsWith('92')) {
        mobileNumberList = number?.replace('92', '+92');
      } else if (number?.startsWith('--92')) {
        mobileNumberList = number?.replace('--92', '+92');
      } else if (number?.startsWith('--03')) {
        mobileNumberList = number?.replace('--03', '+923');
      } else if (number?.startsWith('+92-')) {
        mobileNumberList = number?.replace('+92-', '+92');
      } else if (number?.startsWith('-92')) {
        mobileNumberList = number?.replace('-92', '+92');
      } else if (number?.startsWith('3')) {
        mobileNumberList = number?.replace('3', '+923');
      } else if (number?.startsWith('-3')) {
        mobileNumberList = number?.replace('-3', '+923');
      } else if (number?.startsWith('--3')) {
        mobileNumberList = number?.replace('--3', '+923');
      } else if (number?.startsWith('+92')) {
        mobileNumberList = number;
      } else if (number === '-' || number === '--') {
        mobileNumberList = '';
      } else {
        mobileNumberList = `${number}`;
      }
    } else if (TENANT_KEY === 'bayut') {
      if (number === null) {
        number = '';
      }
      if (number?.startsWith('0')) {
        number = '';
      }
      if (number?.includes('-')) {
        number = number?.replace(/-/g, '');
      }
      if (number?.startsWith('966')) {
        number = number?.replace('966', '+966');
      }
      const cleanedNumber = number.replace(/[^\d+]/g, '');
      if (tenantConstants.phoneRegex.test(cleanedNumber)) {
        mobileNumberList = cleanedNumber;
      } else if (cleanedNumber === '-' || cleanedNumber === '--') {
        mobileNumberList = '';
      } else {
        mobileNumberList = `${number}`;
      }
    }

    return mobileNumberList;
  };
  try {
    if (type === 'singleNumber') {
      return replaceNumber(
        typeof data === 'string'
          ? data.replaceAll('-', '')
          : typeof data === 'object' && data[0]
            ? data[0].replaceAll('-', '')
            : '',
      );
    } else {
      if (typeof data === 'string') {
        return [replaceNumber(data.replaceAll('-', ''))];
      } else if (typeof data === 'object') {
        return data.map((item) => replaceNumber(item.replaceAll('-', '')));
      } else {
        return '';
      }
    }
  } catch (error) {
    return typeof data === 'string' ? '' : [''];
  }
};

const findByType = (children, component) => {
  const result = [];
  /* This is the array of result since Article can have multiple times the same sub-component */
  const type = [component.displayName] || [component.name];
  /* We can store the actual name of the component through the displayName or name property of our sub-component */
  React.Children.forEach(children, (child) => {
    const childType = child && child.type && (child.type.displayName || child.type.name);
    if (type.includes(childType)) {
      result.push(child);
    }
  });
  0;
  /* Then we go through each React children, if one of matches the name of the sub-component we’re looking for we put it in the result array */
  return result[0];
};

const allowInput = (type, value, limit) => {
  if (!value) return true;
  const regex = {
    number: new RegExp('^(\\d|[1-9]\\d' + `{1,${limit ? limit : 12}})` + '(?:[.]\\d{0,2})?$'),
    text: new RegExp(`^(.|[\f\n\r\t\v]){1,${limit ? limit : 250}}$`),
  };
  return regex[type].test(value);
};

const checkIfAndroid = () => {
  const isAndroid = /Android/i.test(navigator.userAgent);
  return !!isAndroid;
};

const isiOS = () => {
  return /iPad|iPhone|iPod|Macintosh/.test(navigator.userAgent) && !window.MSStream;
};

function checkRequiredKeys(obj, requiredKeys) {
  for (const key of requiredKeys) {
    if (key.includes('.')) {
      // Handle nested keys
      const [firstLevelKey, ...remainingKeys] = key.split('.');
      if (obj?.[firstLevelKey]) {
        checkRequiredKeys(obj[firstLevelKey], [remainingKeys.join('.')]);
      } else {
        throw new Error(`Missing required export: ${key}`);
      }
    } else {
      if (!(key in obj)) {
        throw new Error(`Missing required export: ${key}`);
      }
    }
  }
  return obj;
}

function checkIfMobile(agent) {
  if (
    /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
      agent,
    ) ||
    /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
      agent.substr(0, 4),
    )
  )
    return true;
  else return false;
}

const CheckIfAndroid = () => {
  const isAndroid = /Android/i.test(navigator.userAgent);
  return !!isAndroid;
};

const isSafari = () => {
  return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
};

const openExternal = (value, type = 'phone') => {
  switch (type) {
    case 'email':
      window.open(`mailto:${value}`, '_blank');
    case 'whatsapp':
      window.open(`https://wa.me/${value}`, '_blank');
    default:
      window.open(`tel:${value}`, '_blank');
  }
};

const removeKeysFromObject = (obj, keys) => {
  let tempObj = { ...obj };
  if (keys?.length) {
    keys.forEach((key) => {
      delete tempObj[key];
    });
  }
  return tempObj;
};
const mapQueryParams = (params, filtersList) => {
  if (!params) return '';
  if (params && !filtersList?.length) return convertQueryObjToString(params);
  const decodedParams = Object.entries(params).reduce((acc, [key, value]) => {
    acc[key] = decodeURIComponent(value);
    return acc;
  }, {});

  let queryParams = [];
  let listItems = [];
  const processedKeys = [];
  let unitKey = null;

  filtersList.forEach((item) => {
    if (item?.subList?.length) {
      item.subList.forEach((subListItem) => {
        const paramValue = decodedParams[subListItem.key];
        if (paramValue) {
          listItems.push(subListItem);
          processedKeys.push(subListItem.key);
        }
      });
    } else {
      const paramValue = decodedParams[item.key];
      if (paramValue) {
        listItems.push(item);
        processedKeys.push(item.key);
      }
    }
  });

  listItems.forEach((item) => {
    if (item?.queryParamKey) {
      const paramValue = decodedParams[item?.key];
      const values = paramValue.split(',');
      if (typeof item.queryParamKey === 'string') {
        values.forEach((value) => {
          queryParams.push(`${item.queryParamKey}=${value}`);
        });
      } else if (Array.isArray(item.queryParamKey)) {
        item.queryParamKey.forEach((key, index) => {
          queryParams.push(`${key}=${values[index] || ''}`);
          if (item.queryParamKey?.[2]) unitKey = item.queryParamKey?.[2];
        });
      } else if (typeof item.queryParamKey === 'function') {
        values.forEach((value) => {
          queryParams.push(item.queryParamKey(value));
        });
      }
    }
  });

  Object.keys(decodedParams).forEach((paramKey) => {
    if (!processedKeys.includes(paramKey)) {
      // O(n) lookup
      const paramValue = decodedParams[paramKey];
      queryParams.push(`${paramKey}=${paramValue}`);
    }
  });
  if (unitKey) queryParams = queryParams.filter((param) => !param.startsWith(unitKey));

  return queryParams.join('&');
};

const getLocaleForURL = () => {
  const locale = store.getState().AppConfig.locale;
  return tenantConstants.languages[0].key === locale ? '' : locale + '/';
};

const getCurrentLocationPath = (currentPath) => {
  return currentPath?.pathname?.split('/').pop();
};

export {
  allowInput,
  areaUnitConvert,
  assignDefaultValueToObject,
  capitalizeFirstLetter,
  checkIfAndroid,
  CheckIfAndroid,
  checkIfMobile,
  checkRequiredKeys,
  ellipsis,
  findByType,
  formatMobile,
  formatNumberString,
  formatPrice,
  formatPriceValue,
  getErrorAllResponse,
  getErrorObjFromResponse,
  getErrorString,
  getKeyValueFromObject,
  getMaxForChart,
  getOBJValueByKey,
  getResultGroup,
  getStepForChart,
  getUniqueArrayByObjKey,
  isiOS,
  stSlash,
  isSafari,
  openExternal,
  removeKeysFromObject,
  mapQueryParams,
  getLocaleForURL,
  getCurrentLocationPath,
};
