import tenantConstants from '@constants';
import tenantData from '@data';
import tenantRoutes from '@routes';
import tenantTheme from '@theme';
import tenantUtils from '@utils';
import { Button, Col, Row, Space, Typography } from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useAppAuthentication } from '../../../hooks';
import TenantComponents from '../../../tenant/components';
import { ProgressStyled } from '../../../tenant/components/bayut/profile-completion/style';
import { Avatar, Group, Icon, Skeleton, Tag } from '../../common';
import DrawerPopover from '../../common/drawerPopover/drawerPopover';
import { IconStyled } from '../../common/icon/IconStyled';
import { LogoNafaz } from '../../svg';
import { InfoWraper, UserDropdown } from './auth-info-style';

const AuthInfo = () => {
  const HeaderComponents = TenantComponents.HeaderComponent;
  const { t } = useTranslation();

  const user = useSelector((state) => state.loginUser.user);
  const loading = useSelector((state) => state.loginUser.loading);
  const isMobile = useSelector((state) => state.AppConfig.isMobile);
  const locale = useSelector((state) => state.AppConfig.locale);
  const loggedInUser = useSelector((state) => state.loginUser);
  const isMemberArea = useSelector((state) => state.AppConfig.isMemberArea);
  const [visible, setVisible] = useState(false);
  const { onLogout } = useAppAuthentication();

  const handleVisibleChange = (isVisible) => {
    setVisible(isVisible);
  };

  const handleLinkClick = () => {
    setVisible(false);
  };

  const SignOut = (e) => {
    e.preventDefault();
    onLogout();
    setVisible(false);
  };

  const renderProfileImage = (size, iconSize, iconContainerSize) => {
    return (
      <Avatar
        size={size}
        src={user?.profile_image}
        style={{ margin: 0 }}
        iconSize={iconSize}
        iconContainerSize={iconContainerSize}
      />
    );
  };

  const userContent = (
    <UserDropdown>
      <div className="user-dropdwon">
        <div className="user-info">
          <Group template="auto 1fr" gap="10px" className="align-items-center mb-12">
            {user?.profile_image ? (
              <img src={user?.profile_image} width="40" alt="" />
            ) : (
              <IconStyled
                style={{
                  '--icon-styled-width': '40px',
                  '--icon-bg-color': tenantTheme['gray300'],
                  height: 'var(--icon-styled-width)',
                  alignSelf: 'auto',
                }}
              >
                <Icon size={20} icon="FiUser" />
              </IconStyled>
            )}
            <div>
              <div className="fs-700 fs16">{locale === 'en' ? user?.name : user?.name_l1}</div>
              <Typography.Text type="secondary" title={user?.email}>
                {user?.email}
              </Typography.Text>
            </div>
          </Group>

          {loggedInUser?.user?.is_nafaz_verified && (
            <Tag color={tenantTheme['primary-light-3']} shape="round" size="12px" className="mb-8">
              <Space.Compact className="base-color align-items-center" style={{ gap: 6 }}>
                <LogoNafaz width={20} />
                <span>{t('Nafath Verified')}</span>
                <Icon icon="PiSealCheckFill" color="#479EEB" />
              </Space.Compact>
            </Tag>
          )}
          <div>
            {loggedInUser?.user?.is_rega_verified && (
              <Tag color={tenantTheme['primary-light-3']} shape="round" size="12px" className="mb-8">
                <Space.Compact className="base-color align-items-center" style={{ gap: 6 }}>
                  <span>{t('REGA Verified')}</span>
                  <Icon icon="PiSealCheckFill" color="#479EEB" />
                </Space.Compact>
              </Tag>
            )}
          </div>
        </div>

        <ul className="dropdwon-links">
          <li>
            <Link to={`${tenantRoutes.app('', false, user).settings.route}/user-profile`} onClick={handleLinkClick}>
              <Icon icon="FiUser" />
              {t('Account Settings')}
            </Link>
          </li>
          <li>
            <Link onClick={SignOut} to="#" className="sign-out">
              <span>
                <Icon icon="FiLogOut" className="flipX p-0" />
              </span>
              {t('Logout')}
            </Link>
          </li>
        </ul>
      </div>
    </UserDropdown>
  );

  return loading && !user ? (
    <AuthInfoSkeleton />
  ) : (
    <InfoWraper>
      <HeaderComponents />
      <div>
        <DrawerPopover
          content={userContent}
          onCancel={() => setVisible(false)}
          overlayInnerStyle={{ padding: 0 }}
          overlayClassName="menu-main"
          onOpenChange={handleVisibleChange}
          action="click"
          title={t('Profile Information')}
          visible={visible}
          height={400}
          footer={null}
        >
          <Button
            shape="round"
            className="head-example name-avatar"
            style={{ gap: '0' }}
            onClick={() => {
              if (isMobile) {
                setVisible(true);
              }
            }}
          >
            {!isMobile && <span className="name">{tenantUtils.getLocalisedString(user, 'name')}</span>}
            {tenantConstants.PROFILE_COMPLETION_APPLICABLE && !isMemberArea ? (
              <ProgressStyled
                size={34}
                type="circle"
                strokeColor={tenantData.getClassificationColor(user?.profile_completion?.classification)}
                percent={user?.profile_completion?.score}
                format={() => renderProfileImage(30, 24, '26')}
              />
            ) : (
              renderProfileImage(34, 26, '34')
            )}
          </Button>
        </DrawerPopover>
      </div>
    </InfoWraper>
  );
};

const AuthInfoSkeleton = () => {
  return (
    <InfoWraper>
      <Row gutter={8}>
        <Col>
          <Skeleton type="button" style={{ marginTop: 25 }} />
        </Col>
        <Col>
          <Skeleton type="avatar" size={40} style={{ marginTop: 20 }} />
        </Col>
      </Row>
    </InfoWraper>
  );
};

export default AuthInfo;
