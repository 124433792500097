import tenantData from '@data';
import { Typography } from 'antd';
import { forwardRef, useImperativeHandle, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Avatar, Flex } from '../../../../components/common';
import Lottie from '../../../../components/common/lottie/lottie';
import congratulationAnimation from './congratulationLottie.json';
import { ModalCompletion, ProgressStyled } from './style';
import { profileCompletionPopUpCancelEvent } from '../../../../services/analyticsService';

const { Text, Title } = Typography;

const ProfileCompletionCongratsModal = forwardRef((props, ref) => {
  const isMobile = useSelector((state) => state.AppConfig.isMobile);

  const [showCongratsModal, setShowCongratsModal] = useState(false);
  const rtl = useSelector((state) => state.AppConfig.rtl);
  const loggedInUser = useSelector((state) => state.loginUser);
  const { t } = useTranslation();

  const getCurrentRoute = () => {
    const currentPath = window.location.pathname?.split('/');
    const route = currentPath[currentPath?.length - 1];
    return route;
  };

  const showModal = () => {
    setShowCongratsModal(true);
  };

  useImperativeHandle(ref, () => ({
    showModal,
  }));

  const imageWithBadge = () => {
    return (
      <Avatar
        size={54}
        src={loggedInUser?.user?.profile_image}
        showBadge={true}
        iconContainerSize="50px"
        iconSize={20}
        badgeColor={tenantData.getClassificationColor(loggedInUser?.user?.profile_completion?.classification)}
        badgeCount={loggedInUser?.user?.profile_completion?.score + '%'}
        badgeShape={'square'}
        badgeOffset={rtl ? [27, 55] : [-27, 55]}
      />
    );
  };

  return (
    <ModalCompletion
      title={null}
      onCancel={() => {
        setShowCongratsModal(false);
        profileCompletionPopUpCancelEvent(loggedInUser?.user, getCurrentRoute());
      }}
      footer={null}
      visible={showCongratsModal}
      className="profile-completion"
      width={!isMobile && 490}
    >
      <Flex vertical justify="center" align="center" gap="15px" style={{ height: isMobile ? '200px' : '245px' }}>
        <div className="congrats-lottie">
          <Lottie animationData={congratulationAnimation} loop={false} autoplay={false} />
        </div>
        <ProgressStyled
          size={58}
          type="circle"
          strokeColor={tenantData.getClassificationColor(loggedInUser?.user?.profile_completion?.classification)}
          percent={loggedInUser?.user?.profile_completion?.score}
          format={() => imageWithBadge()}
          style={{ marginBlockEnd: 10 }}
        />
        <div className="text-center">
          <Title level={isMobile ? 4 : 3} className="mb-4">
            {t('Profile Completed')}
          </Title>
          <Text type="secondary" className="d-block" style={{ width: '35ch' }}>
            {t('Congratulations, you have successfully completed your profile!')}
          </Text>
        </div>
      </Flex>
    </ModalCompletion>
  );
});
export default ProfileCompletionCongratsModal;
