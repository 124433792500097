import Styled from 'styled-components';

export const ListWithBullet = Styled.ol`
  padding-inline-start: 0;
  li {
    list-style: none;

    &:before {
      content: '';
      display: inline-block; 
      width: 6px;
      height: 6px;
      border-radius: 1rem;
      background-color: #E1F2F0;
      border: 1px solid #55969B;
      
      margin-inline-end: 8px;
    }
  }


`;
