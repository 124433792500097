import React, { useEffect, useRef, useState } from 'react';
import { addAgencyUser, setAgencyStaffList, updateAgencyUser } from '../../redux/agencyStaff/actionCreator';

import tenantApi from '@api';
import tenantConstants from '@constants';
import { Tabs } from 'antd';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { notification } from '../../components/common';
import { strings } from '../../constants/strings';
import { JSONFormStyled } from '../common/json-form/styled';
import { Modal } from '../common/modals/antd-modals';
import { setUsersList } from '../../redux/users/actionCreator';

export const UserForm = ({ isVisible, setIsVisible, agencyId, userId, isAdmin }) => {
  const { t } = useTranslation();
  const { TabPane } = Tabs;
  const [formData, setFormData] = useState(null);
  const [permissionFormData, setPermissionFormData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [detailLoading, setDetailLoading] = useState(false);
  const [permissionLoading, setPermissionLoading] = useState(false);
  const [error, setError] = useState(null);
  const [tabIndex, setTabIndex] = useState('user-details');
  const formRef = useRef();
  const permissionRef = useRef();
  const user = useSelector((state) => state.loginUser.user);
  const dispatch = useDispatch();

  useEffect(() => {
    isVisible && onShowModal();
  }, [isVisible]);

  const onCloseModal = () => {
    setTabIndex('user-details');
    setPermissionFormData(null);
    setIsVisible(false);
    setFormData(null);
    setError(null);
    formRef?.current && formRef?.current?.resetForm();
  };

  const onShowModal = () => {
    getUserDetailData();
  };

  const handleOk = () => {
    tabIndex === 'user-details'
      ? !loading && formRef.current && formRef.current.submitForm()
      : !loading && permissionRef.current.submitForm();
  };

  const onChange = (key) => {
    key === 'user-details' && !formData && getUserDetailData();
    setTabIndex(key);
  };

  const getUserDetailData = async () => {
    setLoading(true);
    const response = await tenantApi.fetchAgencyUserDetail(userId);
    if (response) {
      setLoading(false);
      if (response.error) {
        setError(true);
      } else {
        setFormData(response);
      }
    }
  };

  const handleDetailsSubmit = async (values) => {
    setDetailLoading(true);
    const response = userId
      ? await tenantApi.updateAgencyUser(agencyId, userId, values)
      : await tenantApi.addAgencyUser(agencyId, values);
    setDetailLoading(false);
    if (response.error) {
      notification.error(response.error);
    } else {
      userId ? dispatch(updateAgencyUser(response.user)) : dispatch(addAgencyUser(response.user));
      onCloseModal();
      dispatch(setAgencyStaffList(user, agencyId));
      dispatch(setUsersList(agencyId));
    }
  };

  // const translateLabelsRecursive = item => {
  //   if (item.props && item.props.label) {
  //     item.props.label = t(item.props.label);
  //     item.props.placeholder = t(item.props.placeholder);
  //   } // Recursive call for nested properties
  //   if (item.props && typeof item.props === 'object') {
  //     Object.values(item.props).forEach(nestedItem => {
  //       if (typeof nestedItem === 'object') {
  //         translateLabelsRecursive(nestedItem);
  //       }
  //     });
  //   }
  // };
  // if (formData?.formFields?.list && typeof formData.formFields.list === 'object') {
  //   Object.values(formData.formFields.list).forEach(item => {
  //     translateLabelsRecursive(item);
  //   });
  // }

  return (
    <Modal
      type="primary"
      title={userId ? t(strings.edit_user) : t(strings.add_user)}
      visible={isVisible}
      okText="Confirm"
      okButtonProps={{ type: 'primary' }}
      loading={detailLoading || permissionLoading}
      onOk={handleOk}
      onCancel={onCloseModal}
      width={990}
    >
      {tenantConstants.country_code == 'PK' ? (
        <Tabs activeKey={tabIndex} onChange={onChange}>
          <TabPane tab={t('User Details')} key="user-details">
            <JSONFormStyled
              fields={formData?.formFields?.list}
              formFieldValues={formData?.agencyUserData}
              ref={formRef}
              onSubmitForm={handleDetailsSubmit}
              loading={!formData && loading && !error}
              fetchError={error}
              retryButtonLoading={!formData && loading}
              onRetry={getUserDetailData}
              noOfContentColumns={2}
            />
          </TabPane>
          {/* {userId && !isAdmin && (
          <TabPane tab="Permissions" key="permissions" style={{ maxWidth: 575, marginInline: 'auto' }}>
            <JSONFormStyled
              fields={permissionFormData?.formFields?.list}
              formFieldValues={permissionFormData?.userPermissionData}
              ref={permissionRef}
              onSubmitForm={handlePermissionSubmit}
              loading={!permissionFormData && loading}
              fetchError={error}
              noOfContentColumns={1}
            />
          </TabPane>
        )} */}
        </Tabs>
      ) : (
        <JSONFormStyled
          fields={formData?.formFields?.list}
          formFieldValues={formData?.agencyUserData}
          ref={formRef}
          onSubmitForm={handleDetailsSubmit}
          loading={!formData && loading && !error}
          fetchError={error}
          retryButtonLoading={!formData && loading}
          onRetry={getUserDetailData}
          noOfContentColumns={2}
        />
      )}
    </Modal>
  );
};
