import tenantConstants from '@constants';
import tenantData from '@data';
import tenantRoutes from '@routes';
import tenantTheme from '@theme';
import { ConfigProvider } from 'antd';
import 'antd/dist/reset.css';
import React, { useCallback, useEffect } from 'react';
import { Provider, useDispatch, useSelector } from 'react-redux';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { ThemeProvider as ThemeProviderStyled } from 'styled-components';
import { Spinner } from './components/common';
import ErrorBoundary from './components/common/errorBoundary/ErrorBoundary';
import RenderIfLoggedIn from './components/render-if-loggedIn/renderIfLoggedIn';
import RenderIfNotLoggedIn from './components/render-if-not-loggedIn/renderIfNotLoggedIn';
import { Maintenance } from './container/pages';
import SignInPage from './container/pages/authentication/SignIn';
import PaymentProcess from './container/pages/payment/payment-process';
import Home from './home';
import { useAppAuthentication, useAppInit, useProfolioSwitch } from './hooks';
import { getProducts } from './redux/general/actionCreator';
import productsSuccess from './redux/general/actions';
import store from './redux/store';
import { fetchUserDetailFromToken } from './redux/user/actionCreator';
import { getRequestHeaders } from './services/networkService';
import './static/less/antd-customized.less';
import './static/less/style.less';
import './static/less/utils.less'; // Should be at the end of CSS loading order

const ProviderConfig = () => {
  const dispatch = useDispatch();
  const { locale, rtl, darkMode, basePath, onLanding } = useAppInit();

  const { user, loading, error } = useSelector((state) => state.loginUser);

  const { auth, awaitForUserToken, redirectToLogin, onLogout, handleRefreshToken } = useAppAuthentication(true);

  const availableRoutes = (user) => tenantRoutes.app('', true, user);
  useEffect(() => {
    dispatch(() => (dispatch) => dispatch(productsSuccess(tenantData.products)));
    fetchBaseDetail();
  }, [auth.authenticated]);

  // To refresh token if user got suspended or converted to package user.
  useEffect(() => {
    if (tenantConstants.KC_ENABLED && user) {
      const localUserInfo = JSON.parse(localStorage.getItem(tenantConstants.USER_LOCAL_STORAGE_KEY) || null);
      if (localUserInfo) {
        const { id, is_package_user, is_suspended } = user;
        if (localUserInfo.is_package_user != is_package_user || localUserInfo.is_suspended != is_suspended) {
          handleRefreshToken(null, () => {
            localStorage.setItem(
              tenantConstants.USER_LOCAL_STORAGE_KEY,
              JSON.stringify({ id, is_package_user, is_suspended }),
            );
          });
        }
      }
    }
  }, [user]);

  const fetchBaseDetail = () => {
    if (auth.authenticated) {
      dispatch(fetchUserDetailFromToken(getRequestHeaders(auth), onLanding));
      dispatch(getProducts(getRequestHeaders(auth)));
    }
  };

  const getAppFont = useCallback(() => {
    if (tenantConstants?.IS_LITE_EXPERINCE) {
      return tenantConstants.IS_LITE_EXPERINCE() ? { fontFamily: tenantConstants.FONT_FAMILY_LITE } : '';
    }
  }, [tenantConstants]);

  return (
    <ConfigProvider
      locale={locale}
      direction={rtl ? 'rtl' : 'ltr'}
      theme={{
        hashed: false,
        cssVar: { key: 'styleProfolio' },
        rtl,
        darkMode: { ...tenantTheme?.darkTheme },
        ...tenantTheme.themeTokens,
        token: {
          ...tenantTheme.themeTokens.token,
          ...getAppFont(),
        },
      }}
    >
      <ThemeProviderStyled theme={{ ...tenantTheme, rtl }}>
        <ErrorBoundary>
          <BrowserRouter basename={basePath}>
            <Routes>
              <Route path="/content/process-payment" element={<PaymentProcess />} />
              <Route path="/maintenance" element={<Maintenance />} />
              <Route
                path={tenantRoutes.auth().signin.path}
                element={
                  awaitForUserToken ? (
                    loading ? (
                      <Spinner type="full" />
                    ) : (
                      <Navigate to={user ? availableRoutes(user)[0].path : '/'} />
                    )
                  ) : (
                    <RenderIfNotLoggedIn Component={<SignInPage />} redirectToLogin={redirectToLogin} />
                  )
                }
              />
              <Route
                path="/"
                element={
                  <RenderIfLoggedIn
                    awaitForUserToken={awaitForUserToken}
                    redirectToLogin={redirectToLogin}
                    error={error}
                    fetchBaseDetail={fetchBaseDetail}
                    loading={loading}
                    onLogout={onLogout}
                    user={user}
                    navigateTo={(e) => availableRoutes(e)[0].path}
                  />
                }
              />
              <Route
                path="/*"
                element={
                  <RenderIfLoggedIn
                    Component={<Home />}
                    awaitForUserToken={awaitForUserToken}
                    redirectToLogin={redirectToLogin}
                    error={error}
                    fetchBaseDetail={fetchBaseDetail}
                    loading={loading}
                    onLogout={onLogout}
                    user={user}
                    navigateTo={(e) => availableRoutes(e)[0].path}
                  />
                }
              />
            </Routes>
          </BrowserRouter>
        </ErrorBoundary>
      </ThemeProviderStyled>
    </ConfigProvider>
  );
};

function App() {
  const [show] = useProfolioSwitch();

  return show ? (
    <Provider store={store}>
      <ProviderConfig />
    </Provider>
  ) : null;
}

export default App;
