export const WEBSITE_SECTIONS = {
  PROFOLIO: 'profolio',
  LITE: 'lite',
};

export const PAGE_GROUPS = {
  DASHBOARD: 'dashboard',
  ADD_PROPERTY: 'add_property',
  MANAGE_LISTING: 'manage_listings',
  REPORTS: 'reports',
  SETTINGS: 'settings',
  AGENCY: 'agency',
  PACKAGES: 'packages',
  LEADS: 'leads', // Inbox
  HELP_CENTRE: 'help_centre',
  APP_REDIRECT: 'app_redirect',
};

export const PAGE_TYPES = {
  DETAILS: 'details',
  DASHBOARD: 'dashboard',
  CREDIT_USAGE: 'credit_usage',
  SUMMARY: 'summary',
  LEADS: 'leads',
  LISTINGS: 'listings',
  ACTIVE: 'active',
  DRAFT: 'draft',
  PENDING: 'pending',
  REMOVED: 'removed',
  AGENCY_STAFF: 'agency-staff',
  USER_SETTINGS: 'user_settings',
  AGENCY_SETTINGS: 'agency_settings',
  FAQ: 'faq',
  UPSELL: 'upsell',
  CHECKOUT: 'checkout',
  NAFATH: 'nafath',
  INBOX: 'inbox',
  HELP_CENTRE: 'help_centre',
  REGA_COMPLIANCE: 'rega_compliance',
  SIDE_MENU: 'side_menu',
  ACCOUNT_MANAGER: 'account_manager', // EMAIL, WHATSAPP, CALL Account Manager
  PROFOLIO: 'profolio', // HelpCenter
  DOWNLOAD_BUTTON: 'download_button',
  CHOOSE_LISTING_TYPE: 'choose_listing_type',
  CHOOSE_FAL_LICENSE: 'choose_fal_license',
  AD_LICENSE_VER: 'ad_license_verification',
  CR_VERIFICATION: 'cr_verification',
  NATIONAL_ID_VERIFICATION: 'national_id_verification',
  USER_PROFILE: 'user-profile',
  POST_LISTING: 'post-listing',
  ADD_PROPERTY_FORM: 'add-property-form',
};

export const PAGE_SECTIONS = {
  REGA_DETAILS: 'rega_details',
  NEW: 'new',
  EDIT: 'edit',
  REGA_QR: 'rega_qr',
  REGA_LINK: 'rega_link',
  MAP: 'map',
  TITLE: 'title',
  DESCRIPTION: 'description',
  CONFIRMATION_POPUP: 'confirmation_popup',
  EMAIL: 'email',
  WHATSAPP: 'whatsapp',
  CALL: 'call',
  PACKAGE_DURATION: 'package_duration',
  DELETE: 'delete',
  QUALITY: 'quality',
  UPGRADE_POPUP: 'upgrade_popup',
};

export const INTERACTED_FROM = {
  HEADER_PROFOLIO: 'header_profolio',
  HEADER_LITE: 'header_lite',
  SIDE_MENU: 'side_menu',
  ACCOUNT_SETTING: 'account_settings',
  MANAGE_LISTINGS: 'manage_listings',
  UPSELL: 'upsell',
  PACKAGED: 'packages',
};

// CATEGORY NAMES
export const EVENT_CATEGORIES = {
  ADD_PROPERTY_CAT: 'Add Property',
  PACKAGE_BUYING_CAT: 'Package Buying',
  PAGE_VIEW_SIDE_MENU_CAT: 'Page Views Side Menu',
  ACCOUNT_MANAGER_CAT: 'Account Manager',
  MANAGE_LISTING_CAT: 'Manage Listing',
  APP_REDIRECT: 'App Redirect',
  LEADS: 'Leads',
  CHECKOUT: 'checkout',
  PROFILE_COMPLETION_CAT: 'Profile Completion',
};

// ACTION NAMES
export const EVENT_NAMES = {
  ADD_PROPERTY_ACT: 'add_property',
  REGA_AD_VALIDATION_ACT: 'rega_ad_validation',
  PAGE_VIEW_ACT: 'page_view',
  CLICK_ACT: 'click',
  IMAGE_UPLOAD_ACT: 'image_upload',
  MAP_PIN_SELECTED_ACT: 'map_pin_selected',
  CONTENT_GENERATION_ACT: 'content_generation',
  SUBMIT_LISTING_ACT: 'submit_listing',
  UPSELL_SUBMIT_ACT: 'upsell_submit',
  PAY_CLICK_ACT: 'pay_click',
  VERIFY_NAFATH_CLICK_ACT: 'verify_nafath_click',
  GET_PACKAGE_ACT: 'get_package',
  CLICK_UPGRADE_ACT: 'click_upgrade',
  UPGRADE_CONFIRM_ACT: 'upgrade_confirm',
  GET_TOPUP_ACT: 'get_topup',
  HELP_CENTRE_CLICK_ACT: 'help_centre_click',
  QUALITY_HOVER: 'quality_hover',
  DELETE_LISTING: 'delete_listing',
  SMART_BANNER_INSTALL_CLICK: 'smart_banner_install_click',
  DOWNLOAD_APP_CLICK: 'download_app_click',
  DOWNLOAD_APP_ACTION: 'download_app_action',
  CONFIRM_CLICK_ACT: 'confirm_click',
  COMPLETE_PROFILE_CLICK_ACT: 'complete_profile',
  WHY_IS_PROFILE_COMPLETION_IMPORTANT_ACT: 'learn_more',
  PROFILE_COMPLETION_POP_UP_CANCEL_ACT: 'profile_completed',
  SELECT_CATEGORY_ACT: 'select_category',
};

export const statusMap = {
  10: PAGE_TYPES.ACTIVE,
  11: PAGE_TYPES.DRAFT,
  12: PAGE_TYPES.PENDING,
  13: PAGE_TYPES.REMOVED,
};

export const STRINGS = {
  NEW: 'new',
  EDIT: 'edit',
  ERROR: 'error',
  SUCCESS: 'success',
  YES: 'yes',
  NO: 'no',
  TRUE: 'true',
  FALSE: 'false',
};

export const getCommonParams = (user) => {
  return {
    website_section: !user.is_package_user ? WEBSITE_SECTIONS.LITE : WEBSITE_SECTIONS.PROFOLIO,
    package_type: user.package ? user.package.slug : null,
    is_agency: user.agency ? true : false,
    agency_id: user.agency ? user.agency.id : null,
    agency_name: user.agency ? user.agency.name : null,
    is_package_user: user.is_package_user ? 'yes' : 'no',
  };
};

export const getPageType = (route) => {
  const matchingKey = Object.keys(PAGE_TYPES).find((key) => PAGE_TYPES[key] === route);
  if (matchingKey) {
    return PAGE_TYPES[matchingKey];
  }
  return PAGE_TYPES.DASHBOARD;
};
