import tenantRoutes from '@routes';
import tenantTheme from '@theme';
import { t } from 'i18next';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { CustomCard } from '../../../../components/common';
import { getClassifiedBaseURL } from '../../../../utility/env';
import { getLocaleForURL } from '../../../../utility/utility';

const ProfileCompletionList = (props) => {
  const { user } = useSelector((state) => state.loginUser);

  const profileNavigationRoutes = useMemo(
    () => [
      {
        key: 1,
        title: t('User Settings'),
        link:
          !user?.is_profile_completed && tenantRoutes.app(`/user-settings`, false, user).settings.subRoutes[0]?.path,
        cardBackground: user?.is_profile_completed && tenantTheme['primary-light-4'],
        isVerified: user?.is_profile_completed,
        internalLink: true,
      },
      {
        key: 2,
        title: t('FAL License Verification'),
        link:
          !user?.is_rega_verified &&
          `${getClassifiedBaseURL()}/${getLocaleForURL()}verification/rega/?redirectPath=${window.location.href}`,
        cardBackground: user?.is_rega_verified && tenantTheme['primary-light-4'],
        isVerified: user?.is_rega_verified,
      },
      {
        key: 3,
        title: t('Nafath Verification'),
        link:
          !user?.is_nafaz_verified &&
          `${getClassifiedBaseURL()}/${getLocaleForURL()}verification/nafath/?redirectPath=${window.location.href}`,
        cardBackground: user?.is_nafaz_verified && tenantTheme['primary-light-4'],
        isVerified: user?.is_nafaz_verified,
      },
    ],
    [],
  );

  return profileNavigationRoutes.map((e) => (
    <CustomCard
      avatarIcon={e?.isVerified ? 'SvgIconCheck' : 'SvgRadio'}
      iconSize={16}
      title={e?.title}
      link={e?.link}
      cardBackground={e?.cardBackground}
      isVerified={e?.isVerified}
      style={{ borderColor: e?.isVerified && tenantTheme['primary-light-3'] }}
      onClick={e?.onClick}
      internalLink={e?.internalLink}
      {...props}
    />
  ));
};
export default ProfileCompletionList;
